import React, { Component } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../../config/locale';
import {
  ACCESS_LEVELS,
  LOCK_STATES,
  TRAINING_REGISTRATION_TYPES,
} from '../../constants';
import {
  deleteElementHistoryEventApprovalService,
  deleteElementHistoryEventService,
  getAllElementHistoryService,
  getElementHistoryService,
  restoreElementHistoryEventApprovalService,
  restoreElementHistoryEventService,
} from '../../services/knowledgeService';
import { registerTrainingService } from '../../services/trainingService';
import { getCurrentDate } from '../../utils/helpers';
import AccessLevelWrapper from '../AccessLevelWrapper';
import Button from '../Button';
import ElementHistoryList from '../ElementHistoryList';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import DateTime from '../DateTime';
import { getVersionsService } from '../../services/versionsService';

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    currentUserId: user.employeeId,
  };
};

class ElementHistoryModal extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      isLoading: true,
      totalEvents: 0,
    };
  }

  componentDidMount() {
    this.getHistory();
  }

  getHistory = () => {
    const { elementId, personId } = this.props;
    getElementHistoryService(personId, elementId)
      .then((response) => {
        this.setState({
          items: response.data.events,
          totalEvents: response.data.totalEvents,
          lockState: response.data.lockState,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  onClickAddReset = (isAdminReset) => {
    const { showModal, personId, elementId, onChangeHistory } = this.props;
    showModal('resetStatus', {
      title: isAdminReset
        ? localeLookup('translations.Add reset as admin')
        : localeLookup('translations.Add reset'),
      maxWidth: '500px',
      infoText: isAdminReset
        ? localeLookup(
            "translations.This action ignores that the element is locked and adds an admin reset. The element's history will be preserved"
          )
        : localeLookup(
            "translations.This action adds a reset. The element's history will be preserved"
          ),
      onConfirm: (note) => {
        registerTrainingService({
          personId,
          elementId,
          type: isAdminReset
            ? TRAINING_REGISTRATION_TYPES.ADMIN_RESET_STATUS
            : TRAINING_REGISTRATION_TYPES.RESET_STATUS,
          note,
          validFromDate: getCurrentDate().format('YYYY-MM-DD'),
        }).then(() => {
          onChangeHistory();
          showModal('elementHistory', {
            fullWidth: true,
            maxWidth: '500px',
            cancelButtonText: localeLookup('translations.Close'),
            title: localeLookup('translations.History'),
            elementId,
            personId,
            onChangeHistory,
          });
        });
      },
      onCancel: this.onClickShowSelf,
    });
  };

  onClickDeleteApproval = ({ eventId, approvalId }) => {
    const { elementId, personId, onChangeHistory } = this.props;
    return deleteElementHistoryEventApprovalService(
      personId,
      elementId,
      eventId,
      approvalId
    ).then((response) => {
      const updatedEvent = response.data.events[0];
      onChangeHistory();
      this.setState((previousState) => ({
        ...previousState,
        items: previousState.items.map((item) => {
          if (item.id !== eventId) return item;
          return updatedEvent;
        }),
      }));
    });
  };

  onClickLoadAll = () => {
    const { items } = this.state;
    const { elementId, personId } = this.props;
    getAllElementHistoryService(personId, elementId)
      .then((response) => {
        this.setState({
          items: [...items, ...response.data.events],
          totalEvents: response.data.totalEvents,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  onClickRestoreApproval = ({ eventId, approvalId }) => {
    const { elementId, personId, onChangeHistory } = this.props;
    return restoreElementHistoryEventApprovalService(
      personId,
      elementId,
      eventId,
      approvalId
    ).then((response) => {
      const updatedEvent = response.data.events[0];
      onChangeHistory();
      this.setState((previousState) => ({
        ...previousState,
        items: previousState.items.map((item) => {
          if (item.id !== eventId) return item;
          return updatedEvent;
        }),
      }));
    });
  };

  onClickDeleteEvent = ({ eventId }) => {
    const { elementId, personId, onChangeHistory } = this.props;
    return deleteElementHistoryEventService(personId, elementId, eventId).then(
      (response) => {
        const updatedEvent = response.data.events[0];
        onChangeHistory();
        this.setState((previousState) => ({
          ...previousState,
          items: previousState.items.map((item) => {
            if (item.id !== eventId) return item;
            return updatedEvent;
          }),
        }));
      }
    );
  };

  onClickRestoreEvent = ({ eventId }) => {
    const { elementId, personId, onChangeHistory } = this.props;
    return restoreElementHistoryEventService(personId, elementId, eventId).then(
      (response) => {
        const updatedEvent = response.data.events[0];
        onChangeHistory();
        this.setState((previousState) => ({
          ...previousState,
          items: previousState.items.map((item) => {
            if (item.id !== eventId) return item;
            return updatedEvent;
          }),
        }));
      }
    );
  };

  onClickShowElementVersion = (version) => {
    const { showModal, elementId } = this.props;
    getVersionsService({
      resources: [`cri:element/${elementId}/version/${version}`],
    }).then(({ data }) => {
      const versionData = data[0];
      showModal('richText', {
        title: versionData.name,
        subtitle: (
          <>
            v{version} ·{' '}
            <DateTime includeTime date={versionData.date}></DateTime>
          </>
        ),
        files: versionData.files,
        description: versionData.description,
        fullWidth: true,
        onClose: this.onClickShowSelf,
        elementId: elementId,
      });
    });
  };

  onClickShowSelf = () => {
    const {
      showModal,
      personId,
      elementId,
      onChangeHistory,
      elementName,
      onClose,
      subtitle,
    } = this.props;
    showModal('elementHistory', {
      fullWidth: true,
      maxWidth: '500px',
      subtitle,
      cancelButtonText: localeLookup('translations.Close'),
      title: localeLookup('translations.History'),
      elementId,
      personId,
      elementName,
      onChangeHistory,
      onClose,
    });
  };

  onClickShowSignature = ({
    signature,
    signee,
    signatureMetadata,
    date,
    signatureGatheredBy,
    signedById,
    signatureGatheredById,
  }) => {
    const { showModal, elementName } = this.props;
    showModal('signatureDisplay', {
      title: localeLookup('translations.Signature'),
      fullWidth: true,
      maxWidth: '700px',
      signature,
      signatureGatheredBy,
      signatureGatheredById,
      signee,
      signatureMetadata,
      signedById,
      date,
      onClose: this.onClickShowSelf,
      onCancel: this.onClickShowSelf,
      subject: elementName,
    });
  };

  render() {
    const { items, isLoading, totalEvents, lockState } = this.state;
    const {
      title,
      cancelButtonText,
      onClose,
      personId,
      elementId,
      onChangeHistory,
      lockHistoryListActions,
      showModal,
      currentUserId,
      subtitle,
    } = this.props;

    return (
      <ModalWrapper className="element-history-modal">
        <ModalHeader title={title} onClose={onClose} subtitle={subtitle} />
        {lockState === LOCK_STATES.UNLOCKED && (
          <Button
            className="element-history-modal__reset-button"
            kind="alert"
            ghost
            onClick={() => this.onClickAddReset(false)}
          >
            {localeLookup('translations.Add reset')}
          </Button>
        )}
        {lockState === LOCK_STATES.LOCKED && (
          <AccessLevelWrapper
            acceptedLevels={[
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
            ]}
          >
            <Button
              onClick={() => this.onClickAddReset(true)}
              className="element-history-modal__reset-button"
              kind="alert"
              ghost
            >
              {localeLookup('translations.Add reset as admin')}
            </Button>
          </AccessLevelWrapper>
        )}
        <ModalBody noSpacing>
          <ElementHistoryList
            lockState={lockState}
            personId={personId}
            elementId={elementId}
            onChangeHistory={onChangeHistory}
            lockActions={lockHistoryListActions}
            showModal={showModal}
            items={items}
            isLoading={isLoading}
            totalEvents={totalEvents}
            onClickDeleteApproval={this.onClickDeleteApproval}
            onClickRestoreApproval={this.onClickRestoreApproval}
            onClickDeleteEvent={this.onClickDeleteEvent}
            onClickRestoreEvent={this.onClickRestoreEvent}
            onClickLoadAll={this.onClickLoadAll}
            currentUserId={currentUserId}
            onClickShowSignature={this.onClickShowSignature}
            onClickShowElementVersion={this.onClickShowElementVersion}
          />
        </ModalBody>

        <ModalFooter
          onCancelClick={onClose}
          cancelButtonText={cancelButtonText}
        />
      </ModalWrapper>
    );
  }
}

export default connect(mapStateToProps)(ElementHistoryModal);
