import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// Actions
import {
  requestTraineeRoleData,
  requestTraineeRoles,
} from '../../actions/traineeRolesActions';
import EmptyState from '../../components/EmptyState';
import Icon from '../../components/Icon';
// Components
import LoadScreen from '../../components/LoadScreen';
import OrganisationUnitsText from '../../components/OrganisationUnitsText';
import PersonWrapper from '../../components/PersonWrapper';
import RoleCard from '../../components/RoleCard';
import Text from '../../components/Text';
import Tooltip from '../../components/Tooltip';
import localeLookup from '../../config/locale';
import { EMPTY_ID } from '../../constants';
import { formatDate } from '../../utils/helpers';
import { trackEvent } from '../../utils/tracking';

const mapStateToProps = (state, ownProps) => ({
  roles: state.trainee.roles,
  organisationUnits: state.trainee.organisationUnits,
  persons: state.persons,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      requestTraineeRoles,
      requestTraineeRoleData,
    },
    dispatch
  ),
});

class TraineeRoles extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount() {
    const { requestTraineeRoles } = this.props;
    trackEvent('$pageview');
    requestTraineeRoles().then(() => {
      this.setState({ isLoading: false });
    });
  }

  getDateSubtitle = (roleId) => {
    const { roles, persons } = this.props;
    const { mentorId, endDate, startDate } = roles[roleId];
    if (mentorId === EMPTY_ID) return null;
    return `${formatDate(startDate)} - ${
      endDate ? `${formatDate(endDate)}` : ''
    }`;
  };

  getMentorSubtitle = (roleId) => {
    const { roles, persons } = this.props;
    const { mentorId } = roles[roleId];
    const prefix = `${localeLookup('translations.Mentor')}:`;
    if (mentorId === EMPTY_ID) return null;
    return (
      <PersonWrapper
        id={mentorId}
        render={({ name, color, tooltip }) => (
          <Text as="span" color={color}>
            <Tooltip tooltip={tooltip}>
              <span>
                {prefix} {name}
              </span>
            </Tooltip>
          </Text>
        )}
      />
    );
  };

  getRoleIcon = (roleId) => {
    const { roles } = this.props;
    const role = roles[roleId];
    const hasMentor = role.mentor !== '';
    if (hasMentor) {
      return 'users2';
    } else if (role.isExperienced) {
      return 'star';
    }
    return 'graduation-hat';
  };

  getRoleLabel = (roleId) => {
    const { roles } = this.props;
    const role = roles[roleId];
    const hasMentor = role.mentor !== '';
    if (hasMentor) {
      return localeLookup('translations.Training');
    }
    if (role.isExperienced) {
      return localeLookup('translations.Experienced');
    }
    return localeLookup('translations.Qualified');
  };

  renderRole = (id) => {
    const { roles, organisationUnits } = this.props;
    const role = roles[id];
    const hasMentor = role.mentorId !== EMPTY_ID;
    const route = `/roles/${role.id}`;
    return (
      <Link
        to={{
          pathname: route,
          state: {
            title: role.name,
            backTitle: localeLookup('translations.Your roles'),
          },
        }}
        key={id}
      >
        <RoleCard
          title={role.name}
          leftIcon={this.getRoleIcon(id)}
          clickable
          competenceLevel={role.competenceLevel}
          notifications={role.notifications}
          subtitle={
            <OrganisationUnitsText
              organisationUnits={role.organisationUnits.map(
                (id) => organisationUnits[id].name
              )}
            />
          }
          subtitle2={this.getMentorSubtitle(role.id)}
          subtitle3={this.getDateSubtitle(role.id)}
          completionPercentage={role.completionPercentage}
          donutColor={hasMentor ? 'green' : 'grey'}
          renderRight={
            <Icon
              className="role-card__arrow"
              color="grey"
              kind="chevron-right"
            />
          }
        />
      </Link>
    );
  };

  render() {
    const { isLoading } = this.state;
    const { roles, organisationUnits, persons } = this.props;
    if (isLoading) return <LoadScreen />;
    const showEmptyState = Object.keys(roles).length === 0 && !isLoading;
    return (
      <div className="main-body">
        <div className="main-content">
          {showEmptyState ? (
            <EmptyState
              fullHeight
              title={localeLookup('translations.No roles')}
              body={localeLookup(
                'translations.You have not yet been assigned any roles'
              )}
            />
          ) : (
            <div className="role-list">
              <h2 className="role-list__title">
                {localeLookup('translations.Roles')}
              </h2>
              <div className="role-list__list">
                {Object.keys(roles).map(this.renderRole)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TraineeRoles);
