import React from 'react';
import cx from 'classnames';

const ModalBody = ({
  children,
  deep,
  center,
  noSpacing,
  onScroll,
  noBottomSpacing,
}) => {
  const classes = cx({
    'modal-content__body--deep': deep,
    'modal-content__body--center': center,
    'modal-content__body--no-spacing': noSpacing,
    'modal-content__body--no-bottom-spacing': noBottomSpacing,
  });
  return (
    <div onScroll={onScroll} className={`modal-content__body ${classes}`}>
      {children}
    </div>
  );
};

export default ModalBody;
