import { format } from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import localeLookup from '../../config/locale';
import {
  ACCESS_LEVELS,
  ACTION_STATES,
  PENDING_STATES,
  TRAINING_REGISTRATION_TYPES,
} from '../../constants';
import { registerBulkTrainingService } from '../../services/trainingService';
import { compareLocal, sortBy } from '../../utils/helpers';
import Accordion from '../Accordion';
import BoxMessage from '../BoxMessage';
import Button from '../Button';
import DateFieldV2 from '../DateFieldV2';
import DropdownButton from '../DropdownButton';
import withAccessControl from '../HOC/withAccessControl';
import withPersonLookup from '../HOC/withPersonLookup';
import Icon from '../Icon';
import SignaturePad from '../SignaturePad';
import SimpleTable from '../SimpleTable';
import Text from '../Text';
import Textarea from '../Textarea';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';

const mapStateToProps = (state) => {
  const { user } = state;
  return { currentUserId: user.employeeId };
};

class BulkCompletionModal extends Component {
  constructor() {
    super();
    this.state = {
      activeStep: 'yourActions',
      elementsThatCanBeCompleted: {
        base64signature: '',
        elements: [],
        elementsGroupedByPerson: {},
        expandedPersonIds: [],
        action: '',
        showElements: false,
        isCompleted: false,
        isLoading: false,
        adminCompleteDate: new Date(),
        adminCompleteNote: '',
      },
      elementsThatCanBeCompletedWithSignature: {
        base64signature: '',
        elements: [],
        elementsGroupedByPerson: {},
        expandedPersonIds: [],
        action: '',
        showElements: false,
        isCompleted: false,
        isLoading: false,
        adminCompleteDate: new Date(),
        adminCompleteNote: '',
      },
      elementsThatCannotBeCompleted: {
        base64signature: '',
        elements: [],
        elementsGroupedByPerson: {},
        expandedPersonIds: [],
        action: '',
        showElements: false,
        isCompleted: false,
        isLoading: false,
        adminCompleteDate: new Date(),
        adminCompleteNote: '',
      },
      elementsThatCanBeCompletedWithSignatureFromOthers: {
        base64signature: '',
        elements: [],
        elementsGroupedByPerson: {},
        expandedPersonIds: [],
        elementsGroupedBySignee: {},
        action: '',
        showElements: false,
        isCompleted: false,
        isLoading: false,
        adminCompleteDate: new Date(),
        adminCompleteNote: '',
      },
    };
  }

  componentDidMount() {
    this.groupElements();
  }

  groupElements = () => {
    const { elements, lookupPerson } = this.props;
    const elementsThatCanBeCompleted = [];
    const elementsThatCanBeCompletedWithSignature = [];
    const elementsThatCannotBeCompleted = [];
    const elementsThatCanBeCompletedWithSignatureFromOthers = [];

    elements.forEach((element) => {
      const mediator = lookupPerson(element.mediatorId);
      switch (element.nextAction) {
        case ACTION_STATES.COMPLETE:
          elementsThatCanBeCompleted.push(element);
          break;
        case ACTION_STATES.COMPLETE_WITH_SIGNATURE:
          elementsThatCanBeCompletedWithSignature.push(element);
          break;
        case ACTION_STATES.REQUEST_SIGNATURE:
          // Check if element is pending otherTrainee or counterPartTrainee
          // If it is, we don't need to check if mediator is wildcardPerson, group or archived
          if (
            element.pending === PENDING_STATES.OTHER_TRAINEE ||
            element.pending === PENDING_STATES.COUNTER_PART_TRAINEE
          ) {
            elementsThatCanBeCompletedWithSignatureFromOthers.push(element);
          } else {
            if (
              !mediator ||
              mediator.isWildcardPerson ||
              mediator.isGroup ||
              mediator.isArchived
            ) {
              elementsThatCannotBeCompleted.push(element);
            } else {
              elementsThatCanBeCompletedWithSignatureFromOthers.push(element);
            }
          }
          break;
        case ACTION_STATES.LOCKED:
        case ACTION_STATES.PENDING_COUNTERPART:
          elementsThatCannotBeCompleted.push(element);
          break;
        default:
          break;
      }
    });
    this.setState({
      activeStep:
        elementsThatCanBeCompleted.length === 0 &&
        elementsThatCanBeCompletedWithSignature.length === 0 &&
        elementsThatCannotBeCompleted.length === 0 &&
        elementsThatCanBeCompletedWithSignatureFromOthers.length > 0
          ? 'othersActions'
          : 'yourActions',
      elementsThatCanBeCompleted: {
        ...this.state.elementsThatCanBeCompleted,
        elements: elementsThatCanBeCompleted,
        elementsGroupedByPerson: this.groupElementsByPerson(
          elementsThatCanBeCompleted
        ),
      },
      elementsThatCanBeCompletedWithSignature: {
        ...this.state.elementsThatCanBeCompletedWithSignature,
        elements: elementsThatCanBeCompletedWithSignature,
        elementsGroupedByPerson: this.groupElementsByPerson(
          elementsThatCanBeCompletedWithSignature
        ),
      },
      elementsThatCannotBeCompleted: {
        ...this.state.elementsThatCannotBeCompleted,
        elements: elementsThatCannotBeCompleted,
        elementsGroupedByPerson: this.groupElementsByPerson(
          elementsThatCannotBeCompleted
        ),
      },
      elementsThatCanBeCompletedWithSignatureFromOthers: {
        ...this.state.elementsThatCanBeCompletedWithSignatureFromOthers,
        elements: elementsThatCanBeCompletedWithSignatureFromOthers,
        elementsGroupedBySignee: this.groupElementsBySignee(
          elementsThatCanBeCompletedWithSignatureFromOthers
        ),
        elementsGroupedByPerson: this.groupElementsByPerson(
          elementsThatCanBeCompletedWithSignatureFromOthers
        ),
      },
    });
  };

  groupElementsByPerson = (elements) => {
    return elements.reduce((acc, element) => {
      if (acc[element.personId]) {
        return {
          ...acc,
          [element.personId]: [...acc[element.personId], element.elementId],
        };
      } else {
        return {
          ...acc,
          [element.personId]: [element.elementId],
        };
      }
    }, {});
  };

  groupElementsBySignee = (elements) => {
    return elements.reduce((acc, element) => {
      const elementSigneeId =
        element.pending === PENDING_STATES.OTHER_TRAINEE ||
        element.pending === PENDING_STATES.COUNTER_PART_TRAINEE
          ? element.personId
          : element.mediatorId;
      if (acc[elementSigneeId]) {
        return {
          ...acc,
          [elementSigneeId]: {
            ...acc[elementSigneeId],
            elements: [...acc[elementSigneeId].elements, element],
          },
        };
      } else {
        return {
          ...acc,
          [elementSigneeId]: {
            showElements: false,
            isLoading: false,
            action: '',
            isCompleted: false,
            adminCompleteDate: new Date(),
            adminCompleteNote: '',
            base64signature: '',
            elements: [element],
          },
        };
      }
    }, {});
  };

  onChangeAction = (state, action, signeeId) => {
    if (
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
      signeeId
    ) {
      this.setState({
        [state]: {
          ...this.state[state],
          elementsGroupedBySignee: {
            ...this.state[state].elementsGroupedBySignee,
            [signeeId]: {
              ...this.state[state].elementsGroupedBySignee[signeeId],
              action,
              showElements: false,
            },
          },
        },
      });
    } else {
      this.setState({
        [state]: {
          ...this.state[state],
          showElements: false,
          action,
        },
      });
    }
  };

  onChangeAdminCompleteDate = (state, date, signeeId) => {
    if (
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
      signeeId
    ) {
      this.setState({
        [state]: {
          ...this.state[state],
          elementsGroupedBySignee: {
            ...this.state[state].elementsGroupedBySignee,
            [signeeId]: {
              ...this.state[state].elementsGroupedBySignee[signeeId],
              adminCompleteDate: date,
            },
          },
        },
      });
    } else {
      this.setState({
        [state]: {
          ...this.state[state],
          adminCompleteDate: date,
        },
      });
    }
  };

  onChangeAdminCompleteNote = (state, e, signeeId) => {
    if (
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
      signeeId
    ) {
      this.setState({
        [state]: {
          ...this.state[state],
          elementsGroupedBySignee: {
            ...this.state[state].elementsGroupedBySignee,
            [signeeId]: {
              ...this.state[state].elementsGroupedBySignee[signeeId],
              adminCompleteNote: e.target.value,
            },
          },
        },
      });
    } else {
      this.setState({
        [state]: {
          ...this.state[state],
          adminCompleteNote: e.target.value,
        },
      });
    }
  };

  onClickCompleteElements = (state) => {
    const { onElementsCompleted } = this.props;
    this.setState(
      {
        [state]: {
          ...this.state[state],
          isLoading: true,
        },
      },
      () => {
        const { elementsGroupedByPerson } = this.state[state];
        const personIds = Object.keys(elementsGroupedByPerson);
        const data = personIds.reduce((acc, personId) => {
          return [
            ...acc,
            { menteeId: personId, elements: elementsGroupedByPerson[personId] },
          ];
        }, []);
        registerBulkTrainingService({
          type: TRAINING_REGISTRATION_TYPES.STANDARD,
          operations: data,
        }).then(() => {
          this.onCompletedElements(state, personIds);
        });
      }
    );
  };

  onClickAdminCompleteElements = (state, signeeId) => {
    const { onElementsCompleted } = this.props;
    const { adminCompleteDate, adminCompleteNote } = this.state[state];

    if (
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
      signeeId
    ) {
      this.setState(
        {
          [state]: {
            ...this.state[state],
            elementsGroupedBySignee: {
              ...this.state[state].elementsGroupedBySignee,
              [signeeId]: {
                ...this.state[state].elementsGroupedBySignee[signeeId],
                isLoading: true,
              },
            },
          },
        },
        () => {
          const { elementsGroupedBySignee } = this.state[state];
          const mediatorElements = elementsGroupedBySignee[signeeId].elements;
          const adminCompleteNote =
            elementsGroupedBySignee[signeeId].adminCompleteNote;
          const adminCompleteDate =
            elementsGroupedBySignee[signeeId].adminCompleteDate;

          const mediatorElementsGroupedByPerson = mediatorElements.reduce(
            (acc, element) => {
              if (acc[element.personId]) {
                return {
                  ...acc,
                  [element.personId]: [
                    ...acc[element.personId],
                    element.elementId,
                  ],
                };
              } else {
                return {
                  ...acc,
                  [element.personId]: [element.elementId],
                };
              }
            },
            {}
          );
          const personIds = Object.keys(mediatorElementsGroupedByPerson);

          const data = personIds.reduce((acc, personId) => {
            return [
              ...acc,
              {
                menteeId: personId,
                elements: mediatorElementsGroupedByPerson[personId],
              },
            ];
          }, []);
          registerBulkTrainingService({
            type: TRAINING_REGISTRATION_TYPES.ADMIN_APPROVAL,
            note: adminCompleteNote,
            validFromDate: format(adminCompleteDate, 'yyyy-MM-dd'),
            operations: data,
          }).then(() => {
            onElementsCompleted(personIds);
            this.setState({
              [state]: {
                ...this.state[state],
                elementsGroupedBySignee: {
                  ...this.state[state].elementsGroupedBySignee,
                  [signeeId]: {
                    ...this.state[state].elementsGroupedBySignee[signeeId],
                    isLoading: false,
                    isCompleted: true,
                    action: '',
                  },
                },
              },
            });
          });
        }
      );
    } else {
      this.setState(
        {
          [state]: {
            ...this.state[state],
            isLoading: true,
          },
        },
        () => {
          const { elementsGroupedByPerson } = this.state[state];
          const personIds = Object.keys(elementsGroupedByPerson);
          const data = personIds.reduce((acc, personId) => {
            return [
              ...acc,
              {
                menteeId: personId,
                elements: elementsGroupedByPerson[personId],
              },
            ];
          }, []);
          registerBulkTrainingService({
            type: TRAINING_REGISTRATION_TYPES.ADMIN_APPROVAL,
            note: adminCompleteNote,
            validFromDate: format(adminCompleteDate, 'yyyy-MM-dd'),
            operations: data,
          }).then(() => {
            onElementsCompleted(personIds);
            this.setState({
              [state]: {
                ...this.state[state],
                isLoading: false,
                isCompleted: true,
                action: '',
              },
            });
          });
        }
      );
    }
  };

  onClickNext = () => {
    this.setState({
      activeStep: 'othersActions',
    });
  };

  onClickSignElements = (state, signeeId) => {
    const { onElementsCompleted } = this.props;
    const { base64signature } = this.state[state];
    if (
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
      signeeId
    ) {
      this.setState(
        {
          [state]: {
            ...this.state[state],
            elementsGroupedBySignee: {
              ...this.state[state].elementsGroupedBySignee,
              [signeeId]: {
                ...this.state[state].elementsGroupedBySignee[signeeId],
                isLoading: true,
              },
            },
          },
        },
        () => {
          const { elementsGroupedBySignee } = this.state[state];
          const mediatorElements = elementsGroupedBySignee[signeeId].elements;

          const mediatorElementsGroupedByPerson = mediatorElements.reduce(
            (acc, element) => {
              if (acc[element.personId]) {
                return {
                  ...acc,
                  [element.personId]: [
                    ...acc[element.personId],
                    element.elementId,
                  ],
                };
              } else {
                return {
                  ...acc,
                  [element.personId]: [element.elementId],
                };
              }
            },
            {}
          );
          const personIds = Object.keys(mediatorElementsGroupedByPerson);

          const data = personIds.reduce((acc, personId) => {
            return [
              ...acc,
              {
                menteeId: personId,
                elements: mediatorElementsGroupedByPerson[personId],
              },
            ];
          }, []);
          registerBulkTrainingService({
            type: TRAINING_REGISTRATION_TYPES.STANDARD,
            signature: { signature: elementsGroupedBySignee[signeeId].base64signature, metadata: {} },
            operations: data,
            onBehalfOfOther: true,
            signeeId: signeeId,
          }).then(() => {
            onElementsCompleted(personIds);
            this.setState({
              [state]: {
                ...this.state[state],
                elementsGroupedBySignee: {
                  ...this.state[state].elementsGroupedBySignee,
                  [signeeId]: {
                    ...this.state[state].elementsGroupedBySignee[signeeId],
                    isLoading: false,
                    isCompleted: true,
                    action: '',
                  },
                },
              },
            });
          });
        }
      );
    } else {
      this.setState(
        {
          [state]: {
            ...this.state[state],
            isLoading: true,
          },
        },
        () => {
          const { elementsGroupedByPerson } = this.state[state];
          const personIds = Object.keys(elementsGroupedByPerson);
          const data = personIds.reduce((acc, personId) => {
            return [
              ...acc,
              {
                menteeId: personId,
                elements: elementsGroupedByPerson[personId],
              },
            ];
          }, []);
          registerBulkTrainingService({
            type: TRAINING_REGISTRATION_TYPES.STANDARD,
            signature: { signature: base64signature, metadata: {} },
            operations: data,
          }).then(() => {
            this.onCompletedElements(state, personIds);
          });
        }
      );
    }
  };

  onClickToggleExpandPerson = (state, personId) => {
    this.setState((prevState) => ({
      [state]: {
        ...this.state[state],
        expandedPersonIds: prevState[state].expandedPersonIds.includes(personId)
          ? prevState[state].expandedPersonIds.filter(
              (expandedId) => expandedId !== personId
            )
          : [...prevState[state].expandedPersonIds, personId],
      },
    }));
  };

  onClickToggleShowElements = (state, signeeId) => {
    if (
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
      signeeId
    ) {
      this.setState((prevState) => ({
        [state]: {
          ...this.state[state],
          elementsGroupedBySignee: {
            ...this.state[state].elementsGroupedBySignee,
            [signeeId]: {
              ...this.state[state].elementsGroupedBySignee[signeeId],
              action: '',
              showElements:
                !prevState[state].elementsGroupedBySignee[signeeId]
                  .showElements,
            },
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        [state]: {
          ...this.state[state],
          showElements: !prevState[state].showElements,
          action: '',
        },
      }));
    }
  };

  onCompletedElements = (state, personIds, signeeId) => {
    const { elementsThatCanBeCompletedWithSignatureFromOthers } = this.state;
    const { onElementsCompleted, lookupPerson } = this.props;
    onElementsCompleted(personIds).then((updatedElements) => {
      const elementsThatCanBeFurtherCompletedByOthers = updatedElements.filter(
        (element) => {
          const canCollectSignature =
            element.nextAction === ACTION_STATES.REQUEST_SIGNATURE;
          if (!canCollectSignature) return false;

          const isPendingTrainer =
            element.pending === PENDING_STATES.OTHER_TRAINER ||
            element.pending === PENDING_STATES.COUNTER_PART_TRAINER;

          const isPendingTrainee =
            element.pending === PENDING_STATES.OTHER_TRAINEE ||
            element.pending === PENDING_STATES.COUNTER_PART_TRAINEE;

          if (isPendingTrainee) {
            return true;
          }

          if (isPendingTrainer) {
            const mediator = lookupPerson(element.mediatorId);
            if (
              !mediator ||
              mediator.isWildcardPerson ||
              mediator.isGroup ||
              mediator.isArchived
            ) {
              return false;
            }
            return true;
          }
        }
      );
      debugger;
      if (elementsThatCanBeFurtherCompletedByOthers.length > 0) {
        const newElementsArray =
          elementsThatCanBeFurtherCompletedByOthers.reduce((acc, element) => {
            if (
              acc.find(
                (e) =>
                  e.elementId === element.elementId &&
                  e.personId === element.personId
              )
            ) {
              return acc;
            }
            return [...acc, element];
          }, elementsThatCanBeCompletedWithSignatureFromOthers.elements);
        this.setState({
          elementsThatCanBeCompletedWithSignatureFromOthers: {
            ...elementsThatCanBeCompletedWithSignatureFromOthers,
            elements: newElementsArray,
            elementsGroupedBySignee:
              this.groupElementsBySignee(newElementsArray),
            elementsGroupedByPerson:
              this.groupElementsByPerson(newElementsArray),
          },
        });
      }
    });
    if (signeeId) {
      this.setState({
        [state]: {
          ...this.state[state],
          elementsGroupedBySignee: {
            ...this.state[state].elementsGroupedBySignee,
            [signeeId]: {
              ...this.state[state].elementsGroupedBySignee[signeeId],
              isLoading: false,
              isCompleted: true,
              action: '',
            },
          },
        },
      });
    } else {
      this.setState({
        [state]: {
          ...this.state[state],
          isLoading: false,
          isCompleted: true,
          action: '',
        },
      });
    }
  };

  onEndSignature = (state, base64signature, signeeId) => {
    if (
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
      signeeId
    ) {
      this.setState({
        [state]: {
          ...this.state[state],
          elementsGroupedBySignee: {
            ...this.state[state].elementsGroupedBySignee,
            [signeeId]: {
              ...this.state[state].elementsGroupedBySignee[signeeId],
              base64signature,
            },
          },
        },
      });
    } else {
      this.setState({
        [state]: {
          ...this.state[state],
          base64signature,
        },
      });
    }
  };

  renderActionDropdown = (state, signeeId) => {
    const { hasAccess } = this.props;
    const {
      elementsThatCanBeCompleted,
      elementsThatCanBeCompletedWithSignature,
      elementsThatCannotBeCompleted,
      elementsThatCanBeCompletedWithSignatureFromOthers,
    } = this.state;
    const { isLoading, action } = this.state[state];
    const getIsLoading = () => {
      if (
        state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
        signeeId
      ) {
        return elementsThatCanBeCompletedWithSignatureFromOthers
          .elementsGroupedBySignee[signeeId].isLoading;
      }
      return isLoading;
    };
    const getIsCompleted = () => {
      if (
        state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
        signeeId
      ) {
        return elementsThatCanBeCompletedWithSignatureFromOthers
          .elementsGroupedBySignee[signeeId].isCompleted;
      }
      return this.state[state].isCompleted;
    };
    const isAdministrator = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.teamadministrator,
    ]);
    const selectedAction =
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' && signeeId
        ? elementsThatCanBeCompletedWithSignatureFromOthers
            .elementsGroupedBySignee[signeeId].action
        : action;

    const getActions = () => {
      switch (state) {
        case 'elementsThatCanBeCompleted':
          return [
            {
              label: elementsThatCanBeCompleted.isCompleted
                ? localeLookup('translations.Completed')
                : localeLookup('translations.Complete'),
              id: 'complete',
            },
            ...(isAdministrator
              ? [
                  {
                    label: elementsThatCanBeCompleted.isCompleted
                      ? localeLookup('translations.Completed as admin')
                      : localeLookup('translations.Complete as admin'),
                    id: 'adminComplete',
                  },
                ]
              : []),
          ];
        case 'elementsThatCanBeCompletedWithSignature':
          return [
            {
              label: elementsThatCanBeCompletedWithSignature.isCompleted
                ? localeLookup('translations.Signed')
                : localeLookup('translations.Sign'),
              id: 'sign',
            },
            ...(isAdministrator
              ? [
                  {
                    label: elementsThatCanBeCompletedWithSignature.isCompleted
                      ? localeLookup('translations.Completed as admin')
                      : localeLookup('translations.Complete as admin'),
                    id: 'adminComplete',
                  },
                ]
              : []),
          ];
        case 'elementsThatCannotBeCompleted':
          if (isAdministrator) {
            return [
              {
                label: elementsThatCannotBeCompleted.isCompleted
                  ? localeLookup('translations.Completed as admin')
                  : localeLookup('translations.Complete as admin'),
                id: 'adminComplete',
              },
            ];
          }
          return [];
        case 'elementsThatCanBeCompletedWithSignatureFromOthers':
          if (signeeId) {
            return [
              {
                label: elementsThatCanBeCompletedWithSignatureFromOthers
                  .elementsGroupedBySignee[signeeId].isCompleted
                  ? localeLookup('translations.Received signature')
                  : localeLookup('translations.Receive signature'),
                id: 'sign',
              },
              ...(isAdministrator
                ? [
                    {
                      label: elementsThatCanBeCompletedWithSignatureFromOthers
                        .elementsGroupedBySignee[signeeId].isCompleted
                        ? localeLookup('translations.Completed as admin')
                        : localeLookup('translations.Complete as admin'),
                      id: 'adminComplete',
                    },
                  ]
                : []),
            ];
          }
          return [];

        default:
          return [];
      }
    };
    const actions = getActions();
    if (actions.length === 0) return null;
    return (
      <DropdownButton
        isLoading={getIsLoading()}
        disabled={getIsLoading() || getIsCompleted()}
        color={getIsCompleted() ? 'green' : ''}
        icon={getIsCompleted() ? 'check' : null}
        onChange={(action) => this.onChangeAction(state, action, signeeId)}
        selectedItem={selectedAction}
        placeholder={
          getIsCompleted()
            ? localeLookup('translations.Completed')
            : localeLookup('translations.Action')
        }
        items={actions}
      />
    );
  };

  renderAdminCompletionAccordionContent = (state, signeeId) => {
    const { adminCompleteDate, adminCompleteNote, isLoading } =
      this.state[state];
    const getAdminCompletionDate = () => {
      if (
        state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
        signeeId
      ) {
        return this.state[state].elementsGroupedBySignee[signeeId]
          .adminCompleteDate;
      } else {
        return adminCompleteDate;
      }
    };
    const getAdminCompletionNote = () => {
      if (
        state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
        signeeId
      ) {
        return (
          this.state[state].elementsGroupedBySignee[signeeId]
            .adminCompleteNote || ''
        );
      } else {
        return adminCompleteNote;
      }
    };
    return (
      <>
        <DateFieldV2
          label={localeLookup('translations.Valid from')}
          value={getAdminCompletionDate()}
          maxDate={new Date()}
          onChange={(date) =>
            this.onChangeAdminCompleteDate(state, date, signeeId)
          }
        />
        <Textarea
          maxLength={255}
          placeholder={localeLookup('translations.Add a note')}
          onChange={(e) => this.onChangeAdminCompleteNote(state, e, signeeId)}
          value={getAdminCompletionNote()}
        />
        <Button
          fullWidth
          className="bulk-completion-modal__section-button"
          kind="darkui"
          disabled={isLoading}
          onClick={() => this.onClickAdminCompleteElements(state, signeeId)}
        >
          {localeLookup('translations.Complete')}
        </Button>
      </>
    );
  };

  renderCollectSignatureSections = () => {
    const { lookupPerson } = this.props;
    const { elementsThatCanBeCompletedWithSignatureFromOthers } = this.state;
    return Object.keys(
      elementsThatCanBeCompletedWithSignatureFromOthers.elementsGroupedBySignee
    ).map((mediatorId) => {
      const mediator = lookupPerson(mediatorId);
      if (!mediator) return null;
      return (
        <section key={mediatorId} className="bulk-completion-modal__section">
          {this.renderSectionHeader(
            'elementsThatCanBeCompletedWithSignatureFromOthers',
            'user-sign',
            'green',
            '{0} elements require signature from',
            mediatorId,
            mediator.name
          )}
          {this.renderSectionAccordion(
            'elementsThatCanBeCompletedWithSignatureFromOthers',
            mediatorId
          )}
        </section>
      );
    });
  };

  renderCompletionAccordionContent = (state) => {
    const { isLoading } = this.state[state];
    return (
      <>
        <Button
          fullWidth
          className="bulk-completion-modal__section-button"
          kind="darkui"
          disabled={isLoading}
          onClick={() => this.onClickCompleteElements(state)}
        >
          {localeLookup('translations.Complete')}
        </Button>
      </>
    );
  };

  renderElements = (state, signeeId) => {
    const { lookupPerson } = this.props;
    const { elements: stateElements, elementsGroupedBySignee } =
      this.state[state];
    const elementsToShow =
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' && signeeId
        ? elementsGroupedBySignee[signeeId].elements
        : stateElements;
    const sortedElements = sortBy(elementsToShow, [
      (a, b) => {
        return compareLocal(a.elementName, b.elementName);
      },
    ]);

    if (
      state === 'elementsThatCannotBeCompleted' ||
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers'
    ) {
      const rows = sortedElements.map((element) => {
        const person = lookupPerson(element.personId);
        const signee = lookupPerson(
          element.pending === PENDING_STATES.OTHER_TRAINEE ||
            element.pending === PENDING_STATES.COUNTER_PART_TRAINEE
            ? element.personId
            : element.mediatorId,
          {
            handleEmptyId: true,
            isLocked: true,
          }
        );
        if (!person) return null;
        return [
          <Text title={element.elementName} size="sm" truncate>
            {element.elementName}
          </Text>,
          person.name,
          signee || element.nextAction === ACTION_STATES.LOCKED ? (
            <Text
              size="sm"
              color={
                state === 'elementsThatCannotBeCompleted' ? 'red' : 'green'
              }
            >
              {element.nextAction === ACTION_STATES.LOCKED ? (
                localeLookup('translations.Locked')
              ) : signee ? (
                <>
                  {localeLookup('translations.Requires signature from {0}', [
                    `${signee.name} ${signee.suffix}`,
                  ])}
                </>
              ) : null}
            </Text>
          ) : null,
        ];
      });
      return (
        <SimpleTable
          fontSize="sm"
          rows={rows}
          headerRow={[
            localeLookup('translations.Element'),
            localeLookup('translations.Person'),
            '',
          ]}
          tableLayout="fixed"
          columnWidths={['30%', '30%', '40%']}
        />
      );
    } else {
      const rows = sortedElements.map((element) => {
        const person = lookupPerson(element.personId);
        if (!person) return null;
        return [`${element.elementName}`, person.name];
      });

      return (
        <SimpleTable
          fontSize="sm"
          rows={rows}
          headerRow={[
            localeLookup('translations.Element'),
            localeLookup('translations.Person'),
          ]}
          columnWidths={['50%', '50%']}
        />
      );
    }
  };

  renderElementsThatCanBeCompletedSection = () => {
    return (
      <section className="bulk-completion-modal__section">
        {this.renderSectionHeader(
          'elementsThatCanBeCompleted',
          'check',
          'green',
          '{0} elements can be completed'
        )}
        {this.renderSectionAccordion('elementsThatCanBeCompleted')}
      </section>
    );
  };

  renderElementsThatCanBeCompletedWithSignatureFromOthersSection = () => {
    return (
      <section className="bulk-completion-modal__section">
        {this.renderSectionHeader(
          'elementsThatCanBeCompletedWithSignatureFromOthers',
          'user-sign',
          'green',
          '{0} elements require signature from others'
        )}
        {this.renderSectionAccordion(
          'elementsThatCanBeCompletedWithSignatureFromOthers'
        )}
      </section>
    );
  };

  renderElementsThatCanBeCompletedWithSignatureSection = () => {
    return (
      <section className="bulk-completion-modal__section">
        {this.renderSectionHeader(
          'elementsThatCanBeCompletedWithSignature',
          'sign',
          'green',
          '{0} elements require signature from you'
        )}
        {this.renderSectionAccordion('elementsThatCanBeCompletedWithSignature')}
      </section>
    );
  };

  renderElementsThatCannotBeCompletedSection = () => {
    return (
      <section className="bulk-completion-modal__section">
        {this.renderSectionHeader(
          'elementsThatCannotBeCompleted',
          'cross-circle',
          'red',
          '{0} elements can not be completed'
        )}
        {this.renderSectionAccordion('elementsThatCannotBeCompleted')}
      </section>
    );
  };

  renderSectionAccordion = (state, signeeId) => {
    const { action, showElements } = this.state[state];
    const showElementsForState =
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' && signeeId
        ? this.state[state].elementsGroupedBySignee[signeeId].showElements
        : showElements;
    const selectedAction =
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' && signeeId
        ? this.state[state].elementsGroupedBySignee[signeeId].action
        : action;
    return (
      <Accordion isOpen={selectedAction || showElementsForState}>
        <main
          style={{
            maxHeight: showElementsForState ? '300px' : 'none',
            overflowY: 'auto',
          }}
          className="bulk-completion-modal__section-main"
        >
          {showElementsForState ? this.renderElements(state, signeeId) : null}
          {selectedAction === 'complete'
            ? this.renderCompletionAccordionContent(state)
            : null}
          {selectedAction === 'sign'
            ? this.renderSignatureCompletionAccordionContent(state, signeeId)
            : null}
          {selectedAction === 'adminComplete'
            ? this.renderAdminCompletionAccordionContent(state, signeeId)
            : null}
        </main>
      </Accordion>
    );
  };

  renderSectionHeader = (
    state,
    icon,
    iconColor,
    title,
    signeeId,
    signeeName
  ) => {
    const { elements, showElements, elementsGroupedBySignee } =
      this.state[state];
    const elementCount =
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' && signeeId
        ? elementsGroupedBySignee[signeeId].elements.length
        : elements.length;

    const showElementsForState =
      state === 'elementsThatCanBeCompletedWithSignatureFromOthers' && signeeId
        ? this.state[state].elementsGroupedBySignee[signeeId].showElements
        : showElements;
    return (
      <header className="bulk-completion-modal__section-header">
        <div className="bulk-completion-modal__section-header-left">
          <Icon kind={icon} color={iconColor}></Icon>
        </div>
        <div className="bulk-completion-modal__section-header-center">
          <Text>
            {localeLookup(`translations.${title}`, [elementCount])}{' '}
            {signeeName ? (
              <Text as="span" bold>
                {signeeName}
              </Text>
            ) : null}
          </Text>
          <Button
            kind="link-style"
            onClick={() => this.onClickToggleShowElements(state, signeeId)}
          >
            {showElementsForState
              ? localeLookup('translations.Hide elements')
              : localeLookup('translations.Show elements')}
          </Button>
        </div>
        <div className="bulk-completion-modal__section-header-right">
          {this.renderActionDropdown(state, signeeId)}
          {state === 'elementsThatCanBeCompletedWithSignatureFromOthers' &&
          !signeeId ? (
            <Text size="sm" color="green">
              {localeLookup('translations.Click next to handle')}
            </Text>
          ) : null}
        </div>
      </header>
    );
  };

  renderSignatureCompletionAccordionContent = (state, signeeId) => {
    const { base64signature, isLoading } = this.state[state];
    const { lookupPerson, currentUserId } = this.props;
    const getSigneeName = () => {
      const person = signeeId
        ? lookupPerson(signeeId)
        : lookupPerson(currentUserId);
      if (person) {
        return `${person.name} (${person.initials}${
          person.employeeNumber ? ` · ${person.employeeNumber}` : ''
        })`;
      }

      return '';
    };
    const confirmDisabled = signeeId
      ? !this.state[state].elementsGroupedBySignee[signeeId].base64signature
      : !base64signature;
    return (
      <>
        <SignaturePad
          signee={getSigneeName()}
          onEndSignature={(signature) =>
            this.onEndSignature(state, signature, signeeId)
          }
        />
        <Button
          fullWidth
          className="bulk-completion-modal__section-button"
          kind="darkui"
          disabled={isLoading || confirmDisabled}
          onClick={() => this.onClickSignElements(state, signeeId)}
        >
          {localeLookup('translations.Sign')}
        </Button>
      </>
    );
  };

  render() {
    const { subtitle, onClose } = this.props;
    const {
      elementsThatCanBeCompleted,
      elementsThatCanBeCompletedWithSignature,
      elementsThatCannotBeCompleted,
      elementsThatCanBeCompletedWithSignatureFromOthers,
      activeStep,
    } = this.state;

    const steps = [
      localeLookup('translations.Your actions'),
      localeLookup('translations.Actions of others'),
    ];

    const showSteps =
      elementsThatCanBeCompletedWithSignatureFromOthers.elements.length > 0 &&
      (elementsThatCanBeCompleted.elements.length > 0 ||
        elementsThatCanBeCompletedWithSignature.elements.length > 0 ||
        elementsThatCannotBeCompleted.elements.length > 0);

    return (
      <ModalWrapper className="bulk-completion-modal">
        <ModalHeader
          title={localeLookup('translations.Complete')}
          subtitle={subtitle}
          onClose={onClose}
          steps={showSteps ? steps : null}
          activeStepIndex={activeStep === 'yourActions' ? 0 : 1}
        />
        {showSteps && activeStep === 'yourActions' ? (
          <BoxMessage spacing="md" type="info" icon="info-circle">
            {localeLookup(
              'translations.Some selected elements require signatures from others. Click “Next” to handle these elements'
            )}
          </BoxMessage>
        ) : null}
        <ModalBody onScroll={this.onScrollBody}>
          {activeStep === 'yourActions' ? (
            <>
              {elementsThatCanBeCompleted.elements.length > 0
                ? this.renderElementsThatCanBeCompletedSection()
                : null}
              {elementsThatCanBeCompletedWithSignature.elements.length > 0
                ? this.renderElementsThatCanBeCompletedWithSignatureSection()
                : null}
              {elementsThatCannotBeCompleted.elements.length > 0
                ? this.renderElementsThatCannotBeCompletedSection()
                : null}
              {elementsThatCanBeCompletedWithSignatureFromOthers.elements
                .length > 0
                ? this.renderElementsThatCanBeCompletedWithSignatureFromOthersSection()
                : null}
            </>
          ) : null}
          {activeStep === 'othersActions'
            ? this.renderCollectSignatureSections()
            : null}
        </ModalBody>
        <ModalFooter
          cancelButtonText={localeLookup('translations.Close')}
          onCancelClick={onClose}
          confirmButtonText={localeLookup('translations.Next')}
          onConfirmClick={
            activeStep === 'yourActions' && showSteps ? this.onClickNext : null
          }
        />
      </ModalWrapper>
    );
  }
}

export default compose(
  connect(mapStateToProps),
  withAccessControl,
  withPersonLookup
)(BulkCompletionModal);
