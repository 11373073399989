/* eslint-disable react/react-in-jsx-scope */
import cx from 'classnames';
import { motion } from 'framer-motion';
import { Component } from 'react';
import { connect } from 'react-redux';
import { AutoSizer, List, MultiGrid } from 'react-virtualized';
import { bindActionCreators } from 'redux';
import Aside from '../../components/Aside';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import CheckboxButton from '../../components/CheckboxButton';
import DateTime from '../../components/DateTime';
import withAccessControl from '../../components/HOC/withAccessControl';
import WithEditorActions from '../../components/HOC/withEditorActions';
import WithModals from '../../components/HOC/withModals';
import WithPersonActions from '../../components/HOC/withPersonActions';
import WithGroupActions from '../../components/HOC/withPersonGroupActions';
import { Header } from '../../components/Header';
import Icon from '../../components/Icon';
import NotificationCounter from '../../components/NotificationCounter';
import TableCell from '../../components/TableCell';
import TableCellEditable from '../../components/TableCellEditable';
import TableHeaderCell from '../../components/TableHeaderCell';
import Tabs from '../../components/Tabs';
import Tooltip from '../../components/Tooltip';
import SideNavFilter from '../../components/sideNav/SideNavFilter';
import SideNavItem from '../../components/sideNav/SideNavItem';
import SideNavList from '../../components/sideNav/SideNavList';
import SideNavListSection from '../../components/sideNav/SideNavListSection';
import SideNavTitle from '../../components/sideNav/SideNavTitle';
import SideNavVirtualListWrapper from '../../components/sideNav/SideNavVirtualListWrapper';
import localeLookup from '../../config/locale';
import {
  ACCESS_LEVELS,
  ORGANISATION_UNIT_STATES,
  PERSON_STATES,
  USER_TYPES,
  VALID_PERSON_STATES,
} from '../../constants';
import {
  updateSpaceCreateModulePermissionsService,
  updateSpaceCreateRolePermissionsService,
} from '../../services/contentService';
import {
  updateGroupNameService,
  updateGroupShortNameService,
} from '../../services/groupService';
import { getLanguagesService } from '../../services/localizationService';
import {
  addPersonPermissionsService,
  changeInitialsService,
  changeLanguageService,
  changeNameService,
  getAccessLevels,
  getPersonsWithAccessLevelsService,
  removePersonPermissionsService,
} from '../../services/personsService';
import { getGroup, getGroups } from '../../slices/groupsSlice';
import { getAllOrganisationUnits } from '../../slices/organisationUnitsSlice';
import { getPersons } from '../../slices/personsSlice';
import { getAllSpaces, getSpaceStatus } from '../../slices/spaceSlice';
import {
  compareDates,
  compareEmpty,
  compareFalsy,
  compareLocal,
  getQueryStringParams,
  sortBy,
} from '../../utils/helpers';
import UserAdministrationOtherFiltersFilter from '../Organisation/UserAdministrationOtherFiltersFilter';
import UserAdministrationPermissionFilter from '../Organisation/UserAdministrationPermissionFilter';
import Text from '../../components/Text';
import { debounce, pickBy } from 'lodash';
import { compose } from 'redux';

const AnimatedAside = motion(Aside);

const submenuVariants = {
  hidden: {
    x: '-100%',
    transition: {
      ease: 'easeIn',
    },
  },
  visible: {
    x: 0,
    transition: {
      ease: 'easeIn',
    },
  },
};

const mapStateToProps = (state) => ({
  persons: state.persons,
  organisationUnits: state.organisationUnits,
  loggedInUserId: state.user.employeeId,
  spaces: state.spaces.spaces,
  spacesEnabled: state.spaces.enabled,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPersons,
      getAllOrganisationUnits,
      getAllSpaces,
      getSpaceStatus,
      getGroups,
      getGroup,
    },
    dispatch
  );

const otherFiltersDefaultState = {
  activePersons: true,
  archivedPersons: true,
  userTypeNoLogin: true,
  userTypeChamp: true,
  userTypeExternal: true,
};

const permissionFilterDefaultState = {
  NoPermissions: true,
  [ACCESS_LEVELS.administrator]: true,
  [ACCESS_LEVELS.groupAdministrator]: true,
  [ACCESS_LEVELS.trainingSessionAdministrator]: true,
  [ACCESS_LEVELS.contentAdministrator]: true,
  [ACCESS_LEVELS.personAdministrator]: true,
  [ACCESS_LEVELS.userAdministrator]: true,
  [ACCESS_LEVELS.createGroups]: true,
  [ACCESS_LEVELS.createTrainingSessions]: true,
  [ACCESS_LEVELS.createRoles]: true,
  [ACCESS_LEVELS.createModules]: true,
  [ACCESS_LEVELS.createPersons]: true,
  [ACCESS_LEVELS.deletePersons]: true,
  [ACCESS_LEVELS.assignAccessLevels]: true,
  [ACCESS_LEVELS.systemAdministrator]: true,
};

class PermissionMatrix extends Component {
  constructor(props) {
    super(props);
    const showPersonsAsDefault = props.hasAccess([
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.userAdministrator,
    ]);
    this.state = {
      activeView: showPersonsAsDefault ? 'persons' : 'groups',
      isLoading: true,
      isUpdating: false,
      rows: [],
      sorting: { columnId: 'name', direction: 'asc' },
      activeFilter: null,
      filterSearchQuery: '',
      filters: {
        administrators: { deselected: [] },
        persons: { deselected: [] },
        organisationUnits: { deselected: [] },
        languages: { deselected: [] },
        permissions: permissionFilterDefaultState,
        otherFilters: otherFiltersDefaultState,
      },
      personsWithAccessLevels: {},
    };
    this.setQueryParams({ view: showPersonsAsDefault ? 'persons' : 'groups' });
    this.FILTERS = [
      { title: localeLookup('translations.Persons') },
      { title: localeLookup('translations.Organisation units') },
      { title: localeLookup('translations.Language') },
      { title: localeLookup('translations.Other filters') },
    ];
  }

  componentDidMount() {
    const {
      getAllOrganisationUnits,
      getPersons,
      getAllSpaces,
      getSpaceStatus,
      personActions,
      getGroups,
    } = this.props;
    this.getQueryParams();

    Promise.all([
      getAccessLevels(),
      getLanguagesService(),
      getPersonsWithAccessLevelsService(),
      getSpaceStatus(),
      getAllSpaces(),
      getAllOrganisationUnits(),
      getPersons(),
      getGroups(),
    ]).then((responses) => {
      const [
        accessLevelsResponse,
        languagesResponse,
        personsWithAccessLevelsResponse,
        spaceStatusResponse,
      ] = responses;
      this.setState(
        {
          accessLevels: accessLevelsResponse.data,
          languages: languagesResponse.data,
          personsWithAccessLevels: personsWithAccessLevelsResponse.data,
        },
        () => {
          this.setColumns();
          this.buildRows();
        }
      );
    });
  }

  getColumnWidth = ({ index }) => {
    return this.COLUMNS[index].width;
  };

  getFilteredSubMenuItems = () => {
    const { filterSearchQuery } = this.state;
    return this.getSubMenuItems().filter((item) => {
      if (item.searchString)
        return item.searchString.includes(filterSearchQuery);
      return (
        item.name.toLowerCase().includes(filterSearchQuery) ||
        item.subtitle?.toLowerCase().includes(filterSearchQuery)
      );
    });
  };

  getFilterSelectionCount = (filterId) => {
    const { persons, organisationUnits } = this.props;
    const { filters, languages } = this.state;
    const filterState = filters[filterId];
    if (filterId === 'persons' || filterId === 'administrators')
      return Object.keys(persons).length - filterState.deselected.length;
    if (filterId === 'organisationUnits')
      return (
        Object.keys(organisationUnits).length -
        filterState.deselected.length +
        1
      ); // +1 Because of "No organisation units"
    if (filterId === 'languages')
      return Object.keys(languages).length - filterState.deselected.length + 1; // +1 Because of organisation language
  };

  getPersonsIdsThatHasAdministatorPermissionInGroup = ({ groupId }) => {
    const { personsWithAccessLevels } = this.state;
    const { persons, groups } = this.props;
    const group = groups[groupId];
    if (!group) return [];
    const activePersons = pickBy(
      persons,
      (person) => person.state === PERSON_STATES.ACTIVE
    );
    return Object.keys(activePersons).filter((personId) => {
      const person = activePersons[personId];
      const personWithAccessLevels = personsWithAccessLevels[person.id];
      const isPersonAdmin = [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.groupAdministrator,
      ].some((accessLevel) =>
        personWithAccessLevels?.accessLevels.includes(accessLevel)
      );
      if (isPersonAdmin) return true;
      return group.administrators.includes(person.id);
    });
  };

  getPersonVisibleAccessLevels = (accessLevels, personId) => {
    const permissionObject = {
      [ACCESS_LEVELS.administrator]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.administrator),
        hasParentAccessLevel: false,
      },
      [ACCESS_LEVELS.contentAdministrator]: {
        hasAccessLevel: accessLevels.includes(
          ACCESS_LEVELS.contentAdministrator
        ),
        hasParentAccessLevel: [
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.champadministrator,
        ].some((accessLevel) => accessLevels.includes(accessLevel)),
      },
      [ACCESS_LEVELS.groupAdministrator]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.groupAdministrator),
        hasParentAccessLevel: [
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.champadministrator,
        ].some((accessLevel) => accessLevels.includes(accessLevel)),
      },
      [ACCESS_LEVELS.trainingSessionAdministrator]: {
        hasAccessLevel: accessLevels.includes(
          ACCESS_LEVELS.trainingSessionAdministrator
        ),
        hasParentAccessLevel: [
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.champadministrator,
        ].some((accessLevel) => accessLevels.includes(accessLevel)),
      },
      [ACCESS_LEVELS.personAdministrator]: {
        hasAccessLevel: accessLevels.includes(
          ACCESS_LEVELS.personAdministrator
        ),
        hasParentAccessLevel: [ACCESS_LEVELS.administrator].some(
          (accessLevel) => accessLevels.includes(accessLevel)
        ),
      },
      [ACCESS_LEVELS.userAdministrator]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.userAdministrator),
        hasParentAccessLevel: [ACCESS_LEVELS.administrator].some(
          (accessLevel) => accessLevels.includes(accessLevel)
        ),
      },
      [ACCESS_LEVELS.createGroups]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.createGroups),
        hasParentAccessLevel: [
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.groupAdministrator,
        ].some((accessLevel) => accessLevels.includes(accessLevel)),
      },
      [ACCESS_LEVELS.createTrainingSessions]: {
        hasAccessLevel: accessLevels.includes(
          ACCESS_LEVELS.createTrainingSessions
        ),
        hasParentAccessLevel: [
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.trainingSessionAdministrator,
        ].some((accessLevel) => accessLevels.includes(accessLevel)),
      },
      [ACCESS_LEVELS.createRoles]: {
        hasAccessLevel:
          this.getPersonCreateRolePermissionInfo(personId).canCreate,
        hasParentAccessLevel: [
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.contentAdministrator,
        ].some((accessLevel) => accessLevels.includes(accessLevel)),
      },
      [ACCESS_LEVELS.createModules]: {
        hasAccessLevel:
          this.getPersonCreateAreaPermissionInfo(personId).canCreate,
        hasParentAccessLevel: [
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.contentAdministrator,
        ].some((accessLevel) => accessLevels.includes(accessLevel)),
      },
      [ACCESS_LEVELS.createPersons]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.createPersons),
        hasParentAccessLevel: [
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.userAdministrator,
          ACCESS_LEVELS.personAdministrator,
        ].some((accessLevel) => accessLevels.includes(accessLevel)),
      },
      [ACCESS_LEVELS.deletePersons]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.deletePersons),
        hasParentAccessLevel: [ACCESS_LEVELS.administrator].some(
          (accessLevel) => accessLevels.includes(accessLevel)
        ),
      },
      [ACCESS_LEVELS.assignAccessLevels]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.assignAccessLevels),
        hasParentAccessLevel: [ACCESS_LEVELS.administrator].some(
          (accessLevel) => accessLevels.includes(accessLevel)
        ),
      },
      [ACCESS_LEVELS.systemAdministrator]: {
        hasAccessLevel: accessLevels.includes(
          ACCESS_LEVELS.systemAdministrator
        ),
        hasParentAccessLevel: false,
      },
    };

    return Object.keys(permissionObject).reduce((acc, permission) => {
      const personPermission = permissionObject[permission];
      if (
        (personPermission.hasAccessLevel ||
          personPermission.hasParentAccessLevel) &&
        !acc.includes(permission)
      ) {
        return [...acc, permission];
      }
      return acc;
    }, []);
  };

  getRowHeight = ({ index }) => {
    return index === 0 ? 140 : 40;
  };

  getScrollbarSize = () => 12;

  getSubMenuItems = () => {
    const { activeFilter, filters, languages } = this.state;
    const { persons, organisationUnits } = this.props;
    if (!activeFilter) return [];
    if (activeFilter === 'administrators') {
      return sortBy(
        Object.values(persons)
          .filter((person) => VALID_PERSON_STATES.includes(person.state))
          .map((person) => {
            return {
              selected: !filters.administrators.deselected.includes(person.id),
              name: person.name,
              tooltip: `${person.initials}${
                person.employeeNumber ? ` · ${person.employeeNumber}` : ''
              }`,
              searchString:
                `${person.name}${person.initials}${person.email}${person.employeeNumber}`.toLowerCase(),
              subtitle:
                person.state === PERSON_STATES.ACTIVE
                  ? ''
                  : localeLookup('translations.Archived'),
              id: person.id,
              onToggle: () =>
                this.onToggleFilter({
                  filterId: 'administrators',
                  id: person.id,
                }),
            };
          }),
        [(a, b) => compareLocal(a.name, b.name)]
      );
    }
    if (activeFilter === 'persons') {
      return sortBy(
        Object.values(persons).reduce((acc, person) => {
          if (VALID_PERSON_STATES.includes(person.state)) {
            return [
              ...acc,
              {
                selected: !filters.persons.deselected.includes(person.id),
                name: person.name,
                tooltip: `${person.initials}${
                  person.employeeNumber ? ` · ${person.employeeNumber}` : ''
                }`,
                searchString:
                  `${person.name}${person.initials}${person.email}${person.employeeNumber}`.toLowerCase(),
                subtitle:
                  person.state === PERSON_STATES.ACTIVE
                    ? ''
                    : localeLookup('translations.Archived'),
                id: person.id,
                onToggle: () =>
                  this.onToggleFilter({ filterId: 'persons', id: person.id }),
              },
            ];
          }
          return acc;
        }, []),
        [(a, b) => compareLocal(a.name, b.name)]
      );
    }
    if (activeFilter === 'organisationUnits') {
      return Object.values(organisationUnits).reduce(
        (acc, item) => {
          const isVisibilityLimited =
            item.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
            item.state === ORGANISATION_UNIT_STATES.PASSIVE;
          return [
            ...acc,
            {
              selected: !filters.organisationUnits.deselected.includes(item.id),
              name: item.name,
              id: item.id,
              subtitle: isVisibilityLimited
                ? localeLookup('translations.Limited visibility')
                : null,
              onToggle: () =>
                this.onToggleFilter({
                  filterId: 'organisationUnits',
                  id: item.id,
                }),
            },
          ];
        },
        [
          {
            selected: !filters.organisationUnits.deselected.includes('none'),
            name: localeLookup('translations.No organisation unit'),
            id: 'none',
            onToggle: () =>
              this.onToggleFilter({
                filterId: 'organisationUnits',
                id: 'none',
              }),
          },
        ]
      );
    }
    if (activeFilter === 'languages') {
      return Object.keys(languages).reduce(
        (acc, localeCode) => {
          return [
            ...acc,
            {
              selected: !filters.languages.deselected.includes(localeCode),
              name: languages[localeCode],
              id: localeCode,
              onToggle: () =>
                this.onToggleFilter({ filterId: 'languages', id: localeCode }),
            },
          ];
        },
        [
          {
            selected: !filters.languages.deselected.includes(''),
            name: localeLookup('translations.Organisation language'),
            id: '',
            onToggle: () =>
              this.onToggleFilter({ filterId: 'languages', id: '' }),
          },
        ]
      );
    }
  };

  getSortedRows = ({ rows }) => {
    const { sorting } = this.state;
    const column = this.COLUMNS.find((x) => x.id === sorting.columnId);
    if (column.id === 'groupadministrator') {
      return sortBy(
        rows,
        [
          (a, b) => {
            const personAPermissionInfo = this.getPersonGroupAdministratorInfo(
              a.id
            );
            const personBPermissionInfo = this.getPersonGroupAdministratorInfo(
              b.id
            );
            return (
              personBPermissionInfo.groupsWherePersonIsContentAdministrator
                .length -
              personAPermissionInfo.groupsWherePersonIsContentAdministrator
                .length
            );
          },
          (a, b) => {
            const personAPermissionInfo = this.getPersonGroupAdministratorInfo(
              a.id
            );
            const personBPermissionInfo = this.getPersonGroupAdministratorInfo(
              b.id
            );
            return compareFalsy(
              personAPermissionInfo.isGroupAdministrator,
              personBPermissionInfo.isGroupAdministrator
            );
          },
        ],
        [sorting.direction, sorting.direction]
      );
    }
    if (column.id === 'contentadministrator') {
      return sortBy(
        rows,
        [
          (a, b) => {
            const personAPermissionInfo =
              this.getPersonContentAdministratorInfo(a.id);
            const personBPermissionInfo =
              this.getPersonContentAdministratorInfo(b.id);
            return (
              personBPermissionInfo.spacesWherePersonIsContentAdministrator
                .length -
              personAPermissionInfo.spacesWherePersonIsContentAdministrator
                .length
            );
          },
          (a, b) => {
            const personAPermissionInfo =
              this.getPersonContentAdministratorInfo(a.id);
            const personBPermissionInfo =
              this.getPersonContentAdministratorInfo(b.id);
            return compareFalsy(
              personAPermissionInfo.isContentAdministrator,
              personBPermissionInfo.isContentAdministrator
            );
          },
        ],
        [sorting.direction, sorting.direction]
      );
    }
    if (column.id === 'createmodules') {
      return sortBy(
        rows,
        [
          (a, b) => {
            const personAPermissionInfo =
              this.getPersonCreateAreaPermissionInfo(a.id);
            const personBPermissionInfo =
              this.getPersonCreateAreaPermissionInfo(b.id);
            return (
              personBPermissionInfo.spacesWherePersonCanCreateAreas.length -
              personAPermissionInfo.spacesWherePersonCanCreateAreas.length
            );
          },
          (a, b) => {
            const personAPermissionInfo =
              this.getPersonCreateAreaPermissionInfo(a.id);
            const personBPermissionInfo =
              this.getPersonCreateAreaPermissionInfo(b.id);
            return compareFalsy(
              personAPermissionInfo.canCreate,
              personBPermissionInfo.canCreate
            );
          },
        ],
        [sorting.direction, sorting.direction]
      );
    }
    if (column.id === 'createroles') {
      return sortBy(
        rows,
        [
          (a, b) => {
            const personAPermissionInfo =
              this.getPersonCreateRolePermissionInfo(a.id);
            const personBPermissionInfo =
              this.getPersonCreateRolePermissionInfo(b.id);
            return (
              personBPermissionInfo.spacesWherePersonCanCreateRoles.length -
              personAPermissionInfo.spacesWherePersonCanCreateRoles.length
            );
          },
          (a, b) => {
            const personAPermissionInfo =
              this.getPersonCreateRolePermissionInfo(a.id);
            const personBPermissionInfo =
              this.getPersonCreateRolePermissionInfo(b.id);
            return compareFalsy(
              personAPermissionInfo.canCreate,
              personBPermissionInfo.canCreate
            );
          },
        ],
        [sorting.direction, sorting.direction]
      );
    }
    if (column.id === 'teamadministrator') {
      return sortBy(
        rows,
        [
          (a, b) => {
            const personAPermissionInfo =
              this.getPersonOrganisationAdministratorInfo(a.id);
            const personBPermissionInfo =
              this.getPersonOrganisationAdministratorInfo(b.id);
            return (
              personBPermissionInfo.unitsWherePersonIsAdministrator.length -
              personAPermissionInfo.unitsWherePersonIsAdministrator.length
            );
          },
          (a, b) => {
            const personAPermissionInfo =
              this.getPersonOrganisationAdministratorInfo(a.id);
            const personBPermissionInfo =
              this.getPersonOrganisationAdministratorInfo(b.id);
            return compareFalsy(
              personAPermissionInfo.isOrganisationAdmin,
              personBPermissionInfo.isOrganisationAdmin
            );
          },
        ],
        [sorting.direction, sorting.direction]
      );
    }
    if (column.id === 'status') {
      return sortBy(
        rows,
        [
          (a, b) =>
            compareLocal(a[sorting.columnId].title, b[sorting.columnId].title),
          (a, b) =>
            compareDates(a[sorting.columnId].date, b[sorting.columnId].date),
        ],
        [sorting.direction, sorting.direction]
      );
    }
    if (column.id === 'employeeNumber') {
      return sortBy(
        rows,
        [
          (a, b) =>
            compareLocal(a.employeeNumber.title, b.employeeNumber.title),
          (a, b) =>
            compareEmpty(a.employeeNumber.title, b.employeeNumber.title),
        ],
        [sorting.direction, 'asc']
      );
    }
    if (column.type === 'text') {
      return sortBy(
        rows,
        [
          (a, b) =>
            compareLocal(a[sorting.columnId].title, b[sorting.columnId].title),
          (a, b) =>
            compareEmpty(a[sorting.columnId].title, b[sorting.columnId].title),
        ],
        [sorting.direction, 'asc']
      );
    } else {
      return sortBy(
        rows,
        [
          (a, b) =>
            compareFalsy(
              a[sorting.columnId].hasAccessLevel ||
                a[sorting.columnId].hasParentAccessLevel,
              b[sorting.columnId].hasAccessLevel ||
                b[sorting.columnId].hasParentAccessLevel
            ),
        ],
        [sorting.direction]
      );
    }
  };

  getStatusContextMenuItems = ({ personId, personState }) => {
    const { loggedInUserId, personActions, hasAccess } = this.props;
    if (personId === loggedInUserId) return null;
    if (personState === PERSON_STATES.ACTIVE) {
      return [
        {
          title: localeLookup('translations.Archive'),
          leftIconKind: 'user-minus',
          onClick: () =>
            personActions.showArchivePersonModal({
              id: personId,
              onArchived: () => this.updatePersonRow(personId),
            }),
        },
      ];
    } else {
      const hasAccessToDelete = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.deletePersons,
      ]);
      if (hasAccessToDelete) {
        return [
          {
            title: localeLookup('translations.Restore'),
            leftIconKind: 'user-plus',
            leftIconColor: 'green',
            onClick: () =>
              personActions
                .activatePerson({ id: personId })
                .then(() => this.updatePersonRow(personId)),
          },
          {
            title: localeLookup('translations.Delete'),
            leftIconKind: 'trash2',
            leftIconColor: 'red',
            onClick: () =>
              personActions.showDeletePersonModal({
                id: personId,
                onDeleted: this.buildRows,
              }),
          },
        ];
      } else {
        return [
          {
            title: localeLookup('translations.Restore'),
            leftIconKind: 'user-plus',
            leftIconColor: 'green',
            onClick: () =>
              personActions
                .activatePerson({ id: personId })
                .then(() => this.updatePersonRow(personId)),
          },
        ];
      }
    }
  };

  getTabs = () => {
    const { hasAccess } = this.props;
    let tabs = [];
    if (
      hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.userAdministrator,
      ])
    ) {
      tabs = [
        ...tabs,
        {
          name: localeLookup('translations.Persons'),
          id: 'persons',
        },
      ];
    }
    if (
      hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.groupAdministrator,
        ACCESS_LEVELS.groupManager,
        ACCESS_LEVELS.createGroups,
      ])
    ) {
      tabs = [
        ...tabs,
        {
          name: `${localeLookup('translations.Groups')} (${localeLookup(
            'translations.Beta'
          )})`,
          id: 'groups',
        },
      ];
    }
    return tabs;
  };

  getQueryParams = () => {
    const { location } = this.props;
    const queryParams = getQueryStringParams(location.search);
    if (queryParams.view) {
      this.setState({
        activeView: queryParams.view || 'persons',
      });
    }
  };

  setColumns = () => {
    const { languages, activeView } = this.state;
    const { personActions, groupActions } = this.props;
    if (activeView === 'persons') {
      this.COLUMNS = [
        {
          label: localeLookup('translations.Name'),
          id: 'name',
          type: 'text',
          width: 250,
          editable: true,
          controlType: 'input',
          controlDisabled: (person) =>
            person.userType === USER_TYPES.userWithExternalLogin,
          onSubmit: ({ personId, value }) =>
            this.onSubmitChangePersonName({ personId, value }),
        },
        {
          label: localeLookup('translations.Initials'),
          id: 'initials',
          type: 'text',
          width: 100,
          editable: true,
          controlType: 'input',
          controlProps: {
            maxLength: 5,
          },
          onSubmit: ({ personId, value }) =>
            this.onSubmitChangePersonInitials({ personId, value }),
        },
        {
          label: localeLookup('translations.Employee number'),
          id: 'employeeNumber',
          type: 'text',
          width: 170,
          icon: 'pencil',
          onIconClick: ({ personId }) =>
            personActions.showChangeEmployeeNumberModal({
              id: personId,
              onChanged: () => this.updatePersonRow(personId),
            }),
        },
        {
          label: localeLookup('translations.Language'),
          id: 'language',
          type: 'text',
          width: 150,
          editable: true,
          controlType: 'select',
          controlValueProp: 'locale',
          //controlDisabled: (person) => person.userType === USER_TYPES.userWithoutLogin,
          selectOptions: [
            {
              value: '',
              label: localeLookup('translations.Organisation language'),
            },
            ...Object.keys(languages).map((localeCode) => ({
              value: localeCode,
              label: languages[localeCode],
            })),
          ],
          onSubmit: ({ personId, value }) =>
            this.onSubmitChangePersonLanguage({ personId, value }),
        },
        {
          label: localeLookup('translations.Login'),
          id: 'login',
          type: 'text',
          width: 250,
        },
        {
          label: localeLookup('translations.Status'),
          id: 'status',
          type: 'text',
          width: 150,
        },
        {
          label: localeLookup('translations.Administrator'),
          id: 'administrator',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.AdministratorTooltip'),
        },
        {
          label: localeLookup('translations.Content administrator'),
          id: 'contentadministrator',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.ContentAdministratorTooltip'),
        },
        {
          label: localeLookup('translations.Group administrator'),
          id: 'groupadministrator',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.GroupAdministratorTooltip'),
        },
        {
          label: localeLookup('translations.Person administrator'),
          id: 'personadministrator',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.PersonAdministratorTooltip'),
        },
        {
          label: localeLookup('translations.User administrator'),
          id: 'useradministrator',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.UserAdministratorTooltip'),
        },
        {
          label: localeLookup('translations.Organisation administrator'),
          id: 'teamadministrator',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup(
            'translations.OrganisationAdministratorTooltip'
          ),
        },
        {
          label: localeLookup('translations.Session administrator'),
          id: 'trainingsessionadministrator',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.SessionAdministratorTooltip'),
        },
        {
          label: localeLookup('translations.Create groups'),
          id: 'creategroups',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.CreateGroupsPermissionTooltip'),
        },
        {
          label: localeLookup('translations.Create sessions'),
          id: 'createtrainingsessions',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.CreateSessionsPermissionTooltip'),
        },
        {
          label: localeLookup('translations.Create roles'),
          id: 'createroles',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.CreateRolesPermissionTooltip'),
        },
        {
          label: localeLookup('translations.Create modules'),
          id: 'createmodules',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.CreateModulesPermissionTooltip'),
        },
        {
          label: localeLookup('translations.Create persons'),
          id: 'createpersons',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.CreatePersonsPermissionTooltip'),
        },
        {
          label: localeLookup('translations.Delete persons'),
          id: 'deletepersons',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.DeletePersonsPermissionTooltip'),
        },
        {
          label: localeLookup('translations.Change permissions'),
          id: 'assignaccesslevels',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup(
            'translations.AssignAccessLevelsPermissionTooltip'
          ),
        },
        {
          label: localeLookup('translations.System administrator'),
          id: 'systemadministrator',
          type: 'checkbox',
          width: 40,
          rotated: true,
          tooltip: localeLookup('translations.SystemAdministratorTooltip'),
        },
        {
          // Hack for spacing at end
          label: '',
          id: 'hidden',
          type: 'hidden',
          width: 200,
          rotated: true,
        },
      ];
    } else if (activeView === 'groups') {
      this.COLUMNS = [
        {
          label: localeLookup('translations.Name'),
          id: 'name',
          type: 'text',
          width: 250,
          editable: true,
          controlType: 'input',
          onSubmit: ({ id, value }) =>
            this.onSubmitChangeGroupName({ id, value }),
        },
        {
          label: localeLookup('translations.Short name'),
          id: 'shortName',
          type: 'text',
          width: 250,
          editable: true,
          controlType: 'input',
          controlProps: {
            maxLength: 5,
          },
          onSubmit: ({ id, value }) =>
            this.onSubmitChangeGroupShortName({ id, value }),
        },
        {
          label: localeLookup('translations.Persons'),
          id: 'members',
          subtitle: '',
          type: 'text',
          width: 250,
          icon: 'pencil',
          onIconClick: ({ groupId }) =>
            groupActions.showChangeGroupMembersModal({
              groupId,
              onChanged: () => this.updateGroupRow(groupId),
            }),
        },
        {
          label: localeLookup('translations.Group administrators'),
          id: 'administrators',
          subtitle: '',
          type: 'text',
          width: 250,
          icon: 'pencil',
          onIconClick: ({ groupId }) =>
            groupActions.showChangeGroupAdministratorsModal({
              groupId,
              onChanged: () => this.updateGroupRow(groupId),
            }),
        },
        {
          label: `${localeLookup(
            'translations.Assignment options'
          )} (${localeLookup('translations.Beta')})`,
          id: 'assignableAs',
          subtitle: '',
          tooltip: localeLookup(
            'translations.Contexts in which this group can be assigned. Currently only mentor assignment is supported'
          ),
          type: 'text',
          width: 250,
          disabled: true,
          controlDisabled: true,
        },
        {
          // Hack for spacing at end
          label: '',
          id: 'hidden',
          type: 'hidden',
          width: 200,
          rotated: true,
        },
      ];
    }
  };

  setQueryParams = () => {
    const { activeView } = this.state;
    const { history } = this.props;
    const serialize = (obj) =>
      Object.keys(obj)
        .filter((key) => obj[key])
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');
    history.push({
      search: serialize({ view: activeView }),
    });
  };

  buildRowForGroup = ({ group }) => {
    const { persons, groupActions } = this.props;
    const { id, name, shortName, assignableAs, members, administrators } =
      group;
    const administratorIds =
      this.getPersonsIdsThatHasAdministatorPermissionInGroup({ groupId: id });
    return {
      id,
      name: {
        title: name,
        contextMenuItems: [
          {
            title: localeLookup('translations.Delete'),
            leftIconKind: 'trash2',
            leftIconColor: 'red',
            onClick: () => {
              groupActions.showConfirmGroupDeleteModal({
                id,
                onDeleted: this.buildRows,
              });
            },
          },
        ],
      },
      shortName: { title: shortName },
      members: {
        title: `${members.length} ${
          members.length === 1
            ? localeLookup('translations.person')
            : localeLookup('translations.persons')
        }`,
        tooltip: (() => {
          const personNames = members.map((id) => {
            const person = persons[id];
            if (!person) return null;
            if (person.state === PERSON_STATES.ARCHIVED) {
              return `${person.name} (${localeLookup(
                'translations.Archived'
              )}) (${person.initials}${
                person.employeeNumber ? ` · ${person.employeeNumber}` : ''
              })`;
            }
            return `${person.name} (${person.initials}${
              person.employeeNumber ? ` · ${person.employeeNumber}` : ''
            })`;
          });
          return personNames.join('\n');
        })(),
      },
      administrators: {
        title: `${administratorIds?.length} ${
          administratorIds?.length === 1
            ? localeLookup('translations.person')
            : localeLookup('translations.persons')
        }`,
        tooltip: (() => {
          const personNames = administratorIds?.map((id) => {
            const person = persons[id];
            if (!person) return null;
            return `${person.name} (${person.initials}${
              person.employeeNumber ? ` · ${person.employeeNumber}` : ''
            })`;
          });
          return personNames?.join('\n');
        })(),
      },
      assignableAs: {
        title: localeLookup('translations.Mentor'),
      },
    };
  };

  buildRowForPerson = ({ person, personWithAccessLevels }) => {
    const { personActions, loggedInUserId } = this.props;
    const { languages } = this.state;
    const { name, initials, email, state, archivedDate, employeeNumber, type } =
      person;
    const { locale, accessLevels, id, userType } = personWithAccessLevels;
    const isChampEmployee = type === 'ChampEmployee';
    return {
      id,
      state,
      locale,
      userType,
      isChampEmployee,
      name: { title: name },
      initials: { title: initials },
      employeeNumber: { title: employeeNumber },
      language: {
        title:
          locale === ''
            ? localeLookup('translations.Organisation language')
            : languages[locale],
        controlValueProp: locale,
      },
      login: {
        title: email,
        subtitle:
          userType === USER_TYPES.userWithExternalLogin
            ? 'Active Directory'
            : null,
        contextMenuItems:
          userType === USER_TYPES.userWithLogin
            ? [
                {
                  title: localeLookup('translations.Edit login'),
                  leftIconKind: 'enter-right',
                  onClick: () =>
                    personActions.showEditPersonLoginModal({
                      userType,
                      userId: id,
                      email,
                      onChanged: () => this.updatePersonRow(id),
                    }),
                },
                {
                  title: localeLookup('translations.Change password'),
                  leftIconKind: 'lock',
                  onClick: () =>
                    personActions.showChangePersonPasswordModal({ id }),
                },
              ]
            : [
                {
                  title: localeLookup('translations.Edit login'),
                  leftIconKind: 'enter-right',
                  onClick: () =>
                    personActions.showEditPersonLoginModal({
                      userType,
                      userId: id,
                      email,
                      onChanged: () => this.updatePersonRow(id),
                    }),
                },
              ],
      },
      status: {
        title:
          state === PERSON_STATES.ACTIVE
            ? localeLookup('translations.Active')
            : localeLookup('translations.Archived'),
        subtitle:
          state === PERSON_STATES.ARCHIVED ? (
            <DateTime date={archivedDate}></DateTime>
          ) : null,
        date: archivedDate,
        contextMenuItems: this.getStatusContextMenuItems({
          personId: id,
          personState: state,
        }),
      },
      statusSubtitle:
        state === PERSON_STATES.ARCHIVED ? (
          <DateTime date={archivedDate}></DateTime>
        ) : null,
      [ACCESS_LEVELS.administrator]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.administrator),
        hasParentAccessLevel: isChampEmployee,
      },
      [ACCESS_LEVELS.contentAdministrator]: {
        hasAccessLevel: accessLevels.includes(
          ACCESS_LEVELS.contentAdministrator
        ),
        hasParentAccessLevel:
          [ACCESS_LEVELS.administrator].some((accessLevel) =>
            accessLevels.includes(accessLevel)
          ) || isChampEmployee,
      },
      [ACCESS_LEVELS.groupAdministrator]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.groupAdministrator),
        hasParentAccessLevel: [ACCESS_LEVELS.administrator].some(
          (accessLevel) => accessLevels.includes(accessLevel) || isChampEmployee
        ),
      },
      [ACCESS_LEVELS.personAdministrator]: {
        hasAccessLevel: accessLevels.includes(
          ACCESS_LEVELS.personAdministrator
        ),
        hasParentAccessLevel: [ACCESS_LEVELS.administrator].some(
          (accessLevel) => accessLevels.includes(accessLevel) || isChampEmployee
        ),
      },
      [ACCESS_LEVELS.userAdministrator]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.userAdministrator),
        hasParentAccessLevel: [ACCESS_LEVELS.administrator].some(
          (accessLevel) => accessLevels.includes(accessLevel) || isChampEmployee
        ),
      },
      [ACCESS_LEVELS.teamadministrator]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.teamadministrator),
        hasParentAccessLevel: [ACCESS_LEVELS.administrator].some(
          (accessLevel) => accessLevels.includes(accessLevel) || isChampEmployee
        ),
      },
      [ACCESS_LEVELS.trainingSessionAdministrator]: {
        hasAccessLevel: accessLevels.includes(
          ACCESS_LEVELS.trainingSessionAdministrator
        ),
        hasParentAccessLevel: [ACCESS_LEVELS.administrator].some(
          (accessLevel) => accessLevels.includes(accessLevel) || isChampEmployee
        ),
      },
      [ACCESS_LEVELS.createGroups]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.createGroups),
        hasParentAccessLevel:
          [ACCESS_LEVELS.administrator, ACCESS_LEVELS.groupAdministrator].some(
            (accessLevel) => accessLevels.includes(accessLevel)
          ) || isChampEmployee,
      },
      [ACCESS_LEVELS.createTrainingSessions]: {
        hasAccessLevel: accessLevels.includes(
          ACCESS_LEVELS.createTrainingSessions
        ),
        hasParentAccessLevel: [
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.trainingSessionAdministrator,
        ].some(
          (accessLevel) => accessLevels.includes(accessLevel) || isChampEmployee
        ),
      },
      [ACCESS_LEVELS.createGroups]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.createGroups),
        hasParentAccessLevel:
          [ACCESS_LEVELS.administrator, ACCESS_LEVELS.groupAdministrator].some(
            (accessLevel) => accessLevels.includes(accessLevel)
          ) || isChampEmployee,
      },
      [ACCESS_LEVELS.createRoles]: {
        hasAccessLevel: this.getPersonCreateRolePermissionInfo(person.id)
          .canCreate,
        hasParentAccessLevel:
          [
            ACCESS_LEVELS.administrator,
            ACCESS_LEVELS.contentAdministrator,
          ].some((accessLevel) => accessLevels.includes(accessLevel)) ||
          isChampEmployee,
      },
      [ACCESS_LEVELS.createModules]: {
        hasAccessLevel: this.getPersonCreateAreaPermissionInfo(person.id)
          .canCreate,
        hasParentAccessLevel:
          [
            ACCESS_LEVELS.administrator,
            ACCESS_LEVELS.contentAdministrator,
          ].some((accessLevel) => accessLevels.includes(accessLevel)) ||
          isChampEmployee,
      },
      [ACCESS_LEVELS.createPersons]: {
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.createPersons),
        hasParentAccessLevel:
          [
            ACCESS_LEVELS.administrator,
            ACCESS_LEVELS.userAdministrator,
            ACCESS_LEVELS.personAdministrator,
          ].some((accessLevel) => accessLevels.includes(accessLevel)) ||
          isChampEmployee,
      },
      [ACCESS_LEVELS.deletePersons]: {
        disabled: !accessLevels.includes(ACCESS_LEVELS.userAdministrator),
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.deletePersons),
        hasParentAccessLevel:
          [ACCESS_LEVELS.administrator].some((accessLevel) =>
            accessLevels.includes(accessLevel)
          ) || isChampEmployee,
      },
      [ACCESS_LEVELS.assignAccessLevels]: {
        disabled: !accessLevels.includes(ACCESS_LEVELS.userAdministrator),
        hasAccessLevel: accessLevels.includes(ACCESS_LEVELS.assignAccessLevels),
        hasParentAccessLevel: [ACCESS_LEVELS.administrator].some(
          (accessLevel) => accessLevels.includes(accessLevel) || isChampEmployee
        ),
      },
      [ACCESS_LEVELS.systemAdministrator]: {
        hasAccessLevel: accessLevels.includes(
          ACCESS_LEVELS.systemAdministrator
        ),
        hasParentAccessLevel: isChampEmployee,
      },
    };
  };

  buildRows = () => {
    const { persons, groups } = this.props;
    const { filters, personsWithAccessLevels, activeView } = this.state;
    if (activeView === 'persons') {
      this.setState(
        {
          rows: this.getSortedRows({
            rows: Object.keys(persons).reduce((acc, id) => {
              if (
                filters.persons.deselected.includes(id) ||
                !this.doesPersonMatchFilters({
                  person: persons[id],
                  personsWithAccessLevels: personsWithAccessLevels[id],
                }) ||
                !personsWithAccessLevels[id]
              )
                return acc;

              if (VALID_PERSON_STATES.includes(persons[id].state) === false)
                return acc;

              return [
                ...acc,
                this.buildRowForPerson({
                  person: persons[id],
                  personWithAccessLevels: personsWithAccessLevels[id],
                }),
              ];
            }, []),
          }),
        },
        () => {
          this.gridRef?.recomputeGridSize();
          this.setState({ isLoading: false });
        }
      );
    } else if (activeView === 'groups') {
      this.setState(
        {
          rows: this.getSortedRows({
            rows: Object.keys(groups).reduce((acc, id) => {
              const group = groups[id];
              const groupAdministratorIds =
                this.getPersonsIdsThatHasAdministatorPermissionInGroup({
                  groupId: id,
                });
              if (
                !group.hasAccess ||
                (filters.persons.deselected.length > 0 &&
                  group.members.every((id) =>
                    filters.persons.deselected.includes(id)
                  )) ||
                (filters.administrators.deselected.length > 0 &&
                  groupAdministratorIds.every((id) =>
                    filters.administrators.deselected.includes(id)
                  ))
              ) {
                return acc;
              }

              return [
                ...acc,
                this.buildRowForGroup({
                  group,
                }),
              ];
            }, []),
          }),
        },
        () => {
          this.gridRef?.recomputeGridSize();
          this.setState({ isLoading: false });
        }
      );
    }
  };

  doesPersonMatchFilters = ({ person, personsWithAccessLevels }) => {
    const { filters } = this.state;
    const { otherFilters, permissions, languages, organisationUnits } = filters;
    if (!this.isAnyFiltersActive()) return true;
    const doesUserTypeMatchFilter = () => {
      if (this.isFilterDefault('otherFilters', otherFiltersDefaultState))
        return true;
      if (personsWithAccessLevels.userType === USER_TYPES.userWithoutLogin)
        return otherFilters.userTypeNoLogin;
      if (personsWithAccessLevels.userType === USER_TYPES.userWithLogin)
        return otherFilters.userTypeChamp;
      if (personsWithAccessLevels.userType === USER_TYPES.userWithExternalLogin)
        return otherFilters.userTypeExternal;
    };
    const doesUserStateMatchFilter = () => {
      if (this.isFilterDefault('otherFilters', otherFiltersDefaultState))
        return true;
      if (person.state === PERSON_STATES.ACTIVE)
        return otherFilters.activePersons;
      if (person.state === PERSON_STATES.ARCHIVED)
        return otherFilters.archivedPersons;
    };
    const doesUserPermissionsMatchFilter = () => {
      if (this.isFilterDefault('permissions', permissionFilterDefaultState))
        return true;
      const personVisibleAccessLevels = this.getPersonVisibleAccessLevels(
        personsWithAccessLevels.accessLevels,
        person.id
      );
      if (personVisibleAccessLevels.length === 0)
        return permissions.NoPermissions;
      return personVisibleAccessLevels.some((level) => {
        return permissions[level];
      });
    };
    const doesUserLanguageMatchFilter = () => {
      if (languages.deselected.length === 0) return true;
      return !languages.deselected.includes(personsWithAccessLevels.locale);
    };

    const doesUserOrganisationUnitsMatchFilter = () => {
      if (organisationUnits.deselected.length === 0) return true;
      if (
        !organisationUnits.deselected.includes('none') &&
        personsWithAccessLevels.organisationUnits.length === 0
      )
        return true;
      return !personsWithAccessLevels.organisationUnits.every((unitId) =>
        organisationUnits.deselected.includes(unitId)
      );
    };

    return (
      doesUserTypeMatchFilter() &&
      doesUserStateMatchFilter() &&
      doesUserPermissionsMatchFilter() &&
      doesUserLanguageMatchFilter() &&
      doesUserOrganisationUnitsMatchFilter()
    );
  };

  isAnyFiltersActive = () => {
    const { filters } = this.state;
    return Object.keys(filters).some((key) => {
      if (key === 'permissions') {
        return !this.isFilterDefault(key, permissionFilterDefaultState);
      }
      if (key === 'otherFilters') {
        return !this.isFilterDefault(key, otherFiltersDefaultState);
      }
      return filters[key].deselected.length !== 0;
    });
  };

  isFilterDefault = (stateKey, defaultState) => {
    const { filters } = this.state;
    return Object.keys(filters[stateKey]).every(
      (key) => filters[stateKey][key] === defaultState[key]
    );
  };

  onChangeFilterQuery = debounce((e) => {
    this.setState({
      filterSearchQuery: e.target.value.toLowerCase(),
    });
  }, 300);

  onChangePersonCreateAreaPermission = async ({
    personId,
    spaceId,
    canCreateModules,
  }) => {
    const { spaces, getAllSpaces } = this.props;
    const currentPersonIdsAllowedToCreateModules =
      spaces[spaceId].canCreateModules;
    if (!canCreateModules) {
      await updateSpaceCreateModulePermissionsService(
        [spaceId],
        [...currentPersonIdsAllowedToCreateModules, personId],
        'space'
      );
    } else {
      await updateSpaceCreateModulePermissionsService(
        [spaceId],
        currentPersonIdsAllowedToCreateModules.filter((id) => id !== personId),
        'space'
      );
    }
    await getAllSpaces();
    this.gridRef?.recomputeGridSize();
  };

  onChangePersonCreateRolePermission = async ({
    personId,
    spaceId,
    canCreateRoles,
  }) => {
    const { spaces, getAllSpaces } = this.props;
    const currentPersonIdsAllowedToCreateRoles = spaces[spaceId].canCreateRoles;
    if (!canCreateRoles) {
      await updateSpaceCreateRolePermissionsService(
        [spaceId],
        [...currentPersonIdsAllowedToCreateRoles, personId],
        'space'
      );
    } else {
      await updateSpaceCreateRolePermissionsService(
        [spaceId],
        currentPersonIdsAllowedToCreateRoles.filter((id) => id !== personId),
        'space'
      );
    }
    await getAllSpaces();
    this.gridRef?.recomputeGridSize();
  };

  onChangePersonPermission = (id, e) => {
    const { checked, name } = e.target;
    if (!name) return;
    if (checked) {
      addPersonPermissionsService(id, [name]).then(() =>
        this.updatePersonRow(id)
      );
    } else {
      if (name === 'useradministrator') {
        removePersonPermissionsService(id, [
          'useradministrator',
          'deletepersons',
          'assignaccesslevels',
        ]).then(() => this.updatePersonRow(id));
      } else {
        removePersonPermissionsService(id, [name]).then(() =>
          this.updatePersonRow(id)
        );
      }
    }
  };

  onChangeView = (view) => {
    const { history } = this.props;
    this.onClickHideFilter();
    this.setState(
      {
        activeView: view.id,
        sorting: { columnId: 'name', direction: 'asc' },
      },
      () => {
        this.setQueryParams();
        this.buildRows();
        this.setColumns();
      }
    );
  };

  onClickChangeSorting = (columnId) => {
    const { sorting } = this.state;
    const getSortDirection = () => {
      if (sorting.columnId !== columnId) return 'asc';
      if (sorting.direction === 'asc') return 'desc';
      return 'asc';
    };
    this.setState(
      {
        sorting: {
          columnId,
          direction: getSortDirection(),
        },
      },
      this.buildRows
    );
  };

  onClickCreateGroup = () => {
    const { groupActions } = this.props;
    groupActions.showCreateGroupModal({
      onCreated: this.buildRows,
    });
  };

  onClickCreatePerson = () => {
    const { personActions, showModal } = this.props;
    personActions.showCreatePersonModal({
      onCreated: () => {
        getPersonsWithAccessLevelsService().then((response) => {
          this.setState({ personsWithAccessLevels: response.data }, () => {
            if (this.isAnyFiltersActive()) {
              showModal('confirmation', {
                title: localeLookup('translations.Person created'),
                maxWidth: '500px',
                fullWidth: true,
                body: localeLookup(
                  'translations.You have just created a new person. Do you want to reset all filters?'
                ),
                confirmButtonText: localeLookup('translations.Reset filters'),
                btnRejectTitle: localeLookup('translations.Keep filters'),
                onConfirm: this.onClickResetFilter,
                confirmButtonType: 'alert',
              });
            } else {
              this.buildRows();
            }
          });
        });
      },
    });
  };

  onClickFilter = ({ id, name }) => {
    this.setState(
      {
        activeFilter: id,
        subMenuTitle: name,
        filterSearchQuery: '',
      },
      () => {
        this.scrollToSubmenuTop();
        if (this.filterInput) {
          this.filterInput.focus();
        }
      }
    );
  };

  onClickFilterDeselectAll = () => {
    const { filters, activeFilter } = this.state;
    this.setState(
      {
        filters: {
          ...filters,
          [activeFilter]: {
            ...filters[activeFilter],
            deselected: this.getSubMenuItems().map((item) => item.id),
          },
        },
      },
      () => {
        this.buildRows();
      }
    );
  };

  onClickFilterSelectAll = () => {
    const { filters, activeFilter } = this.state;
    this.setState(
      {
        filters: {
          ...filters,
          [activeFilter]: {
            ...filters[activeFilter],
            deselected: [],
          },
        },
      },
      () => {
        this.buildRows();
      }
    );
  };

  onClickHideFilter = () => {
    this.setState({
      activeFilter: null,
      subMenuTitle: '',
    });
  };

  onClickResetFilter = () => {
    const { filters } = this.state;
    this.setState(
      {
        filters: Object.keys(filters).reduce(
          (acc, key) => {
            if (acc[key]) {
              return acc;
            }
            return {
              ...acc,
              [key]: {
                ...filters[key],
                deselected: [],
              },
            };
          },
          {
            permissions: permissionFilterDefaultState,
            otherFilters: otherFiltersDefaultState,
          }
        ),
      },
      () => {
        this.buildRows();
      }
    );
  };

  onClickResetSingleFilter = (stateKey, defaultState) => {
    const { filters } = this.state;
    if (defaultState) {
      this.setState(
        {
          filters: {
            ...filters,
            [stateKey]: defaultState,
          },
        },
        () => {
          this.buildRows();
        }
      );
    } else {
      this.setState(
        {
          filters: {
            ...filters,
            [stateKey]: {
              ...filters[stateKey],
              deselected: [],
            },
          },
        },
        () => {
          this.buildRows();
        }
      );
    }
  };

  onSubmitChangeGroupName = ({ id, value }) => {
    this.setState({ isUpdating: true });
    return updateGroupNameService({ id, name: value }).then(() => {
      this.updateGroupRow(id);
    });
  };

  onSubmitChangeGroupShortName = ({ id, value }) => {
    this.setState({ isUpdating: true });
    return updateGroupShortNameService({ id, shortName: value }).then(() => {
      this.updateGroupRow(id);
    });
  };

  onSubmitChangePersonInitials = ({ personId, value }) => {
    this.setState({ isUpdating: true });
    return changeInitialsService(personId, { initials: value }).then(() => {
      this.updatePersonRow(personId);
    });
  };
  onSubmitChangePersonLanguage = ({ personId, value }) => {
    this.setState({ isUpdating: true });
    return changeLanguageService(personId, { locale: value }).then(() => {
      this.updatePersonRow(personId);
    });
  };
  onSubmitChangePersonName = ({ personId, value }) => {
    this.setState({ isUpdating: true });
    return changeNameService(personId, { name: value }).then(() => {
      this.updatePersonRow(personId);
    });
  };

  onToggleFilter = ({ filterId, id }) => {
    const { filters } = this.state;
    const filter = filters[filterId];
    if (filter.deselected.includes(id)) {
      this.setState(
        {
          filters: {
            ...filters,
            [filterId]: {
              ...filters[filterId],
              deselected: filter.deselected.filter(
                (deselectedId) => deselectedId !== id
              ),
            },
          },
        },
        () => {
          this.buildRows();
        }
      );
    } else {
      this.setState(
        {
          filters: {
            ...filters,
            [filterId]: {
              ...filters[filterId],
              deselected: [...filter.deselected, id],
            },
          },
        },
        () => {
          this.buildRows();
        }
      );
    }
  };

  onToggleStaticFilter = (filterStateKey, stateKey) => {
    const { filters } = this.state;
    this.setState(
      {
        filters: {
          ...filters,
          [filterStateKey]: {
            ...filters[filterStateKey],
            [stateKey]: !filters[filterStateKey][stateKey],
          },
        },
      },
      () => {
        this.buildRows();
      }
    );
  };

  updatePersonRow = (personId) => {
    const { getPersons } = this.props;
    const { rows, personsWithAccessLevels } = this.state;
    getPersons().then((personData) => {
      getPersonsWithAccessLevelsService([personId]).then(({ data }) => {
        const updatedPerson = data[personId];
        this.setState(
          {
            rows: rows.map((row) => {
              if (row.id === personId) {
                return this.buildRowForPerson({
                  personWithAccessLevels: updatedPerson,
                  person: personData[personId],
                });
              }
              return row;
            }),
            personsWithAccessLevels: {
              ...personsWithAccessLevels,
              [personId]: updatedPerson,
            },
            isUpdating: false,
          },
          () => {
            this.gridRef?.recomputeGridSize();
          }
        );
      });
    });
  };

  updateGroupRow = (id) => {
    const { getGroup } = this.props;
    const { rows } = this.state;
    getGroup([id]).then((data) => {
      const updatedGroup = data[id];
      this.setState(
        {
          rows: rows.map((row) => {
            if (row.id === id) {
              return this.buildRowForGroup({ group: updatedGroup });
            }
            return row;
          }),
          isUpdating: false,
        },
        () => {
          this.gridRef?.recomputeGridSize();
        }
      );
    });
  };

  scrollToSubmenuTop = () => {
    this.submenuList && this.submenuList.scrollToPosition(0);
  };

  renderNotificationCounter = (filterId) => {
    const { filters } = this.state;
    const onClickReset = () => this.onClickResetSingleFilter(filterId);
    const count = this.getFilterSelectionCount(filterId);
    return filters[filterId].deselected.length === 0 ? null : (
      <NotificationCounter
        onClick={onClickReset}
        color="grey"
        icon="cross2"
        size="small"
        count={count}
      />
    );
  };

  renderSidebar = () => {
    const { hasAccess } = this.props;
    const { filters, activeView } = this.state;
    const showCreateButton =
      activeView === 'persons'
        ? true
        : hasAccess([
            ACCESS_LEVELS.champadministrator,
            ACCESS_LEVELS.administrator,
            ACCESS_LEVELS.groupAdministrator,
            ACCESS_LEVELS.createGroups,
          ]);
    return (
      <Aside
        className="permission-matrix__sidebar"
        footerComponent={
          <Button
            disabled={!this.isAnyFiltersActive()}
            fullWidth
            kind="alert"
            onClick={this.onClickResetFilter}
          >
            {localeLookup('translations.Reset filters')}
          </Button>
        }
      >
        <SideNavList>
          {showCreateButton && (
            <SideNavItem
              title={
                activeView === 'persons'
                  ? localeLookup('translations.Create person')
                  : localeLookup('translations.Create group')
              }
              onClick={
                activeView === 'persons'
                  ? this.onClickCreatePerson
                  : this.onClickCreateGroup
              }
              leftComponent={
                <Icon
                  color="green"
                  kind={activeView === 'persons' ? 'user-plus' : 'plus-circle'}
                />
              }
            />
          )}
        </SideNavList>
        <SideNavListSection title={localeLookup('translations.Filter')}>
          {activeView === 'groups' && (
            <SideNavItem
              title={localeLookup('translations.Administrators')}
              onClick={() => {
                this.onClickFilter({
                  id: 'administrators',
                  name: localeLookup('translations.Administrators'),
                });
              }}
              stopRightComponentClickPropagation
              rightComponent={this.renderNotificationCounter('administrators')}
            />
          )}
          <SideNavItem
            title={localeLookup('translations.Persons')}
            onClick={() => {
              this.onClickFilter({
                id: 'persons',
                name: localeLookup('translations.Persons'),
              });
            }}
            stopRightComponentClickPropagation
            rightComponent={this.renderNotificationCounter('persons')}
          />
          {activeView === 'persons' && (
            <>
              <SideNavItem
                title={localeLookup('translations.Organisation units')}
                onClick={() => {
                  this.onClickFilter({
                    id: 'organisationUnits',
                    name: localeLookup('translations.Organisation units'),
                  });
                }}
                stopRightComponentClickPropagation
                rightComponent={this.renderNotificationCounter(
                  'organisationUnits'
                )}
              />
              <SideNavItem
                title={localeLookup('translations.Languages')}
                onClick={() => {
                  this.onClickFilter({
                    id: 'languages',
                    name: localeLookup('translations.Languages'),
                  });
                }}
                stopRightComponentClickPropagation
                rightComponent={this.renderNotificationCounter('languages')}
              />
              <SideNavItem
                title={localeLookup('translations.Permissions')}
                onClick={() => {
                  this.onClickFilter({
                    id: 'permissions',
                    name: localeLookup('translations.Permissions'),
                  });
                }}
                rightComponent={
                  this.isFilterDefault(
                    'permissions',
                    permissionFilterDefaultState
                  ) ? null : (
                    <Icon color="blue" kind="settings" />
                  )
                }
              />
              <SideNavItem
                title={localeLookup('translations.Other filters')}
                onClick={() => {
                  this.onClickFilter({
                    id: 'otherFilters',
                    name: localeLookup('translations.Other filters'),
                  });
                }}
                rightComponent={
                  this.isFilterDefault(
                    'otherFilters',
                    otherFiltersDefaultState
                  ) ? null : (
                    <Icon color="blue" kind="settings" />
                  )
                }
              />
            </>
          )}
        </SideNavListSection>
        {this.renderSubmenu()}
      </Aside>
    );
  };

  renderSubmenu = () => {
    const { activeFilter, subMenuTitle, filterSearchQuery, filters } =
      this.state;
    if (activeFilter === 'permissions') {
      return (
        <AnimatedAside
          variants={submenuVariants}
          initial="hidden"
          animate={activeFilter !== null ? 'visible' : 'hidden'}
          isSublevel
          footerComponent={
            <Button fullWidth kind="shy" onClick={this.onClickHideFilter}>
              {localeLookup('translations.Back')}
            </Button>
          }
        >
          <UserAdministrationPermissionFilter
            isDefault={this.isFilterDefault(
              'permissions',
              permissionFilterDefaultState
            )}
            filterState={filters.permissions}
            onClickReset={() =>
              this.onClickResetSingleFilter(
                'permissions',
                permissionFilterDefaultState
              )
            }
            onToggleFilter={(stateKey) =>
              this.onToggleStaticFilter('permissions', stateKey)
            }
          />
        </AnimatedAside>
      );
    }
    if (activeFilter === 'otherFilters') {
      return (
        <AnimatedAside
          variants={submenuVariants}
          initial="hidden"
          animate={activeFilter !== null ? 'visible' : 'hidden'}
          isSublevel
          footerComponent={
            <Button fullWidth kind="shy" onClick={this.onClickHideFilter}>
              {localeLookup('translations.Back')}
            </Button>
          }
        >
          <UserAdministrationOtherFiltersFilter
            isDefault={this.isFilterDefault(
              'otherFilters',
              otherFiltersDefaultState
            )}
            filterState={filters.otherFilters}
            onClickReset={() =>
              this.onClickResetSingleFilter(
                'otherFilters',
                otherFiltersDefaultState
              )
            }
            onToggleFilter={(stateKey) =>
              this.onToggleStaticFilter('otherFilters', stateKey)
            }
          />
        </AnimatedAside>
      );
    }
    const submenuItems = this.getFilteredSubMenuItems();
    return (
      <AnimatedAside
        variants={submenuVariants}
        initial="hidden"
        animate={activeFilter !== null ? 'visible' : 'hidden'}
        isSublevel
        noScroll
        footerComponent={
          <Button fullWidth kind="shy" onClick={this.onClickHideFilter}>
            {localeLookup('translations.Back')}
          </Button>
        }
      >
        <SideNavTitle title={subMenuTitle} />
        {activeFilter ? (
          <SideNavFilter
            autoFocus
            inputRef={(el) => {
              this.filterInput = el;
            }}
            //value={filterSearchQuery}
            onChange={this.onChangeFilterQuery}
            placeholder={`${localeLookup('translations.Search')}...`}
            filterActions={[
              {
                title: localeLookup('translations.Select all'),
                onClick: this.onClickFilterSelectAll,
              },
              {
                title: localeLookup('translations.Deselect all'),
                onClick: this.onClickFilterDeselectAll,
              },
            ]}
          />
        ) : null}
        <SideNavVirtualListWrapper>
          <AutoSizer nonce={cspNonce}>
            {({ height }) => (
              <List
                ref={(el) => (this.submenuList = el)}
                height={height}
                rowHeight={50}
                rowRenderer={({ index, key, style }) => {
                  const item = submenuItems[index];
                  return (
                    <SideNavItem
                      small
                      element="div"
                      showHoverTitle
                      key={key}
                      style={style}
                      title={item.name}
                      tooltip={item.tooltip}
                      subtitle={item.subtitle}
                      onClick={item.onToggle}
                      stopRightComponentClickPropagation
                      rightComponent={
                        <Checkbox
                          onChange={item.onToggle}
                          isChecked={item.selected}
                          id={key}
                        />
                      }
                    />
                  );
                }}
                rowCount={submenuItems.length}
                width={279}
              />
            )}
          </AutoSizer>
        </SideNavVirtualListWrapper>
      </AnimatedAside>
    );
  };

  renderTable = () => {
    const { rows } = this.state;

    return (
      <AutoSizer>
        {({ height, width }) => (
          <MultiGrid
            ref={(el) => (this.gridRef = el)}
            classNameBottomLeftGrid="permission-matrix__table-bottom-left-grid"
            enableFixedColumnScroll
            classNameBottomRightGrid="scrollContainer"
            getScrollbarSize={this.getScrollbarSize}
            cellRenderer={this.renderCell}
            columnCount={this.COLUMNS.length}
            columnWidth={this.getColumnWidth}
            fixedColumnCount={1}
            fixedRowCount={1}
            rowCount={rows.length + 1}
            rowHeight={this.getRowHeight}
            width={width}
            height={height}
          />
        )}
      </AutoSizer>
    );
  };

  renderCell = ({ columnIndex, key, rowIndex, style, parent }) => {
    if (rowIndex === 0)
      return this.renderHeaderCell({ columnIndex, key, rowIndex, style });
    return this.renderTableCell({ columnIndex, key, rowIndex, style });
  };

  renderHeaderCell = ({ columnIndex, key, rowIndex, style }) => {
    const { sorting } = this.state;
    if (!this.COLUMNS[columnIndex].rotated) {
      return (
        <TableHeaderCell
          className="permission-matrix__header-cell"
          sortable
          sortActive={sorting.columnId === this.COLUMNS[columnIndex].id}
          sortDirection={sorting.direction}
          onClick={() =>
            this.onClickChangeSorting(this.COLUMNS[columnIndex].id)
          }
          key={key}
          title={this.COLUMNS[columnIndex].label}
          style={style}
          tooltip={this.COLUMNS[columnIndex].tooltip}
        />
      );
    }
    return (
      <TableHeaderCell
        className={cx(
          'permission-matrix__header-cell',
          'permission-matrix__header-cell--rotated',
          {
            'permission-matrix__header-cell--hidden':
              columnIndex === this.COLUMNS.length - 1,
          }
        )}
        sortable
        sortActive={sorting.columnId === this.COLUMNS[columnIndex].id}
        sortDirection={sorting.direction}
        onClick={null}
        key={key}
        title={this.COLUMNS[columnIndex].label}
        style={style}
      >
        <div
          onClick={() => {
            this.onClickChangeSorting(this.COLUMNS[columnIndex].id);
          }}
        >
          <span className="permission-matrix__header-cell-title">
            {this.COLUMNS[columnIndex].label}
          </span>
          <Tooltip tooltip={this.COLUMNS[columnIndex].tooltip}>
            <span>
              <Icon
                className="permission-matrix__header-cell-tooltip-icon"
                kind="info-circle"
              ></Icon>
            </span>
          </Tooltip>
        </div>
      </TableHeaderCell>
    );
  };

  getPersonCreateAreaPermissionInfo = (personId) => {
    const { personsWithAccessLevels } = this.state;
    const { spacesEnabled, spaces } = this.props;
    const personWithAccessLevels = personsWithAccessLevels[personId];
    const isAdmin = [
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ].some((accessLevel) => {
      return personWithAccessLevels.accessLevels.includes(accessLevel);
    });
    if (spacesEnabled) {
      const spacesWherePersonCanCreateAreas = Object.values(spaces).filter(
        (space) => {
          if (isAdmin) return true;
          if (space.administrators.includes(personId)) return true;
          return space.canCreateModules.includes(personId);
        }
      );
      return {
        canCreate: spacesWherePersonCanCreateAreas.length > 0,
        spacesWherePersonCanCreateAreas,
      };
    } else {
      const mainSpace = spaces[Object.keys(spaces)[0]];
      // If spaces is not enabled, the permission lies on main space
      const canCreate =
        isAdmin || mainSpace.canCreateModules.includes(personId);

      return { canCreate, spacesWherePersonCanCreateAreas: [mainSpace] };
    }
  };

  renderCreateAreasPermissionCell = ({ columnIndex, key, rowIndex, style }) => {
    const { rows, personsWithAccessLevels } = this.state;
    const { loggedInUserId, hasAccess, spacesEnabled, spaces, editorActions } =
      this.props;
    const column = this.COLUMNS[columnIndex];
    const dataRowIndex = rowIndex - 1;
    const person = rows[dataRowIndex];
    const personWithAccessLevels = personsWithAccessLevels[person.id];
    const permissionInfo = this.getPersonCreateAreaPermissionInfo(person.id);
    const isPersonCurrentUser = person.id === loggedInUserId;
    const canChangePermissions =
      person.state !== PERSON_STATES.ARCHIVED &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]);
    const isAdmin =
      [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ].some((accessLevel) => {
        return personWithAccessLevels.accessLevels.includes(accessLevel);
      }) || person.isChampEmployee;
    if (spacesEnabled) {
      return (
        <TableCell
          tooltip={
            permissionInfo.spacesWherePersonCanCreateAreas.length > 0 ? (
              <>
                <span>
                  {localeLookup(
                    'translations.Can create modules in following content spaces'
                  )}
                  :
                </span>
                {permissionInfo.spacesWherePersonCanCreateAreas.map(
                  (space) => `\n${space.name}`
                )}
              </>
            ) : (
              localeLookup(
                'translations.Can not create modules in any content spaces'
              )
            )
          }
          centerContent
          key={key}
          style={style}
        >
          <CheckboxButton
            className="person-editor__info-section-admin-checkbox"
            disabled={
              person.isChampEmployee ||
              person[column.id].hasParentAccessLevel ||
              !canChangePermissions
            }
            onClick={() =>
              editorActions.showChangePersonSpaceCreateAreaModal({
                personId: person.id,
                onChanged: () => {
                  this.gridRef?.recomputeGridSize();
                },
              })
            }
            color={isAdmin ? 'green' : 'grey'}
            icon={isAdmin ? 'check' : null}
            text={
              permissionInfo.spacesWherePersonCanCreateAreas.length > 0 &&
              !isAdmin
                ? permissionInfo.spacesWherePersonCanCreateAreas.length
                : null
            }
          />
        </TableCell>
      );
    } else {
      const mainSpace = spaces[Object.keys(spaces)[0]];
      return (
        <TableCell centerContent key={key} style={style}>
          <Checkbox
            id={`${person.id}${column.id}`}
            name={column.id}
            onChange={(e) =>
              this.onChangePersonCreateAreaPermission({
                personId: person.id,
                spaceId: mainSpace.id,
                canCreateModules: permissionInfo.canCreate,
              })
            }
            isChecked={permissionInfo.canCreate}
            disabled={
              person.isChampEmployee ||
              person[column.id].hasParentAccessLevel ||
              column.id === ACCESS_LEVELS.systemAdministrator ||
              !canChangePermissions
            }
          ></Checkbox>
        </TableCell>
      );
    }
  };

  getPersonCreateRolePermissionInfo = (personId) => {
    const { personsWithAccessLevels } = this.state;
    const { spacesEnabled, spaces } = this.props;
    const personWithAccessLevels = personsWithAccessLevels[personId];
    const isAdmin = [
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.contentAdministrator,
    ].some((accessLevel) => {
      return personWithAccessLevels.accessLevels.includes(accessLevel);
    });
    if (spacesEnabled) {
      const spacesWherePersonCanCreateRoles = Object.values(spaces).filter(
        (space) => {
          if (isAdmin) return true;
          if (space.administrators.includes(personId)) return true;
          return space.canCreateRoles.includes(personId);
        }
      );
      return {
        canCreate: spacesWherePersonCanCreateRoles.length > 0,
        spacesWherePersonCanCreateRoles,
      };
    } else {
      const mainSpace = spaces[Object.keys(spaces)[0]];
      // If spaces is not enabled, the permission lies on main space
      const canCreate = isAdmin || mainSpace.canCreateRoles.includes(personId);

      return { canCreate, spacesWherePersonCanCreateRoles: [mainSpace] };
    }
  };

  renderCreateRolesPermissionCell = ({ columnIndex, key, rowIndex, style }) => {
    const { rows, personsWithAccessLevels } = this.state;
    const { loggedInUserId, hasAccess, spacesEnabled, spaces, editorActions } =
      this.props;
    const column = this.COLUMNS[columnIndex];
    const dataRowIndex = rowIndex - 1;
    const person = rows[dataRowIndex];
    const personWithAccessLevels = personsWithAccessLevels[person.id];
    const isAdmin =
      [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ].some((accessLevel) => {
        return personWithAccessLevels.accessLevels.includes(accessLevel);
      }) || person.isChampEmployee;
    const permissionInfo = this.getPersonCreateRolePermissionInfo(person.id);
    const isPersonCurrentUser = person.id === loggedInUserId;
    const canChangePermissions =
      person.state !== PERSON_STATES.ARCHIVED &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]);
    if (spacesEnabled) {
      return (
        <TableCell
          tooltip={
            permissionInfo.spacesWherePersonCanCreateRoles.length > 0 ? (
              <>
                <span>
                  {localeLookup(
                    'translations.Can create roles in following content spaces'
                  )}
                  :
                </span>
                {permissionInfo.spacesWherePersonCanCreateRoles.map(
                  (space) => `\n${space.name}`
                )}
              </>
            ) : (
              localeLookup(
                'translations.Can not create roles in any content spaces'
              )
            )
          }
          centerContent
          key={key}
          style={style}
        >
          <CheckboxButton
            disabled={
              person.isChampEmployee ||
              person[column.id].hasParentAccessLevel ||
              column.id === ACCESS_LEVELS.systemAdministrator ||
              !canChangePermissions
            }
            onClick={() =>
              editorActions.showChangePersonSpaceCreateRoleModal({
                personId: person.id,
                onChanged: () => {
                  this.gridRef?.recomputeGridSize();
                },
              })
            }
            color={isAdmin ? 'green' : 'grey'}
            icon={isAdmin ? 'check' : null}
            text={
              permissionInfo.spacesWherePersonCanCreateRoles.length > 0 &&
              !isAdmin
                ? permissionInfo.spacesWherePersonCanCreateRoles.length
                : null
            }
          />
        </TableCell>
      );
    } else {
      const mainSpace = spaces[Object.keys(spaces)[0]];
      return (
        <TableCell centerContent key={key} style={style}>
          <Checkbox
            id={`${person.id}${column.id}`}
            name={column.id}
            onChange={(e) =>
              this.onChangePersonCreateRolePermission({
                personId: person.id,
                spaceId: mainSpace.id,
                canCreateRoles: permissionInfo.canCreate,
              })
            }
            isChecked={permissionInfo.canCreate}
            disabled={
              person.isChampEmployee ||
              person[column.id].hasParentAccessLevel ||
              column.id === ACCESS_LEVELS.systemAdministrator ||
              !canChangePermissions
            }
          ></Checkbox>
        </TableCell>
      );
    }
  };

  getPersonContentAdministratorInfo = (personId, isChampEmployee) => {
    const { personsWithAccessLevels } = this.state;
    const { spacesEnabled, spaces } = this.props;
    const personWithAccessLevels = personsWithAccessLevels[personId];
    const isAdmin =
      [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ].some((accessLevel) => {
        return personWithAccessLevels.accessLevels.includes(accessLevel);
      }) || isChampEmployee;
    if (spacesEnabled) {
      const spacesWherePersonIsContentAdministrator = Object.values(
        spaces
      ).filter((space) => {
        if (isAdmin) return true;
        return space.administrators.includes(personId);
      });
      return {
        isContentAdministrator: isAdmin,
        spacesWherePersonIsContentAdministrator:
          spacesWherePersonIsContentAdministrator,
      };
    } else {
      const mainSpace = spaces[Object.keys(spaces)[0]];
      const isContentAdministrator = isAdmin;

      return {
        isContentAdministrator,
        spacesWherePersonIsContentAdministrator: [mainSpace],
      };
    }
  };

  getPersonGroupAdministratorInfo = (personId, isChampEmployee) => {
    const { personsWithAccessLevels } = this.state;
    const { groups } = this.props;
    const personWithAccessLevels = personsWithAccessLevels[personId];
    const isAdmin =
      [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.groupAdministrator,
      ].some((accessLevel) => {
        return personWithAccessLevels.accessLevels.includes(accessLevel);
      }) || isChampEmployee;

    const groupsWherePersonIsContentAdministrator = Object.values(
      groups
    ).filter((group) => {
      if (isAdmin) return true;
      return group.administrators.includes(personId);
    });
    return {
      isGroupAdministrator: isAdmin,
      groupsWherePersonIsContentAdministrator:
        groupsWherePersonIsContentAdministrator,
    };
  };

  renderContentAdministratorCell = ({ columnIndex, key, rowIndex, style }) => {
    const { rows, personsWithAccessLevels } = this.state;
    const { loggedInUserId, hasAccess, spacesEnabled, editorActions } =
      this.props;
    const column = this.COLUMNS[columnIndex];
    const dataRowIndex = rowIndex - 1;
    const person = rows[dataRowIndex];
    const permissionInfo = this.getPersonContentAdministratorInfo(
      person.id,
      person.isChampEmployee
    );
    const isPersonCurrentUser = person.id === loggedInUserId;
    const canChangePermissions =
      person.state !== PERSON_STATES.ARCHIVED &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]);
    if (spacesEnabled) {
      const tooltip =
        permissionInfo.spacesWherePersonIsContentAdministrator.length > 0 &&
        !permissionInfo.isContentAdministrator ? (
          <>
            <span>
              {localeLookup(
                'translations.Content administrator in following content spaces'
              )}
              :
            </span>
            {permissionInfo.spacesWherePersonIsContentAdministrator.map(
              (space) => `\n${space.name}`
            )}
          </>
        ) : null;
      return (
        <TableCell tooltip={tooltip} centerContent key={key} style={style}>
          <CheckboxButton
            disabled={
              person.isChampEmployee ||
              person[column.id].hasParentAccessLevel ||
              !canChangePermissions
            }
            onClick={() =>
              editorActions.showChangePersonSpaceAdministratorModal({
                personId: person.id,
                onChanged: () => this.updatePersonRow(person.id),
              })
            }
            color={permissionInfo.isContentAdministrator ? 'green' : 'grey'}
            icon={permissionInfo.isContentAdministrator ? 'check' : null}
            text={
              permissionInfo.spacesWherePersonIsContentAdministrator.length >
                0 && !permissionInfo.isContentAdministrator
                ? permissionInfo.spacesWherePersonIsContentAdministrator.length
                : null
            }
          />
        </TableCell>
      );
    } else {
      return (
        <TableCell centerContent key={key} style={style}>
          <Checkbox
            id={`${person.id}${column.id}`}
            name={column.id}
            onChange={(e) => this.onChangePersonPermission(person.id, e)}
            isChecked={permissionInfo.isContentAdministrator}
            disabled={
              person.isChampEmployee ||
              person[column.id].hasParentAccessLevel ||
              !canChangePermissions
            }
          ></Checkbox>
        </TableCell>
      );
    }
  };

  renderGroupAdministratorCell = ({ columnIndex, key, rowIndex, style }) => {
    const { rows, personsWithAccessLevels } = this.state;
    const {
      loggedInUserId,
      hasAccess,
      spacesEnabled,
      editorActions,
      groups,
      groupActions,
    } = this.props;
    const column = this.COLUMNS[columnIndex];
    const dataRowIndex = rowIndex - 1;
    const person = rows[dataRowIndex];
    const permissionInfo = this.getPersonGroupAdministratorInfo(
      person.id,
      person.isChampEmployee
    );
    const hasGroups = Object.keys(groups).length > 0;
    const isPersonCurrentUser = person.id === loggedInUserId;
    const canChangePermissions =
      person.state !== PERSON_STATES.ARCHIVED &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]);
    if (hasGroups) {
      const tooltip =
        permissionInfo.groupsWherePersonIsContentAdministrator.length > 0 &&
        !permissionInfo.isGroupAdministrator ? (
          <>
            <span>
              {localeLookup(
                'translations.Group administrator in following groups'
              )}
              :
            </span>
            {permissionInfo.groupsWherePersonIsContentAdministrator.map(
              (space) => `\n${space.name}`
            )}
          </>
        ) : null;
      return (
        <TableCell tooltip={tooltip} centerContent key={key} style={style}>
          <CheckboxButton
            disabled={
              person.isChampEmployee ||
              person[column.id].hasParentAccessLevel ||
              !canChangePermissions
            }
            onClick={() =>
              groupActions.showChangePersonGroupAdministratorModal({
                personId: person.id,
                onChanged: () => this.updatePersonRow(person.id),
              })
            }
            color={permissionInfo.isGroupAdministrator ? 'green' : 'grey'}
            icon={permissionInfo.isGroupAdministrator ? 'check' : null}
            text={
              permissionInfo.groupsWherePersonIsContentAdministrator.length >
                0 && !permissionInfo.isGroupAdministrator
                ? permissionInfo.groupsWherePersonIsContentAdministrator.length
                : null
            }
          />
        </TableCell>
      );
    } else {
      return (
        <TableCell centerContent key={key} style={style}>
          <Checkbox
            id={`${person.id}${column.id}`}
            name={column.id}
            onChange={(e) => this.onChangePersonPermission(person.id, e)}
            isChecked={permissionInfo.isGroupAdministrator}
            disabled={
              person.isChampEmployee ||
              person[column.id].hasParentAccessLevel ||
              !canChangePermissions
            }
          ></Checkbox>
        </TableCell>
      );
    }
  };

  getPersonOrganisationAdministratorInfo = (personId, isChampEmployee) => {
    const { personsWithAccessLevels } = this.state;
    const { organisationUnits, persons } = this.props;
    const personWithAccessLevels = personsWithAccessLevels[personId];
    const isAdmin =
      [ACCESS_LEVELS.champadministrator, ACCESS_LEVELS.administrator].some(
        (accessLevel) => {
          return personWithAccessLevels.accessLevels.includes(accessLevel);
        }
      ) || isChampEmployee;
    const isOrganisationAdmin =
      organisationUnits[
        Object.keys(organisationUnits)[0]
      ].teamAdministrators.includes(personId) || isAdmin;
    const unitsWherePersonIsAdministrator = Object.keys(
      organisationUnits
    ).filter((unitId) =>
      organisationUnits[unitId].teamAdministrators.includes(personId)
    );
    const getUnitChildrenRecursively = (unitId) => {
      const unit = organisationUnits[unitId];
      const allChildren = [...unit.descendants];
      unit.descendants.forEach((unitId) => {
        if (organisationUnits[unitId].descendants.length > 0) {
          allChildren.push(...getUnitChildrenRecursively(unitId));
        }
      });
      return allChildren;
    };
    const visibleAdminUnits = unitsWherePersonIsAdministrator
      .map((unitId) => {
        return getUnitChildrenRecursively(unitId);
      })
      .flat();

    return {
      isOrganisationAdmin,
      unitsWherePersonIsAdministrator: [
        ...new Set([...unitsWherePersonIsAdministrator, ...visibleAdminUnits]),
      ],
    };
  };

  renderHeader = () => {
    const { hasAccess } = this.props;
    const showHeader = hasAccess([
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.groupAdministrator,
      ACCESS_LEVELS.groupManager,
      ACCESS_LEVELS.createGroups,
    ]);
    if (!showHeader) return null;
    return <Header leftComponent={this.renderTabs()}></Header>;
  };

  renderOrganisationAdministratorCell = ({
    columnIndex,
    key,
    rowIndex,
    style,
  }) => {
    const { rows } = this.state;
    const {
      loggedInUserId,
      hasAccess,
      spacesEnabled,
      editorActions,
      organisationUnits,
    } = this.props;
    const column = this.COLUMNS[columnIndex];
    const dataRowIndex = rowIndex - 1;
    const person = rows[dataRowIndex];
    const permissionInfo = this.getPersonOrganisationAdministratorInfo(
      person.id,
      person.isChampEmployee
    );
    const isPersonCurrentUser = person.id === loggedInUserId;
    const canChangePermissions =
      person.state !== PERSON_STATES.ARCHIVED &&
      !isPersonCurrentUser &&
      hasAccess([
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.assignAccessLevels,
      ]);
    if (Object.keys(organisationUnits).length > 0) {
      const tooltip =
        permissionInfo.unitsWherePersonIsAdministrator.length > 0 &&
        !permissionInfo.isOrganisationAdmin ? (
          <>
            <span>
              {localeLookup(
                'translations.Organisation administrator in following units'
              )}
              :
            </span>
            {permissionInfo.unitsWherePersonIsAdministrator.map((unitId) => {
              const unit = organisationUnits[unitId];
              if (!unit) return null;
              const isVisibilityLimited =
                unit.state === ORGANISATION_UNIT_STATES.INHERITED_PASSIVE ||
                unit.state === ORGANISATION_UNIT_STATES.PASSIVE;
              return `\n${unit.name}${
                isVisibilityLimited
                  ? ` (${localeLookup('translations.Limited visibility')})`
                  : ''
              }`;
            })}
          </>
        ) : null;
      return (
        <TableCell tooltip={tooltip} centerContent key={key} style={style}>
          <CheckboxButton
            className="person-editor__info-section-admin-checkbox"
            disabled={
              person.isChampEmployee ||
              person[column.id].hasParentAccessLevel ||
              !canChangePermissions
            }
            onClick={() =>
              editorActions.showChangePersonOrganisationAdministratorModal({
                personId: person.id,
                onChanged: () => this.updatePersonRow(person.id),
              })
            }
            color={permissionInfo.isOrganisationAdmin ? 'green' : 'grey'}
            icon={permissionInfo.isOrganisationAdmin ? 'check' : null}
            text={
              permissionInfo.unitsWherePersonIsAdministrator.length > 0 &&
              !permissionInfo.isOrganisationAdmin
                ? permissionInfo.unitsWherePersonIsAdministrator.length
                : null
            }
          />
        </TableCell>
      );
    } else {
      return (
        <TableCell centerContent key={key} style={style}>
          <Checkbox
            id={`${person.id}${column.id}`}
            name={column.id}
            onChange={(e) => this.onChangePersonPermission(person.id, e)}
            isChecked={permissionInfo.isOrganisationAdmin}
            disabled={
              person.isChampEmployee ||
              person[column.id].hasParentAccessLevel ||
              !canChangePermissions
            }
          ></Checkbox>
        </TableCell>
      );
    }
  };

  renderTabs = () => {
    const { activeView } = this.state;
    const tabs = this.getTabs();

    return (
      <Tabs
        activeTab={activeView}
        tabWidth="10rem"
        onChangeTab={this.onChangeView}
        tabs={tabs}
      />
    );
  };

  renderTableCell = ({ columnIndex, key, rowIndex, style }) => {
    const { rows } = this.state;
    const { loggedInUserId, hasAccess, spacesEnabled } = this.props;
    // Hack to add spacing - Last column is hidden
    if (columnIndex === this.COLUMNS.length - 1) return null;
    const column = this.COLUMNS[columnIndex];
    const dataRowIndex = rowIndex - 1;
    const row = rows[dataRowIndex];
    if (column.id === 'groupadministrator') {
      return this.renderGroupAdministratorCell({
        columnIndex,
        key,
        rowIndex,
        style,
      });
    }
    if (column.id === 'contentadministrator') {
      return this.renderContentAdministratorCell({
        columnIndex,
        key,
        rowIndex,
        style,
      });
    }
    if (column.id === 'createroles') {
      return this.renderCreateRolesPermissionCell({
        columnIndex,
        key,
        rowIndex,
        style,
      });
    }
    if (column.id === 'createmodules') {
      return this.renderCreateAreasPermissionCell({
        columnIndex,
        key,
        rowIndex,
        style,
      });
    }
    if (column.id === 'teamadministrator') {
      return this.renderOrganisationAdministratorCell({
        columnIndex,
        key,
        rowIndex,
        style,
      });
    }
    if (column.type === 'text') {
      if (column.editable) {
        return (
          <TableCellEditable
            title={row[column.id].title}
            tooltip={row[column.id].tooltip}
            key={key}
            style={style}
            className={
              column.id === 'initials'
                ? 'table-cell-editable--text-uppercase'
                : null
            }
            controlValue={
              row[column.id].controlValueProp || row[column.id].title
            }
            controlType={column.controlType}
            controlProps={column.controlProps}
            controlDisabled={
              column.controlDisabled
                ? column.controlDisabled(row)
                : false || row.isChampEmployee
            }
            onSubmit={(value) =>
              column.onSubmit({ personId: row.id, id: row.id, value })
            }
            selectOptions={column.selectOptions}
            contextMenuItems={row[column.id].contextMenuItems}
          />
        );
      }
      return (
        <TableCell
          icon={column.icon}
          hoverToShowIcon
          tooltip={row[column.id].tooltip}
          disabled={row.isChampEmployee || column.disabled}
          onIconClick={() =>
            column.onIconClick({ personId: row.id, groupId: row.id })
          }
          iconSize="sm"
          subtitle={row[column.id].subtitle}
          contextMenuItems={row[column.id].contextMenuItems}
          centerContent
          key={key}
          style={style}
          title={row[column.id].title}
        ></TableCell>
      );
    }
    if (column.type === 'checkbox') {
      const isPersonCurrentUser = row.id === loggedInUserId;
      const canChangePermissions =
        row.state !== PERSON_STATES.ARCHIVED &&
        !isPersonCurrentUser &&
        hasAccess([
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.assignAccessLevels,
        ]);
      return (
        <TableCell centerContent key={key} style={style}>
          <Checkbox
            id={`${row.id}${column.id}`}
            name={column.id}
            onChange={(e) => this.onChangePersonPermission(row.id, e)}
            isChecked={
              row[column.id].hasAccessLevel ||
              row[column.id].hasParentAccessLevel
            }
            disabled={
              row.isChampEmployee ||
              row[column.id].disabled ||
              row[column.id].hasParentAccessLevel ||
              column.id === ACCESS_LEVELS.systemAdministrator ||
              !canChangePermissions
            }
          ></Checkbox>
        </TableCell>
      );
    }
    return (
      <TableCell key={key} style={style}>
        {columnIndex} {rowIndex}
      </TableCell>
    );
  };

  render() {
    const { isLoading, isUpdating } = this.state;
    if (isLoading) return null;
    return (
      <>
        {this.renderHeader()}
        <div
          className={cx('permission-matrix', {
            'permission-matrix--state-updating': isUpdating,
          })}
        >
          {this.renderSidebar()}
          <div className="permission-matrix__table">{this.renderTable()}</div>
        </div>
      </>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  WithEditorActions,
  WithGroupActions,
  WithPersonActions,
  withAccessControl,
  WithModals
)(PermissionMatrix);
