import React, { useEffect, useState } from 'react';
import Icon from '../Icon';
import ConfirmButton from '../ConfirmButton';
import localeLookup from '../../config/locale';
import axios from 'axios';
import { axiosRequestGET } from '../../services/serviceConfig';

const Document = ({ fileName, url, onDelete, canDelete }) => {
  const [href, setHref] = useState('');

  useEffect(() => {
    // Your code here
    axiosRequestGET(url, null, {
      responseType: 'blob',
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
      setHref(href);
    });
  }, []);
  return (
    <div className="document">
      <div className="document__icon-wrapper">
        <Icon className="document__icon" kind="paperclip" />
      </div>
      <div className="document__info">
        <p className="document__info-name">
          <span>{fileName}</span>
        </p>
      </div>
      {canDelete && (
        <ConfirmButton
          onConfirm={onDelete}
          render={(onClick, isActive) => {
            return (
              <button
                onClick={onClick}
                className="ui-btn ui-btn-alert ui-small"
              >
                {localeLookup('translations.Delete')}
              </button>
            );
          }}
        />
      )}
      <a
        href={href}
        download={fileName}
        rel="noopener noreferrer"
        target="_blank"
        className="ui-btn ui-btn-darkui ui-small document__delete"
      >
        {localeLookup('translations.Open')}
      </a>
    </div>
  );
};

export default Document;
