import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import localeLookup from '../../config/locale';
import { EMPTY_ID, ROLE_OPERATIONS } from '../../constants';
import {
  activatePersonService,
  changeEmployeeNumberService,
  changeLoginToExternalLoginService,
  changeLoginToInternalLoginService,
  changeLoginToNoLoginService,
  changePersonRoleRelevanceService,
  deactivatePersonService,
  deletePersonService,
  getRoleLevelChangeConflictsService,
  removeRoleFromPersonService,
  setPersonRoleAsExperiencedService,
  setPersonRoleAsQualifiedService,
} from '../../services/personsService';
import { getPersons, removePerson } from '../../slices/personsSlice';
import withAccessControl from './withAccessControl';
import WithModals from './withModals';
import { getAllOrganisationUnitsService } from '../../services/organisationService';
const mapStateToProps = (state, ownProps) => {
  const { persons } = state;
  return { persons, wrappedComponentProps: ownProps };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getPersons,
      removePerson,
    },
    dispatch
  ),
});

const WithPersonActions = (WrappedComponent) => {
  class WithPersonActionsComponent extends React.Component {
    state = {};
    activatePerson = ({ id }) => {
      return activatePersonService(id);
    };

    onChangeRoleLevel = ({
      operationType,
      onConfirm,
      conflictModalTitle,
      skipCloseOnConfirm,
      conflictModalConfirmButtonType,
      conflictModalInfoText,
      person,
      role,
    }) => {
      const { showModal } = this.props;
      getRoleLevelChangeConflictsService(person.id, {
        roleId: role.id,
        operationType,
      })
        .then(() => onConfirm())
        .catch(({ response }) => {
          if (response.status === 409) {
            showModal('confirmation', {
              title: conflictModalTitle,
              subtitle: `${person.name} · ${role.name}`,
              infoText:
                conflictModalInfoText ||
                localeLookup(
                  'translations.If you change the level of the role, it will also affect the level in these organisation units'
                ),
              fullWidth: true,
              maxWidth: '500px',
              confirmButtonText: localeLookup('translations.Continue'),
              confirmButtonType: conflictModalConfirmButtonType,
              skipCloseOnConfirm: skipCloseOnConfirm,
              body: (
                <>
                  <p>
                    {localeLookup(
                      "translations.The person's role is part of the following organisation units"
                    )}
                    :
                  </p>
                  <ul>
                    {response.data.organisationUnits.map((unitName) => (
                      <li key={unitName}>{unitName}</li>
                    ))}
                  </ul>
                </>
              ),
              onConfirm: () => onConfirm(),
            });
          }
        });
    };

    onChangePersonRoleRelevance = async ({
      person,
      role,
      onChanged,
      organisationUnitsWherePersonHasRole,
      personRoleNotRelevantInOrganisationUnitIds,
    }) => {
      const { showModal, hideModal } = this.props;
      const selectedOptionIds = organisationUnitsWherePersonHasRole.filter(
        (id) => !personRoleNotRelevantInOrganisationUnitIds.includes(id)
      );
      const organisationUnitsResponse = await getAllOrganisationUnitsService();
      const organisationUnits = organisationUnitsResponse.data;
      const options = organisationUnitsWherePersonHasRole.reduce((acc, id) => {
        const unit = organisationUnits[id];
        if (unit) {
          return [
            ...acc,
            {
              title: unit.name,
              id: unit.id,
            },
          ];
        }
        return acc;
      }, []);

      showModal('checkList', {
        title: `${localeLookup('translations.Relevance')}`,
        subtitle: `${person.name} · ${role.name}`,
        fullWidth: true,
        maxWidth: '700px',
        showFilter: false,
        infoText: localeLookup(
          "translations.This feature controls whether a person's role is relevant in the individual organisation unit and whether the person's role affects goals for the organisation unit. If the person's role is not relevant in any organisation units, the \"Remove role\" feature should be used"
        ),
        confirmBtnText: localeLookup('translations.Save'),
        options,
        selectedOptionIds,
        onConfirm: (selectedUnits) => {
          hideModal();
          const notRelevantInOrganisationUnitIds =
            organisationUnitsWherePersonHasRole.filter(
              (id) => !selectedUnits.includes(id)
            );
          changePersonRoleRelevanceService({
            personId: person.id,
            notRelevantInOrganisationUnitIds: notRelevantInOrganisationUnitIds,
            roleId: role.id,
          }).then(() => {
            onChanged?.();
          });
        },
      });
    };

    onChangePersonRoleToExperienced = ({ person, role, onChanged }) => {
      this.onChangeRoleLevel({
        personId: person.id,
        roleId: role.id,
        person,
        role,
        onConfirm: () => {
          setPersonRoleAsExperiencedService({
            personId: person.id,
            roleId: role.id,
          }).then(() => {
            onChanged?.();
          });
        },
        operationType: ROLE_OPERATIONS.CHANGE_LEVEL,
        conflictModalTitle: localeLookup('translations.Set as experienced'),
      });
    };

    onChangePersonRoleToQualified = ({ person, role, onChanged }) => {
      this.onChangeRoleLevel({
        person,
        role,
        operationType: ROLE_OPERATIONS.CHANGE_LEVEL,
        conflictModalTitle: localeLookup('translations.Set as qualified'),
        onConfirm: () =>
          setPersonRoleAsQualifiedService({
            personId: person.id,
            roleId: role.id,
          }).then(() => {
            onChanged?.();
          }),
      });
    };

    onChangePersonRoleTraining = ({
      person,
      role,
      personRole,
      onChanged,
      unitId,
    }) => {
      const hasMentor = personRole?.mentor && personRole?.mentor !== EMPTY_ID;
      this.onChangeRoleLevel({
        person,
        role,
        onConfirm: () =>
          this.showTrainingModal({
            onConfirm: onChanged,
            personRole,
            role,
            person,
            unitId,
            context: 'edit',
          }),
        operationType: ROLE_OPERATIONS.CHANGE_LEVEL,
        conflictModalTitle: hasMentor
          ? localeLookup('translations.Edit training')
          : localeLookup('translations.Start training'),
        conflictModalInfoText: hasMentor
          ? localeLookup(
              'translations.If you edit the training program, it will also affect the program in these organisation units'
            )
          : localeLookup(
              'translations.If you change the level of the role, it will also affect the level in these organisation units'
            ),
        skipCloseOnConfirm: true,
      });
    };

    onRemovePersonRole = ({ onChanged, role, person }) => {
      this.onChangeRoleLevel({
        role,
        person,
        operationType: ROLE_OPERATIONS.REMOVE_ROLE,
        conflictModalTitle: localeLookup('translations.Remove role'),
        conflictModalConfirmButtonType: 'alert',
        conflictModalInfoText: localeLookup(
          `translations.This action will remove the person's role in all organisation units. Use "Relevance" if the person's role needs to be removed from specific organisation units`
        ),
        onConfirm: () =>
          removeRoleFromPersonService({
            personId: person.id,
            roleId: role.id,
          }).then(() => {
            onChanged?.();
          }),
      });
    };

    onSubmitEditLogin = ({
      email,
      password,
      password2,
      newUserType,
      userId,
      onChanged,
    }) => {
      const { hideModal } = this.props;
      if (newUserType === 'NoAccess') {
        changeLoginToNoLoginService(userId).then(() => {
          hideModal();
          onChanged?.();
        });
      } else if (newUserType === 'Champ') {
        changeLoginToInternalLoginService(userId, {
          email,
          password,
          repeatedPassword: password2,
        }).then(() => {
          hideModal();
          onChanged?.();
        });
      } else if (newUserType === 'External') {
        changeLoginToExternalLoginService(userId, { email }).then(() => {
          hideModal();
          onChanged?.();
        });
      }
    };

    showArchivePersonModal = ({ id, onArchived }) => {
      const { showModal, getPersons, persons } = this.props;
      const person = persons[id];
      showModal('confirmation', {
        title: localeLookup('translations.Archive'),
        subtitle: person.name,
        maxWidth: '500px',
        fullWidth: true,
        body: (
          <>
            <p>{localeLookup('translations.Archiving maintains')}:</p>
            <ul>
              <li>
                {localeLookup(
                  'translations.Own roles (Shown under "Former roles")'
                )}
              </li>
              <li>
                {localeLookup(
                  "translations.Mentoring responsibility for other people's training programs"
                )}
              </li>
              <li>{localeLookup('translations.Status of own elements')}</li>
              <li>
                {localeLookup(
                  "translations.Responsibility for completion of other persons' elements"
                )}
              </li>
              <li>
                {localeLookup(
                  "translations.Completions on other people's elements"
                )}
              </li>
              <li>
                {localeLookup(
                  "translations.Signatures on other people's elements"
                )}
              </li>
            </ul>
            <br />
            <p>{localeLookup('translations.Archiving removes')}: </p>
            <ul>
              <li>{localeLookup('translations.Ability to log in')}</li>
              <li>{localeLookup('translations.Permissions')}</li>
              <li>
                {localeLookup('translations.Connection to organisation units')}
              </li>
              <li>
                {localeLookup('translations.Organisation administrator status')}
              </li>
              <li>
                {localeLookup('translations.Role owner- and editorships')}
              </li>
              <li>
                {localeLookup('translations.Module owner- and editorships')}
              </li>
              <li>{localeLookup('translations.Expert status')}</li>
            </ul>
          </>
        ),
        onConfirm: () => {
          deactivatePersonService(id).then(() => {
            getPersons().then(() => {
              onArchived?.();
            });
          });
        },
        confirmButtonText: localeLookup('translations.Archive'),
        confirmButtonType: 'darkui',
      });
    };

    showChangeEmployeeNumberModal = ({ id, onChanged }) => {
      const { persons, hideModal, showModal, getPersons } = this.props;
      const person = persons[id];
      showModal('employeeNumber', {
        title: localeLookup('translations.Employee number'),
        subtitle: person.name,
        defaultValue: person.employeeNumber,
        confirmButtonText: localeLookup('translations.Change'),
        onConfirm: ({ employeeNumber }) => {
          changeEmployeeNumberService(id, { employeeNumber }).then(() => {
            getPersons();
            hideModal();
            onChanged?.();
          });
        },
      });
    };

    showChangePersonPasswordModal = ({ id, isOwnPassword }) => {
      const { showModal, persons } = this.props;
      const person = persons[id];
      showModal('changePassword', {
        personId: id,
        isOwnPassword,
        confirmButtonText: localeLookup('translations.Change'),
        title: localeLookup('translations.Change password'),
        subtitle: person?.name,
        maxWidth: '500px',
        fullWidth: true,
      });
    };

    showCreatePersonModal = ({ onCreated }) => {
      const { showModal, hideModal } = this.props;
      showModal('createUser', {
        title: localeLookup('translations.Create person'),
        fullWidth: false,
        onCreated: ({ id }) => {
          hideModal();
          onCreated?.(id);
        },
      });
    };

    showDeletePersonModal = ({ id, onDeleted }) => {
      const { showModal, hideModal, persons, removePerson } = this.props;
      const person = persons[id];
      showModal('confirmation', {
        title: localeLookup('translations.Delete'),
        subtitle: person.name,
        safeWord: localeLookup('translations.Delete'),
        maxWidth: '500px',
        fullWidth: true,
        body: (
          <>
            <p>
              {localeLookup(
                'translations.All data about the person will be permanently deleted and can not be recovered'
              )}
            </p>
            <br />
            <p>
              {localeLookup(
                'translations.In the following contexts, the person is changed to "Deleted person"'
              )}
              :
            </p>
            <ul>
              <li>
                {localeLookup(
                  "translations.Mentoring responsibility for other people's training programs"
                )}
              </li>
              <li>
                {localeLookup(
                  "translations.Responsibility for completion of other persons' elements"
                )}
              </li>
              <li>
                {localeLookup(
                  "translations.Completions on other people's elements"
                )}
              </li>
            </ul>
            <br />
            <p>
              {localeLookup(
                "translations.Signatures on other people's elements are deleted, but other information about the completion is maintained"
              )}
            </p>
          </>
        ),
        onConfirm: () => {
          hideModal();
          deletePersonService(id).then(async () => {
            await removePerson(person.id);
            onDeleted?.();
          });
        },
        confirmButtonText: localeLookup('translations.Delete'),
        confirmButtonType: 'alert',
      });
    };

    showEditLoginModal = ({ userType, userId, email, onChanged }) => {
      const { showModal } = this.props;
      showModal('editLogin', {
        userType,
        userId,
        email,
        onSubmit: (values) =>
          this.onSubmitEditLogin({ ...values, userId, onChanged }),
      });
    };

    showTrainingModal = ({
      onConfirm,
      personRole,
      role,
      person,
      unitId,
      context,
    }) => {
      const { showModal } = this.props;
      const mentorId = personRole?.mentor;
      showModal('training', {
        title:
          mentorId && mentorId !== EMPTY_ID
            ? localeLookup('translations.Edit training')
            : localeLookup('translations.Start training'),
        subtitle: `${person.name} · ${role.name}`,
        context,
        stopPropagation: false,
        confirmButtonText: localeLookup('translations.Confirm'),
        fullWidth: true,
        maxWidth: '700px',
        initialValues: {
          mentorId,
          personId: person.id,
          organisationUnitId: unitId,
          roleId: role.id,
          endDate: personRole?.endDate ? new Date(personRole.endDate) : null,
          startDate: personRole?.startDate
            ? new Date(personRole.startDate)
            : new Date(),
          state: personRole?.state ? personRole.state : 'Active',
        },
        onConfirm: () => {
          onConfirm?.();
        },
      });
    };

    render() {
      return (
        <WrappedComponent
          personActions={{
            activatePerson: this.activatePerson,
            showArchivePersonModal: this.showArchivePersonModal,
            showChangePersonPasswordModal: this.showChangePersonPasswordModal,
            showDeletePersonModal: this.showDeletePersonModal,
            showEditPersonLoginModal: this.showEditLoginModal,
            showCreatePersonModal: this.showCreatePersonModal,
            showChangeEmployeeNumberModal: this.showChangeEmployeeNumberModal,
            onChangePersonRoleToExperienced:
              this.onChangePersonRoleToExperienced,
            onChangePersonRoleToQualified: this.onChangePersonRoleToQualified,
            onRemovePersonRole: this.onRemovePersonRole,
            onChangePersonRoleTraining: this.onChangePersonRoleTraining,
            onChangePersonRoleRelevance: this.onChangePersonRoleRelevance,
          }}
          {...this.props.wrappedComponentProps}
        />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithModals(withAccessControl(WithPersonActionsComponent)));
};

export default WithPersonActions;
