import React, { Component } from 'react';
import { connect } from 'react-redux';
import localeLookup from '../../config/locale';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';
import Loader from '../Loader';
import ListCard from '../ListCard';
import Text from '../Text';
import DateTime from '../DateTime';
import Button from '../Button';
import Badge from '../Badge';
import Icon from '../Icon';
import { getVersionsService } from '../../services/versionsService';
import withPersonLookup from '../HOC/withPersonLookup';
import { VERSION_TYPES } from '../../constants';
import KnowledgeElementContentButton from '../KnowledgeElementContentButton';

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    currentUserId: user.employeeId,
  };
};

class ElementVersionsModal extends Component {
  constructor() {
    super();
    this.state = {
      versions: [],
      isLoading: true,
      totalVersions: 0,
    };
  }

  componentDidMount() {
    this.getVersions();
  }

  getVersions = () => {
    const { element, personId } = this.props;
    getVersionsService({
      resources: [`cri:element/${element.id}`],
    }).then(({ data }) => {
      this.setState({
        versions: data,
        totalVersions: data.length,
        isLoading: false,
      });
    });
  };

  onClickLoadAll = () => {};

  onClickVersion = (version) => {
    const { showModal, element } = this.props;
    showModal('richText', {
      title: version.name,
      subtitle: (
        <>
          v{version.version} ·{' '}
          <DateTime includeTime date={version.date}></DateTime>
        </>
      ),
      files: version.files,
      description: version.description,
      fullWidth: true,
      onClose: this.onShowSelf,
      elementId: element.id,
    });
  };

  onShowSelf = () => {
    const { element, showModal, onClose } = this.props;
    showModal('elementVersions', {
      title: localeLookup('translations.Versions'),
      subtitle: element.name,
      element,
      fullWidth: true,
      maxWidth: '500px',
      onClose,
    });
  };

  renderVersionContentIcon = (description, files) => {
    return (
      <KnowledgeElementContentButton files={files} description={description} />
    );
  };

  renderVersions = () => {
    const { lookupPerson } = this.props;
    const { versions } = this.state;
    return versions.map((version) => {
      const changedBy = lookupPerson(version.changedBy);
      const renderVersionType = () => {
        switch (version.event) {
          case VERSION_TYPES.NAME:
            return localeLookup('translations.Edited name');
          case VERSION_TYPES.DESCRIPTION_ADDED:
            return localeLookup('translations.Added description');
          case VERSION_TYPES.DESCRIPTION_EDITED:
            return localeLookup('translations.Edited description');
          case VERSION_TYPES.DESCRIPTION_REMOVED:
            return localeLookup('translations.Removed description');
          case VERSION_TYPES.FILE_ADDED:
            return localeLookup('translations.Added files');
          case VERSION_TYPES.FILE_REMOVED:
            return localeLookup('translations.Removed files');
          case VERSION_TYPES.CREATED:
            return localeLookup('translations.Created element');
          default:
            return null;
        }
      };
      return (
        <ListCard
          onClick={() => this.onClickVersion(version)}
          el="button"
          clickable
          key={version.version}
          leftComponent={
            <Badge size="small" color="green" text={`v${version.version}`} />
          }
          rightComponent={this.renderVersionContentIcon(
            version.description,
            version.files
          )}
        >
          <Text>{version.name}</Text>
          <Text size="sm">{renderVersionType()}</Text>
          {version.event !== VERSION_TYPES.MIGRATED && (
            <Text size="sm">
              {changedBy?.name},{' '}
              <DateTime includeTime date={version.date}></DateTime>
            </Text>
          )}
        </ListCard>
      );
    });
  };

  render() {
    const {
      items,
      isLoading,
      totalEvents,
      lockState,
      versions,
      totalVersions,
    } = this.state;
    const { title, onClose, elementId, showModal, subtitle } = this.props;

    return (
      <ModalWrapper className="element-versions-modal">
        <ModalHeader title={title} onClose={onClose} subtitle={subtitle} />
        <ModalBody deep>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {this.renderVersions()}{' '}
              {versions.length < totalVersions && (
                <Button
                  className="element-versions-modal__button"
                  onClick={this.onClickLoadAll}
                  kind="invisible"
                >
                  {localeLookup('translations.Load all')}
                </Button>
              )}
            </>
          )}
        </ModalBody>

        <ModalFooter
          onCancelClick={onClose}
          cancelButtonText={localeLookup('translations.Close')}
        />
      </ModalWrapper>
    );
  }
}

export default connect(mapStateToProps)(withPersonLookup(ElementVersionsModal));
