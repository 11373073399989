import React, { Component } from 'react';
import { connect } from 'react-redux';
// Components
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import ModalWrapper from './ModalWrapper';

import localeLookup from '../../config/locale';
import BoxMessage from '../BoxMessage';
import Button from '../Button';
import ElementDescription from '../ElementDescription';
import FileHandler from '../FileHandler';
import FileUploadBox from '../FileUploadBox';
import RTEditor from '../RTEditor';

const mapStateToProps = (state, ownProps) => ({
  disableUpload: state.rootmenu.disableUpload,
});

class RTEditorModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue,
      RTEDirty: false,
      fileAdded: false,
      fileUploaded: false,
      isUploading: false,
      showPreview: false,
    };
  }

  getElementUntilFilePresent = async () => {
    const { getElement, elementId, updateModalProps } = this.props;
    let hasFiles = false;
    let requestHasFailed = false;
    const wait = (ms) =>
      new Promise((resolve) => {
        setTimeout(() => resolve(), ms);
      });
    do {
      try {
        const element = await getElement();
        hasFiles = element[elementId].files?.length > 0;
        if (hasFiles) {
          updateModalProps({ files: element[elementId].files });
          this.setState({
            fileUploaded: true,
            isUploading: false,
          });
          this.setPreventLeave(false);
        } else {
          await wait(2000);
        }
      } catch (error) {
        // Could not get element
        requestHasFailed = true;
      }
    } while (!hasFiles && !requestHasFailed);
  };

  canSubmit = () => {
    const { fileAdded, fileUploaded, RTEDirty } = this.state;

    if (fileAdded) {
      return fileUploaded;
    }
    return RTEDirty;
  };

  onRTEditorChange = (newValue) => {
    if (newValue !== this.props.defaultValue) {
      this.setState({
        RTEDirty: true,
      });
    }
    this.setState({
      value: newValue,
    });
  };

  onConfirm = () => {
    const { onConfirm, onClose, defaultValue } = this.props;
    if (defaultValue !== this.state.value) onConfirm(this.state.value);
    onClose();
  };

  onShowPreviewToggle = () => {
    const { showPreview } = this.state;
    this.setState({
      showPreview: !showPreview,
    });
  };

  onUploadFile = ({ file, onProgressChange, errorCallback, cancelToken }) => {
    const { onSubmitFile, files, getElement } = this.props;
    this.setPreventLeave(true);
    this.setState(
      {
        isUploading: true,
      },
      () => {
        onSubmitFile({
          file,
          onProgressChange,
          errorCallback: (err) => {
            errorCallback(err);
            this.setPreventLeave(false);
            this.setState({
              isUploading: false,
            });
          },
          currentFiles: files,
          cancelToken,
          successCallback: () => {
            this.getElementUntilFilePresent();
            return;

            this.setState({
              fileUploaded: true,
              isUploading: false,
            });
            this.setPreventLeave(false);
          },
        });
      }
    );
  };

  setPreventLeave = (preventLeave) => {
    if (preventLeave) {
      window.onbeforeunload = (e) => {
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = localeLookup(
          'translations.Upload has not finished, are you sure you want to leave?'
        );
        return localeLookup(
          'translations.Upload has not finished, are you sure you want to leave?'
        );
      };
    } else {
      window.onbeforeunload = null;
    }
  };

  submitDescription = () => {
    const { onConfirm, defaultValue } = this.props;
    const { value } = this.state;
    if (value !== defaultValue) {
      onConfirm(value);
    }
  };

  render() {
    const {
      disableUpload,
      elementId,
      files,
      onDeleteFile,
      onGetFilesProgress,
      onSubmitFile,
      subtitle,
      title,
    } = this.props;

    const { isUploading, showPreview, value } = this.state;

    return (
      <ModalWrapper className="modal-content">
        <ModalHeader title={title} subtitle={subtitle} />
        <ModalBody>
          {(!files || files.length === 0) &&
            onSubmitFile &&
            !disableUpload &&
            !showPreview && (
              <FileUploadBox
                onFileChange={() => {
                  this.setState({ fileAdded: true });
                }}
                onFileRemove={() => {
                  this.setState({ fileAdded: false });
                }}
                onSubmit={this.onUploadFile}
              />
            )}
          {files &&
            files.length > 0 &&
            files.map((file, index) => (
              <FileHandler
                key={index}
                elementId={elementId}
                canDelete={!disableUpload && !showPreview}
                file={file}
                onDeleteFile={onDeleteFile}
                onGetFilesProgress={onGetFilesProgress}
              />
            ))}
          {showPreview ? (
            <div className="rich-text-preview">
              <ElementDescription description={value} fullWidth />
            </div>
          ) : (
            <RTEditor
              autoFocus
              onBlur={this.submitDescription}
              onChange={this.onRTEditorChange}
              defaultValue={value}
            />
          )}
          {/* This is not proper BEM structure.. i know */}
          <Button
            className="rich-text-preview__button"
            onClick={this.onShowPreviewToggle}
            kind="link-style"
          >
            {showPreview
              ? localeLookup('translations.Hide preview')
              : localeLookup('translations.Preview description')}
          </Button>
          {showPreview && (
            <BoxMessage icon="file-search">
              {localeLookup(
                'translations.Please note that this is a preview, click the button above to exit preview'
              )}
            </BoxMessage>
          )}
        </ModalBody>
        <ModalFooter
          onCancelClick={this.onConfirm}
          cancelButtonText={localeLookup('translations.Close')}
          cancelButtonKind="darkui"
          cancelDisabled={isUploading}
        />
      </ModalWrapper>
    );
  }
}

export default connect(mapStateToProps)(RTEditorModal);
