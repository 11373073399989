import axios from 'axios';
import { ChampUserManager, store } from '../../index';
import { errorNotification } from '../actions/notificationActions';
import localeLookup from '../config/locale';
import { AUTH_ENDPOINT } from '../constants';
import { getCookie } from '../utils/helpers';

axios.interceptors.request.use((config) => {
  const CSRFToken = getCookie('X-CSRF-TOKEN');
  config.headers['X-CSRF-TOKEN'] = CSRFToken;
  const jsonToken = sessionStorage.getItem(
    `oidc.user:${AUTH_ENDPOINT()}:champ`
  );
  if (!jsonToken) return config;
  const { user } = store.getState();
  if (user?.id !== '') {
    const tenantId = localStorage.getItem(`tenantId_${user.id}`);
    if (tenantId && !config.url.includes(AUTH_ENDPOINT())) {
      config.headers.TenantId = tenantId;
    }
  }
  const token = JSON.parse(jsonToken)?.access_token;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.setItem('post_login_url', window.location.href);
      ChampUserManager.signinRedirect();
    }
    if (
      error.response?.status === 403 &&
      error.response?.data.name === 'NoAccess'
    ) {
      window.location = `/error?message=${error.response.data.reason}&type=${error.response.data.name}`;
      return Promise.reject(error);
    }
    if (error.response?.status >= 500) {
      store.dispatch(
        errorNotification({
          title: localeLookup('translations.An error occurred'),
          position: 'tr',
          autoDismiss: 5,
        })
      );
    }
    return Promise.reject(error);
  }
);

export const axiosRequestGET = (url, data, options = {}) => {
  const request = {
    url,
    type: 'GET',
    cache: false,
    params: data,
    headers: { Pragma: 'no-cache' },
    withCredentials: true,
    ...options,
  };
  return axios.get(url, request);
};

export const axiosRequestPOST = (url, data, options = {}) => {
  const request = {
    url,
    method: 'post',
    cache: false,
    data,
    headers: { Pragma: 'no-cache' },
    ...options,
  };
  return axios(request);
};

export const axiosRequestDELETE = (url, data) => {
  const request = {
    url,
    method: 'delete',
    cache: false,
    data,
    headers: { Pragma: 'no-cache' },
  };
  return axios(request);
};

export const axiosRequestPUT = (url, data) => {
  const request = {
    url,
    method: 'put',
    cache: false,
    data,
    headers: { Pragma: 'no-cache' },
  };
  return axios(request);
};
