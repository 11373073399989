import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import localeLookup from '../config/locale';
import { MAIN_STATUS_CATEGORIES, PENDING_STATES } from '../constants';
import KnowledgeElementContentButton from './KnowledgeElementContentButton';
import PersonWrapper from './PersonWrapper';
// Components
import StatusButton from './StatusButton';
import Text from './Text';
import Tooltip from './Tooltip';

class KnowledgeElement extends Component {
  onClickShowDescription = (e) => {
    e.stopPropagation();
    const { showModal, description, files, name, id } = this.props;
    const descriptionLength =
      description && description.replace(/<(?:.|\n)*?>/gm, '').trim().length;
    if (descriptionLength > 0 || (files && files.length > 0)) {
      showModal('richText', {
        title: name,
        description,
        files,
        fullWidth: true,
        maxWidth: '700px',
        elementId: id,
      });
    }
  };

  getElementTypeText = () => {
    const { isAdditionalKnowledge, isNewKnowledge, isRequired } = this.props;

    if (isNewKnowledge && !isAdditionalKnowledge) {
      return localeLookup('translations.New');
    }
    if (isRequired) {
      return localeLookup('translations.Critical');
    }

    return '';
  };

  renderContentButton = () => {
    const { description, files } = this.props;
    return (
      <KnowledgeElementContentButton
        files={files}
        description={description}
        onClick={this.onClickShowDescription}
      />
    );
  };

  renderStatusButton = () => {
    const {
      name,
      nextAction,
      hasHistory,
      hasValidity,
      areaId,
      pending,
      onUpdateElement,
      daysUntilExpiration,
      id,
      isReadOnly,
      status,
      personId,
      persons,
      mediatorId,
    } = this.props;
    if (isReadOnly) return null;
    const personName = persons[personId]?.name;
    return (
      <StatusButton
        mediatorId={mediatorId}
        areaId={areaId}
        daysUntilExpiration={daysUntilExpiration}
        elementId={id}
        elementName={name}
        hasHistory={hasHistory}
        hasValidity={hasValidity}
        nextAction={nextAction}
        onChangeStatus={onUpdateElement}
        pending={pending}
        personId={personId}
        personName={personName}
        size="large"
        status={status}
      />
    );
  };

  renderSubtitle = () => {
    const {
      personId,
      persons,
      pending,
      currentUserId,
      mediatorId,
      isTrainingTask,
      isReadOnly,
      lockState,
    } = this.props;
    if (isReadOnly) return null;
    const person = persons[personId];
    const traineeText = person?.name;
    const traineeTooltip = `${person.initials} ${
      person.employeeNumber ? `· ${person.employeeNumber}` : ''
    }`;

    if (pending !== PENDING_STATES.NONE) {
      if (personId !== currentUserId) {
        return (
          <>
            <PersonWrapper
              id={mediatorId}
              lockState={lockState}
              isTrainingTask={isTrainingTask}
              render={({ name, color, tooltip }) => (
                <Text as="span" color={color}>
                  <Tooltip tooltip={tooltip}>
                    <span>{name}</span>
                  </Tooltip>
                </Text>
              )}
            />{' '}
            <Text as="span" size="sm">
              &{' '}
              <Tooltip tooltip={traineeTooltip}>
                <span>{traineeText}</span>
              </Tooltip>
            </Text>
          </>
        );
      }
      return (
        <>
          <Text as="span" size="sm">
            <Tooltip tooltip={traineeTooltip}>
              <span>{traineeText}</span>
            </Tooltip>{' '}
            &
          </Text>{' '}
          <PersonWrapper
            id={mediatorId}
            lockState={lockState}
            isTrainingTask={isTrainingTask}
            render={({ name, color, tooltip }) => (
              <Text as="span" color={color}>
                <Tooltip tooltip={tooltip}>
                  <span>{name}</span>
                </Tooltip>
              </Text>
            )}
          />
        </>
      );
    }
    return (
      <PersonWrapper
        id={mediatorId}
        lockState={lockState}
        isTrainingTask={isTrainingTask}
        render={({ name, color, tooltip }) => (
          <Text as="span" color={color}>
            <Tooltip tooltip={tooltip}>
              <span>{name}</span>
            </Tooltip>
          </Text>
        )}
      />
    );
  };

  render() {
    const {
      showTopBorder,
      showNotification,
      name,
      isTrainingTask,
      status,
      isReadOnly,
      isAdditionalKnowledge,
      isNewKnowledge,
      isRequired,
    } = this.props;

    const elementClasses = cx({
      'knowledge-element--top-border': showTopBorder,
      'knowledge-element--highlight': showNotification,
      'knowledge-element--task': isTrainingTask,
      'knowledge-element--valid':
        (!isReadOnly &&
          status.startsWith(MAIN_STATUS_CATEGORIES.VALID_COMPLETION)) ||
        isAdditionalKnowledge,
    });

    const elementTypeText = this.getElementTypeText();
    console.log(elementTypeText);
    return (
      <div className={`knowledge-element ${elementClasses}`}>
        {isRequired && <div className="knowledge-element__highlight"></div>}
        <div className="knowledge-element__info">
          {isNewKnowledge && (
            <p className="knowledge-element__info-meta">
              {localeLookup('translations.New')}
            </p>
          )}
          <p className="knowledge-element__info-name">{name}</p>
          <p className="knowledge-element__info-subtitle">
            {this.renderSubtitle()}
          </p>
        </div>
        {this.renderContentButton()}
        {this.renderStatusButton()}
      </div>
    );
  }
}

KnowledgeElement.defaultProps = {
  showTopBorder: false,
};

KnowledgeElement.propTypes = {
  areaId: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  daysUntilExpiration: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  experts: PropTypes.arrayOf(PropTypes.string).isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      type: PropTypes.string,
      state: PropTypes.string,
      url: PropTypes.string,
      progress: PropTypes.number,
      minutesRemaining: PropTypes.number,
    })
  ).isRequired,
  id: PropTypes.string.isRequired,
  isAdditionalKnowledge: PropTypes.bool.isRequired,
  isNewKnowledge: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isTrainingTask: PropTypes.bool.isRequired,
  knowledgeOwnerId: PropTypes.string.isRequired,
  lockState: PropTypes.string.isRequired,
  mediatorId: PropTypes.string.isRequired,
  mentorId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  nextAction: PropTypes.string.isRequired,
  onUpdateElement: PropTypes.func.isRequired,
  pending: PropTypes.string.isRequired,
  personId: PropTypes.string.isRequired,
  persons: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    initials: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  roleId: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  showNotification: PropTypes.bool.isRequired,
  showTopBorder: PropTypes.bool,
  status: PropTypes.string.isRequired,
  wildcardPersons: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  mediatorType: PropTypes.string.isRequired,
};
export default KnowledgeElement;
