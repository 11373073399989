import React, { useEffect, useState } from 'react';
import { axiosRequestGET } from '../../services/serviceConfig';

const Image = ({ url }) => {
  const [imageUrl, setUrl] = useState('');

  useEffect(() => {
    // Your code here
    axiosRequestGET(url, null, {
      responseType: 'blob',
    }).then((response) => {
      // create file link in browser's memory
      const url = URL.createObjectURL(response.data);
      setUrl(url);
    });
  }, []);
  if (!imageUrl) return null;
  return (
    <div className="image">
      <a href={imageUrl} target="_blank" rel="noopener noreferrer">
        <img className="image__img" src={imageUrl} alt="Description" />
      </a>
    </div>
  );
};

export default Image;
