import React, { Component } from 'react';

// Components
import ButtonIcon from '../ButtonIcon';
import ModalBody from './ModalBody';
import ModalWrapper from './ModalWrapper';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import Input from '../formElements/Input';
import localeLookup from '../../config/locale';
import Text from '../Text';
import BoxMessage from '../BoxMessage';

class ConfirmationModal extends Component {
  constructor() {
    super();
    this.state = {
      safeWordValue: '',
      isSubmitting: false,
    };
    this.isConfirmDisabled = this.isConfirmDisabled.bind(this);
  }

  isConfirmDisabled() {
    const { safeWord } = this.props;
    const { safeWordValue, isSubmitting } = this.state;
    if (safeWord) {
      const safeWordLC = safeWord.toLowerCase();
      const safeWordValueLC = safeWordValue.toLowerCase();
      return safeWordValueLC !== safeWordLC || isSubmitting;
    }
    return isSubmitting;
  }

  render() {
    const {
      body,
      btnConfirmKind = 'darkui',
      btnConfirmTitle = 'Ok',
      btnRejectTitle = localeLookup('translations.Cancel'),
      cancel = true,
      onCancel,
      onClose,
      onConfirm,
      subtitle,
      title,
      safeWord,
      infoText,
      dismissable,
      skipCloseOnConfirm,
      infoTextType,
      infoTextIcon,
    } = this.props;

    const onConfirmHandler = () => {
      if (this.isConfirmDisabled()) return;
      this.setState({ isSubmitting: true });
      /* if (onCancel) onCancel(); */
      if (onConfirm) onConfirm();
      if (!skipCloseOnConfirm) onClose();
    };

    const onCancelHandler = () => {
      if (onCancel) onCancel();
    };
    return (
      <ModalWrapper>
        <ModalHeader
          title={title}
          subtitle={subtitle}
          onClose={onClose}
          dismissable={dismissable}
        />
        {infoText && (
          <BoxMessage
            type={infoTextType}
            spacing="md"
            icon={infoTextIcon || 'info-circle'}
          >
            {infoText}
          </BoxMessage>
        )}
        <ModalBody noBottomSpacing={safeWord?.length > 1}>{body}</ModalBody>
        {safeWord && (
          <div className="modal-content__confirm-field">
            <Input
              autoFocus
              onEnterPress={onConfirmHandler}
              onChange={(e) => this.setState({ safeWordValue: e.target.value })}
            />
            <Text size="sm" color="dark-grey">{`${localeLookup(
              'translations.Type in'
            )} "${safeWord}" ${localeLookup(
              'translations.to continue'
            )}`}</Text>
          </div>
        )}
        <ModalFooter
          onCancelClick={onCancelHandler}
          cancelButtonText={btnRejectTitle}
          confirmButtonText={btnConfirmTitle}
          confirmButtonKind={btnConfirmKind}
          onConfirmClick={onConfirmHandler}
          confirmDisabled={this.isConfirmDisabled()}
        />
      </ModalWrapper>
    );
  }
}

export default ConfirmationModal;
