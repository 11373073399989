import React from 'react';
import localeLookup from '../../config/locale';
import { getElementContentIcon } from '../../utils/elementHelpers';
import WithModals from '../HOC/withModals';
import Icon from '../Icon';
import Text from '../Text';
import DashboardSidebarItem from './DashboardSidebarItem';
import DashboardSidebarList from './DashboardSidebarList';

const KnowledgeDashboardSidebar = ({ knowledgeElementsSortOrder, knowledgeElements, areaName, showModal }) => {
  const renderKnowledgeElement = (id) => {
    const element = knowledgeElements[id];
    const icon = getElementContentIcon({ description: element.description, files: element.files });
    const onClickShowDescription = () =>
      showModal('richText', {
        title: element.name,
        description: element.description,
        files: element.files,
        fullWidth: true,
        maxWidth: '700px',
        elementId: element.id,
      });
    const onClick = icon ? onClickShowDescription : null;
    if (element) {
      return (
        <DashboardSidebarItem
          key={id}
          highlight={element.isRequired}
          onClick={onClick}
          title={element.name}
          rightComponent={icon ? <Icon color="grey" size="small" kind={icon} /> : null}
        />
      );
    }
  };

  const showEmptyState = knowledgeElementsSortOrder.length === 0;

  return (
    <div className="dashboard-sidebar">
      <DashboardSidebarList>
        <DashboardSidebarItem sticky title={areaName} icon="book2">
          <DashboardSidebarList nested>{knowledgeElementsSortOrder.map(renderKnowledgeElement)}</DashboardSidebarList>
        </DashboardSidebarItem>
        {showEmptyState && (
          <Text className="dashboard-sidebar__empty-text" size="sm" color="dark-grey">
            {localeLookup('translations.No elements has been added to this module')}
          </Text>
        )}
      </DashboardSidebarList>
    </div>
  );
};

export default WithModals(KnowledgeDashboardSidebar);
