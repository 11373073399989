import PropTypes from 'prop-types';
import React from 'react';
import localeLookup from '../../config/locale';
import { getElementContentIcon } from '../../utils/elementHelpers';
import WithModals from '../HOC/withModals';
import Icon from '../Icon';
import Text from '../Text';
import DashboardSidebarItem from './DashboardSidebarItem';
import DashboardSidebarList from './DashboardSidebarList';

const RoleDashboardSidebar = ({
  roleName,
  knowledgeAreas,
  roleKnowledgeAreas,
  knowledgeAreasSortOrder,
  additionalKnowledgeAreasSortOrder,
  knowledgeElements,
  showModal,
  expandedAreaId,
  onClickExpandArea,
}) => {
  const renderKnowledgeElement = (id) => {
    const element = knowledgeElements[id];
    const icon = getElementContentIcon({
      description: element.description,
      files: element.files,
    });
    const onClickShowDescription = () =>
      showModal('richText', {
        title: element.name,
        description: element.description,
        files: element.files,
        fullWidth: true,
        maxWidth: '700px',
        elementId: element.id,
      });
    const onClick = icon ? onClickShowDescription : null;
    if (element) {
      return (
        <DashboardSidebarItem
          key={id}
          highlight={element.isRequired}
          onClick={onClick}
          title={element.name}
          rightComponent={
            icon ? <Icon color="grey" size="small" kind={icon} /> : null
          }
        />
      );
    }
  };

  const renderKnowledgeArea = (id) => {
    const area = knowledgeAreas[id];
    const roleArea = roleKnowledgeAreas[id];
    const hasAreaElements = roleArea.knowledgeElements?.length > 0;
    const isAreaExpanded = hasAreaElements && expandedAreaId === id;
    const onClick = hasAreaElements ? () => onClickExpandArea(id) : null;
    if (area && roleArea) {
      return (
        <DashboardSidebarItem
          onClick={onClick}
          key={id}
          icon="book2"
          title={area.name}
          rightComponent={
            hasAreaElements ? (
              <Icon
                color="grey"
                size="x-small"
                kind={isAreaExpanded ? 'chevron-up' : 'chevron-down'}
              />
            ) : null
          }
        >
          {isAreaExpanded && (
            <DashboardSidebarList nested>
              {roleArea.knowledgeElements.map(renderKnowledgeElement)}
            </DashboardSidebarList>
          )}
        </DashboardSidebarItem>
      );
    }
    return null;
  };

  const showEmptyState =
    knowledgeAreasSortOrder.length === 0 &&
    additionalKnowledgeAreasSortOrder.length === 0;

  return (
    <div className="dashboard-sidebar">
      <DashboardSidebarList>
        <DashboardSidebarItem sticky title={roleName} icon="profile">
          <DashboardSidebarList nested>
            {knowledgeAreasSortOrder.map(renderKnowledgeArea)}
          </DashboardSidebarList>
          {additionalKnowledgeAreasSortOrder.length > 0 && (
            <Text
              className="dashboard-sidebar__section-title"
              size="sm"
              upperCase
              color="dark-grey"
            >
              {localeLookup('translations.Additional knowledge')}
            </Text>
          )}
          <DashboardSidebarList dashedBorders nested>
            {additionalKnowledgeAreasSortOrder.map(renderKnowledgeArea)}
          </DashboardSidebarList>
        </DashboardSidebarItem>
        {showEmptyState && (
          <Text
            className="dashboard-sidebar__empty-text"
            size="sm"
            color="dark-grey"
          >
            {localeLookup(
              'translations.No knowledge has been added to this role'
            )}
          </Text>
        )}
      </DashboardSidebarList>
    </div>
  );
};

RoleDashboardSidebar.propTypes = {
  roleName: PropTypes.string.isRequired,
  knowledgeAreas: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  roleKnowledgeAreas: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      knowledgeElements: PropTypes.arrayOf(PropTypes.string).isRequired,
      knowledgeOwner: PropTypes.string.isRequired,
      experts: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  knowledgeAreasSortOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  additionalKnowledgeAreasSortOrder: PropTypes.arrayOf(PropTypes.string)
    .isRequired,
  knowledgeElements: PropTypes.objectOf(
    PropTypes.shape({
      approvers: PropTypes.arrayOf(PropTypes.string).isRequired,
      champLink: PropTypes.shape({
        link: PropTypes.string.isRequired,
        isPublic: PropTypes.bool.isRequired,
      }).isRequired,
      completedAt: PropTypes.string.isRequired,
      completionRequirement: PropTypes.string.isRequired,
      completionType: PropTypes.string.isRequired,
      connectedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
      connections: PropTypes.shape({
        knowledgeArea: PropTypes.string.isRequired,
        knowledgeElementId: PropTypes.string.isRequired,
        organisationUnits: PropTypes.arrayOf(PropTypes.string).isRequired,
        roles: PropTypes.arrayOf(PropTypes.string).isRequired,
      }),
      daysUntilExpiration: PropTypes.number.isRequired,
      defaultMediator: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          fileName: PropTypes.string,
          type: PropTypes.string,
          state: PropTypes.string,
          url: PropTypes.string,
          progress: PropTypes.number,
          minutesRemaining: PropTypes.number,
        })
      ).isRequired,
      hasHistory: PropTypes.bool.isRequired,
      hasValidity: PropTypes.bool.isRequired,
      id: PropTypes.string.isRequired,
      isConnected: PropTypes.bool.isRequired,
      isNewKnowledge: PropTypes.bool.isRequired,
      isRequired: PropTypes.bool.isRequired,
      isTrainingTask: PropTypes.bool.isRequired,
      links: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      lockedApprover: PropTypes.bool.isRequired,
      mediator: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      nextAction: PropTypes.string.isRequired,
      pending: PropTypes.string.isRequired,
      roleConnections: PropTypes.shape({}).isRequired,
      showNotification: PropTypes.bool.isRequired,
      signedById: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      validity: PropTypes.shape({
        enabled: PropTypes.bool.isRequired,
        validPeriod: PropTypes.number.isRequired,
      }).isRequired,
    })
  ).isRequired,
  showModal: PropTypes.func.isRequired,
  expandedAreaId: PropTypes.string.isRequired,
  onClickExpandArea: PropTypes.func.isRequired,
};

export default WithModals(RoleDashboardSidebar);
