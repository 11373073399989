/* eslint-disable class-methods-use-this */
import React from 'react';
import ProgressDiagram from '../ProgressDiagram';
import Counter from '../Counter';
import OrganisationStatusboxProgressBar from './OrganisationStatusboxProgressBar';
import Loader from '../Loader';
import localeLookup from '../../config/locale';
import Checkbox from '../Checkbox';

class OrganisationBoardGoal extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      isHidden: true,
    };
  }

  getIconModifierClass = () => {
    const { goals } = this.props;

    const hasNoGoals = () => goals.every((goal) => goal.objective === 0);
    const hasGaps = () => goals.some((goal) => goal.gap > 0);
    const isAchievingGoals = () =>
      goals.some(
        (goal) =>
          goal.done < goal.objective &&
          goal.done + goal.ongoing >= goal.objective
      );

    if (hasNoGoals()) {
      return '';
    }
    if (hasGaps()) {
      return 'organisation-board-goal__indicator--red';
    }
    if (isAchievingGoals()) {
      return 'organisation-board-goal__indicator--yellow';
    }
    return 'organisation-board-goal__indicator--green';
  };

  onBlur = (e) => {
    const { currentTarget } = e;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.setState({ isHidden: true });
      }
    }, 0);
  };

  onChangeAllInOrganisationUnit = (e, index) => {
    const { goals, onUpdateGoals } = this.props;
    const { checked } = e.target;
    this.setState({ isLoading: true });
    const goalsCopy = JSON.parse(JSON.stringify(goals));
    if (checked) {
      goalsCopy[index].isAllObjective = true;
      onUpdateGoals(goalsCopy).then(() => {
        this.setState({ isLoading: false });
      });
    } else {
      goalsCopy[index].isAllObjective = false;
      onUpdateGoals(goalsCopy).then(() => {
        this.setState({ isLoading: false });
      });
    }
  };

  // Toggle the visibility
  toggleHidden = () => {
    const { isHidden } = this.state;
    this.setState({
      isHidden: !isHidden,
    });
  };

  updateGoals = (value, index) => {
    const { goals, onUpdateGoals } = this.props;
    this.setState({ isLoading: true });
    const goalsCopy = JSON.parse(JSON.stringify(goals));
    goalsCopy[index].objective = value;
    onUpdateGoals(goalsCopy).then(() => {
      this.setState({ isLoading: false });
    });
  };

  renderDiagram = (goal) => {
    const diagramValues = () => {
      return [
        {
          color: 'green',
          value: goal.done,
        },
        {
          color: 'yellow',
          value: goal.ongoingGap,
        },
        {
          color: 'light-green',
          value: goal.ongoingRemainder,
        },
        {
          color: 'red',
          value: goal.gap,
        },
      ];
    };

    const diagramCompletionPercentage = () => {
      if (goal.gap > 0) {
        return 100;
      }
      if (goal.ongoingGap > 0 && goal.ongoingRemainder === 0) {
        return 100;
      }
      if (goal.objective === 0) {
        return 0;
      }
      return (
        (goal.objective /
          (goal.done + goal.ongoingGap + goal.ongoingRemainder)) *
        100
      ).toFixed(2);
    };

    if (
      goal.objective === 0 &&
      goal.done === 0 &&
      goal.ongoingRemainder === 0
    ) {
      return (
        <div className="organisation-board-goal__no-goal">
          <span className="organisation-board-goal__no-goal-text">0</span>
        </div>
      );
    }

    return (
      <ProgressDiagram
        completionPercentage={diagramCompletionPercentage()}
        diagramValues={diagramValues()}
      />
    );
  };

  render() {
    const { completionPercentage, goals } = this.props;
    const { isHidden, isLoading } = this.state;
    if (!goals) return null;
    const roleHasNoGoals = goals?.every((goal) => goal.objective === 0);
    return (
      <div
        className="organisation-board-goal"
        tabIndex="1"
        onBlur={this.onBlur}
      >
        <div
          className={`organisation-board-goal__indicator ${this.getIconModifierClass()}`}
          onClick={this.toggleHidden}
        >
          {!roleHasNoGoals && (
            <div className="organisation-board-goal__indicator-inner">
              <span className="organisation-board-goal__indicator-percentage">
                {completionPercentage}%
              </span>
              <OrganisationStatusboxProgressBar
                completionPercentage={completionPercentage}
              />
            </div>
          )}
          {roleHasNoGoals && (
            <span className="organisation-board-goal__indicator-dash">–</span>
          )}
        </div>
        {!isHidden && (
          <div className="organisation-board-goal__box">
            {isLoading && (
              <div className="organisation-board-goal__load-overlay">
                <Loader />
              </div>
            )}
            {goals.map((goal, i) => {
              return (
                <div key={i}>
                  {goal.name === 'TrainedGoal' ? (
                    <p className="organisation-board-goal__text">
                      {localeLookup('translations.TrainedGoal')}
                    </p>
                  ) : (
                    <p className="organisation-board-goal__text">
                      {localeLookup('translations.ExperiencedGoal')}
                    </p>
                  )}
                  {this.renderDiagram(goal)}
                  <div className="organisation-board-goal__counter">
                    <span>{localeLookup('translations.Goal')}:</span>
                    <Counter
                      disabled={goal.isAllObjective}
                      disableInput={isLoading || goal.isAllObjective}
                      handleChange={(value) => {
                        this.updateGoals(value, i);
                      }}
                      value={goal.objective}
                    />
                  </div>
                  <Checkbox
                    className="organisation-board-goal__checkbox"
                    labelPosition="right"
                    isChecked={goal.isAllObjective}
                    label={localeLookup(
                      'translations.All in organisation unit'
                    )}
                    id={`${goal.name}allInOrganisationUnit`}
                    name={`${goal.name}allInOrganisationUnit`}
                    onChange={(e) => this.onChangeAllInOrganisationUnit(e, i)}
                  />
                  <hr className="organisation-board-goal__line" />
                </div>
              );
            })}

            <div className="organisation-board-goal__legend">
              <div className="organisation-board-goal__legend-text">
                <div className="organisation-board-goal__legend-icon organisation-board-goal__legend-icon--green" />
                <p>{localeLookup('translations.Meets criteria')}</p>
              </div>
              <div className="organisation-board-goal__legend-text">
                <div className="organisation-board-goal__legend-icon organisation-board-goal__legend-icon--half organisation-board-goal__legend-icon--yellow" />
                <div className="organisation-board-goal__legend-icon organisation-board-goal__legend-icon--half organisation-board-goal__legend-icon--light-green" />
                <p>{localeLookup('translations.Under training')}</p>
              </div>
              <div className="organisation-board-goal__legend-text">
                <div className="organisation-board-goal__legend-icon organisation-board-goal__legend-icon--red" />
                <p>{localeLookup('translations.Deviation')}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default OrganisationBoardGoal;
