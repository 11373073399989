import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  systemHideLoadOverlay,
  systemShowLoadOverlay,
} from '../../actions/systemActions';
import localeLookup from '../../config/locale';
import { ACCESS_LEVELS, EMPTY_ID } from '../../constants';
import {
  connectAreasToRolesService,
  connectElementsToRolesService,
  connectRolesToOrganisationUnitsService,
  connectSpacesToModulesService,
  createElementService,
  deleteAreaService,
  deleteElementFileService,
  deleteElementService,
  deleteRoleService,
  deleteSpaceService,
  disconnectAreasFromRolesService,
  disconnectElementsFromRolesService,
  disconnectRolesFromOrganisationUnitsService,
  disconnectSpacesFromModulesService,
  finalizeElementFileUpload,
  getElementFileConversionProgress,
  getElementRelocationRelationsService,
  getElementUploadAccess,
  updateAreaCategoryService,
  updateAreaChampLinkVisiblityService,
  updateAreaEditorsService,
  updateAreaElementsOrderService,
  updateAreaExpertsService,
  updateAreaNameService,
  updateAreaOwnerService,
  updateElementAreaLocationService,
  updateElementDescriptionService,
  updateElementExpirationService,
  updateElementLinkVisibilityService,
  updateElementNameService,
  updateElementTypeService,
  updateRoleAreasOrderService,
  updateRoleDescriptionService,
  updateRoleEditorsService,
  updateRoleNameService,
  updateRoleOwnerService,
  updateRoleSpaceService,
  updateSpaceAdministratorsService,
  updateSpaceCreateModulePermissionsService,
  updateSpaceCreateRolePermissionsService,
  updateSpaceNameService,
  uploadFileToElementService,
} from '../../services/contentService';
import { updateOrganisationUnitPersonAdministratorsService } from '../../services/organisationService';
import {
  addPersonPermissionsService,
  getPersonsWithAccessLevelsService,
  removePersonPermissionsService,
} from '../../services/personsService';
import {
  queryAreas,
  removeArea,
  updateAreaElementsOrder,
} from '../../slices/areasSlice';
import { getAreasAccess, getRolesAccess } from '../../slices/editorSlice';
import { queryElements, removeElement } from '../../slices/elementsSlice';
import { getAllOrganisationUnits } from '../../slices/organisationUnitsSlice';
import { selectActivePersonsSortOrder } from '../../slices/personsSlice';
import {
  queryRoles,
  removeRole,
  updateRoleAreasOrder,
} from '../../slices/rolesSlice';
import { getAllSpaces, removeSpace } from '../../slices/spaceSlice';
import {
  compareLocal,
  createFormData,
  moveArrayItemToIndex,
  sortBy,
} from '../../utils/helpers';
import RoleUsageInfo from '../editor/RoleUsageInfo';
import OrganisationUnitsText from '../OrganisationUnitsText';
import SimpleTable from '../SimpleTable';
import withAccessControl from './withAccessControl';
import WithModals from './withModals';
const mapStateToProps = (state) => {
  const {
    roles,
    areas,
    persons,
    user,
    organisationUnits,
    elements,
    wildcardPersons,
    categories,
    editor,
    spaces,
  } = state;
  return {
    activeSpaceId: spaces.activeSpaceId,
    roles,
    areas,
    categories,
    persons,
    activePersonsSortOrder: selectActivePersonsSortOrder(state),
    currentUserId: user.employeeId,
    organisationUnits,
    elements,
    wildcardPersons,
    accessibleAreas: editor.accessibleAreas,
    accessibleRoles: editor.accessibleRoles,
    spaces: spaces.spaces,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getAllSpaces,
      removeRole,
      systemHideLoadOverlay,
      systemShowLoadOverlay,
      getAreasAccess,
      getRolesAccess,
      queryAreas,
      queryRoles,
      queryElements,
      removeElement,
      removeArea,
      updateAreaElementsOrder,
      updateRoleAreasOrder,
      removeSpace,
      getAllOrganisationUnits,
    },
    dispatch
  ),
});

const WithEditorActions = (WrappedComponent) => {
  class WithEditorActionsComponent extends React.Component {
    state = {};

    getAreas = (ids = []) => {
      const { queryAreas } = this.props;
      return queryAreas(ids);
    };

    getRole = (id = '') => {
      const { queryRoles } = this.props;
      return queryRoles([id]);
    };

    getRoles = (ids = []) => {
      const { queryRoles } = this.props;
      return queryRoles(ids);
    };

    getSpaces = () => {
      const { getAllSpaces } = this.props;
      return getAllSpaces();
    };

    changeAreaElementsOrder = ({ areaId, index, moveToIndex }) => {
      const { areas, updateAreaElementsOrder } = this.props;
      const area = areas[areaId];
      const newOrder = moveArrayItemToIndex({
        arr: area.knowledgeElements,
        currentIndex: index,
        newIndex: moveToIndex,
      });
      // Update state
      updateAreaElementsOrder(areaId, newOrder);
      // Persist order
      updateAreaElementsOrderService(areaId, newOrder);
    };

    changeAreaName = (id, value) => {
      return updateAreaNameService(id, value).then(() => {
        this.getAreas([id]);
      });
    };

    changeElementExpiration = ({ id, enabled, value }) => {
      const { queryElements } = this.props;
      updateElementExpirationService(id, enabled, value).then(() => {
        queryElements([id]);
      });
    };

    changeElementName = (id, value) => {
      const { queryElements } = this.props;
      updateElementNameService(id, value).then(() => {
        queryElements([id]);
      });
    };

    changeElementType = (id, value) => {
      const { queryElements } = this.props;
      updateElementTypeService(id, value).then(() => {
        queryElements([id]);
      });
    };

    changeRoleAreasOrder = ({ roleId, isAdditional, index, moveToIndex }) => {
      const { roles, updateRoleAreasOrder } = this.props;
      const role = roles[roleId];
      if (!isAdditional) {
        const newOrder = moveArrayItemToIndex({
          arr: role.areas,
          currentIndex: index,
          newIndex: moveToIndex,
        });
        // Update state
        updateRoleAreasOrder({
          id: roleId,
          areasOrder: newOrder,
          additionalAreasOrder: role.additionalAreas,
        });
        // Persist order
        updateRoleAreasOrderService(roleId, newOrder, role.additionalAreas);
      } else {
        const newOrder = moveArrayItemToIndex({
          arr: role.additionalAreas,
          currentIndex: index,
          newIndex: moveToIndex,
        });
        // Update state
        updateRoleAreasOrder({
          id: roleId,
          areasOrder: role.areas,
          additionalAreasOrder: newOrder,
        });
        // Persist order
        updateRoleAreasOrderService(roleId, role.areas, newOrder);
      }
    };

    changeRoleName = (id, value) => {
      updateRoleNameService(id, value).then(() => {
        this.getRole(id);
      });
    };

    changeSpaceName = (id, value) => {
      updateSpaceNameService(id, value).then(() => {
        this.getSpaces();
      });
    };

    connectAreasToSpaces = async ({
      areaIds = [],
      spaceIds = [],
      editable = false,
    }) => {
      const { getAreasAccess } = this.props;
      await connectSpacesToModulesService(spaceIds, areaIds, editable);
      return await Promise.all([
        this.getSpaces(),
        this.getAreas(areaIds),
        getAreasAccess(),
      ]);
    };

    disconnectAreasFromSpaces = async ({ areaIds = [], spaceIds = [] }) => {
      await disconnectSpacesFromModulesService(spaceIds, areaIds);
      return await Promise.all([this.getSpaces(), this.getAreas(areaIds)]);
    };

    connectElementsToRoles = ({ roleIds = [], elementIds = [] }) => {
      const { queryElements } = this.props;
      connectElementsToRolesService(roleIds, elementIds).then(() =>
        queryElements(elementIds).then(() => this.getRoles(roleIds))
      );
    };

    createElements = ({ names, areaId, roleId, connectToRole }) => {
      const { queryElements, systemHideLoadOverlay, systemShowLoadOverlay } =
        this.props;
      if (names.length > 1) systemShowLoadOverlay();
      createElementService(names, areaId).then(({ data: ids }) => {
        if (connectToRole) {
          connectElementsToRolesService([roleId], ids).then(() =>
            queryElements(ids)
              .then(() => this.getAreas([areaId]))
              .then(() => this.getRole(roleId))
              .then(() => systemHideLoadOverlay())
          );
        } else {
          queryElements(ids)
            .then(() => {
              this.getAreas([areaId]);
            })
            .then(() => systemHideLoadOverlay());
        }
      });
    };

    disconnectElementsFromRoles = ({ roleIds = [], elementIds = [] }) => {
      const { queryElements } = this.props;
      disconnectElementsFromRolesService(roleIds, elementIds).then(() =>
        queryElements(elementIds).then(() => this.getRoles(roleIds))
      );
    };

    disconnectAreasFromRoles = ({ roleIds = [], areaIds = [] }) => {
      return disconnectAreasFromRolesService(roleIds, areaIds).then(() => {
        this.getAreas(areaIds).then(() => {
          this.getRoles(roleIds);
        });
      });
    };

    showAreaScopeWarningModal = ({ onConfirm, onCancel }) => {
      const { showModal } = this.props;
      showModal('confirmation', {
        title: `${localeLookup('translations.Remove access')}`,
        maxWidth: '500px',
        body: `${localeLookup(
          'translations.You are about to remove your access to this module. Do you want to continue?'
        )}`,
        btnRejectTitle: localeLookup('translations.Cancel'),
        confirmButtonText: localeLookup('translations.Confirm'),
        confirmButtonType: 'alert',
        onCancel: onCancel,
        onConfirm: onConfirm,
      });
    };

    showChangeAreaCategoryModal = (areaId) => {
      const { showModal, areas } = this.props;

      showModal('assignCategory', {
        title: localeLookup('translations.Category'),
        subtitle: areas[areaId].name,
        fullWidth: true,
        maxWidth: '500px',
        selectedCategory: areas[areaId].category,
        onConfirm: (categoryId) => {
          updateAreaCategoryService(areaId, categoryId).then(() => {
            this.getAreas([areaId]);
          });
        },
      });
    };

    showChangeAreaEditorsModal = ({
      areaId,
      selectedValues,
      newlyCreatedUserIds = [],
      onChanged,
    }) => {
      const {
        areas,
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
      } = this.props;
      const area = areas[areaId];
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const selectedOptionIds = selectedValues || area.editors;

      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            if (newlyCreatedUserIds.includes(id)) {
              acc[0].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            } else if (area.editors?.includes(id)) {
              acc[1].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            } else {
              acc[2].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            }
            return acc;
          },
          [
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Current selection'),
              options: [],
            },
            { title: localeLookup('translations.Other persons'), options: [] },
          ]
        );
      const options = getSectionedOptions();

      showModal('checkList', {
        title: localeLookup('translations.Editors'),
        subtitle: area.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds,
        options,
        renderSectioned: true,
        onConfirm: (ids) => {
          hideModal();
          this.updateAreaEditors({ areaId, ids, onChanged });
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            initialName: filterString,
            onCreated: ({ id }) => {
              hideModal();
              this.showChangeAreaEditorsModal({
                areaId,
                selectedValues: selectedOptionIds,
                newlyCreatedUserIds: [...newlyCreatedUserIds, id],
              });
            },
          });
        },
      });
    };

    showChangeAreaExpertsModal = ({
      areaId,
      newlyCreatedUserIds = [],
      onChanged,
    }) => {
      const {
        areas,
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
      } = this.props;
      const area = areas[areaId];
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const renderSectioned =
        newlyCreatedUserIds?.length > 0 || area.experts.length > 0;
      const getFlatOptions = () =>
        activePersonsSortOrder.reduce((acc, id) => {
          const person = persons[id];
          return [
            ...acc,
            {
              title: person.name,
              id,
              checked: area.experts.includes(id),
              tooltip: `${person.initials} ${
                person.employeeNumber ? `· ${person.employeeNumber}` : ''
              }`,
              searchString: `${person.name}${person.employeeNumber}${person.initials}`,
            },
          ];
        }, []);

      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            if (newlyCreatedUserIds.includes(id)) {
              acc[0].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            } else if (area.experts.includes(id)) {
              acc[1].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            } else {
              acc[2].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            }
            return acc;
          },
          [
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Current selection'),
              options: [],
            },
            { title: localeLookup('translations.Other persons'), options: [] },
          ]
        );
      const options = renderSectioned
        ? getSectionedOptions()
        : getFlatOptions();

      showModal('checkList', {
        title: localeLookup('translations.Experts'),
        subtitle: area.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds: area.experts,
        options,
        renderSectioned,
        onConfirm: (expertIds) => {
          hideModal();
          this.updateAreaExperts({ areaId, expertIds, onChanged });
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            initialName: filterString,
            onCreated: ({ id }) => {
              hideModal();
              this.showChangeAreaExpertsModal({
                areaId,
                newlyCreatedUserIds: [...newlyCreatedUserIds, id],
                onChanged: onChanged,
              });
            },
          });
        },
      });
    };

    showChangeAreaOwnerModal = ({
      areaId,
      selectedValue,
      newlyCreatedUserIds = [],
      onChanged,
    }) => {
      const {
        areas,
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
      } = this.props;
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const defaultValue = selectedValue || areas[areaId].owner;
      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            if (defaultValue === id) {
              acc[0].options.push({
                label: person.name,
                value: id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
              return acc;
            }
            if (newlyCreatedUserIds.includes(id)) {
              acc[1].options.push({
                label: person.name,
                value: id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
              return acc;
            } else {
              acc[2].options.push({
                label: person.name,
                value: id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
              return acc;
            }
          },
          [
            {
              title: localeLookup('translations.Current selection'),
              options:
                defaultValue === EMPTY_ID
                  ? [
                      {
                        label: localeLookup('translations.No owner'),
                        value: EMPTY_ID,
                      },
                    ]
                  : [],
            },
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Other persons'),
              options:
                defaultValue !== EMPTY_ID
                  ? [
                      {
                        label: localeLookup('translations.No owner'),
                        value: EMPTY_ID,
                      },
                    ]
                  : [],
            },
          ]
        );

      showModal('radioButton', {
        title: localeLookup('translations.Owner'),
        subtitle: areas[areaId].name,
        fullWidth: true,
        maxWidth: '500px',
        grouped: true,
        options: getSectionedOptions(),
        selectedValue: defaultValue,
        onConfirm: (ownerId) => {
          this.updateAreaOwner({ areaId, ownerId, onChanged });
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: (filterString) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            fullWidth: false,
            initialName: filterString,
            onCreated: ({ id }) => {
              hideModal();
              this.showChangeAreaOwnerModal({
                areaId,
                selectedValue: defaultValue,
                newlyCreatedUserIds: [...newlyCreatedUserIds, id],
                onChanged,
              });
            },
          });
        },
      });
    };

    showChangeAreaNameModal = ({ areaId }) => {
      const { areas, hideModal, showModal } = this.props;
      const area = areas[areaId];
      showModal('nameModal', {
        title: localeLookup('translations.Rename'),
        defaultValue: area.name,
        confirmButtonText: localeLookup('translations.Rename'),
        onConfirm: ({ name }) => {
          this.changeAreaName(areaId, name);
          hideModal();
        },
      });
    };

    showChangeAreaRolesModal = ({ areaId, connectAsAdditional = false }) => {
      const {
        roles,
        areas,
        organisationUnits,
        hideModal,
        showModal,
        queryRoles,
        hasAccess,
        accessibleRoles,
        hasCreateRolePermission,
        getRolesAccess,
        queryElements,
      } = this.props;
      const area = areas[areaId];
      const areaSpaceIds = [
        ...Object.keys(area.readOnlyInSpaces),
        ...Object.keys(area.editableInSpaces),
      ];
      const connectedRoles = [
        ...area.attachedAsMandatoryToRoles,
        ...area.attachedAsAdditionalToRoles,
      ];
      const isAdmin = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ]);
      const showCreateRoleButton = hasCreateRolePermission({});
      const options = Object.values(roles).reduce((acc, role) => {
        const isInSpace = areaSpaceIds.includes(role.space);
        if (
          isInSpace &&
          !connectedRoles.includes(role.id) &&
          (isAdmin || accessibleRoles.includes(role.id))
        ) {
          return [
            ...acc,
            {
              title: role.name,
              searchString: `${role.name}${role.organisationUnits
                .map((id) => organisationUnits[id].name)
                .join('')}`,
              subtitle: (
                <OrganisationUnitsText
                  organisationUnitIds={role.organisationUnits}
                />
              ),
              id: role.id,
            },
          ];
        }
        return acc;
      }, []);

      showModal('checkList', {
        title: connectAsAdditional
          ? localeLookup('translations.Connect roles as additional knowledge')
          : localeLookup('translations.Connect roles'),
        subtitle: area.name,
        fullWidth: true,
        maxWidth: '500px',
        options,
        selectedOptionIds: [],
        showFilterButton: showCreateRoleButton,
        filterButtonText: localeLookup('translations.Create role'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createRole', {
            maxWidth: '900px',
            fullWidth: true,
            initialName: filterString,
            organisationUnits,
            onCreated: (id) => {
              hideModal();
              connectAreasToRolesService(
                [id],
                [area.id],
                connectAsAdditional
              ).then(() => {
                getRolesAccess().then(() => {
                  queryRoles([id]).then(() => {
                    this.getAreas([areaId]);
                    queryElements(area.knowledgeElements);
                  });
                });
              });
            },
          });
        },
        onConfirm: (roleIds) => {
          hideModal();
          connectAreasToRolesService(
            roleIds,
            [area.id],
            connectAsAdditional
          ).then(() => {
            queryRoles(roleIds).then(() => {
              this.getAreas([areaId]);
              queryElements(area.knowledgeElements);
            });
          });
        },
      });
    };

    showChangeElementConnectionsModal = (id) => {
      const {
        showModal,
        hideModal,
        roles,
        elements,
        organisationUnits,
        queryElements,
        hasAccessToRole,
      } = this.props;
      const element = elements[id];
      const connectedRoleIds = Object.keys(element.roleConnections).filter(
        (id) => element.roleConnections[id]
      );
      const options = Object.keys(element.roleConnections).map((roleId) => {
        const role = roles[roleId];
        const hasAccess = hasAccessToRole(roleId);
        return {
          title: role.name,
          searchString: `${role.name}${role.organisationUnits
            .map((id) => organisationUnits[id].name)
            .join('')}`,
          subtitle: (
            <OrganisationUnitsText
              organisationUnitIds={role.organisationUnits}
            />
          ),
          id: role.id,
          disabled: !hasAccess,
        };
      });
      showModal('checkList', {
        title: localeLookup('translations.Connections'),
        subtitle: element.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds: connectedRoleIds,
        showToggleAll: true,
        options,
        onConfirm: (selectedIds) => {
          hideModal();
          const rolesToDisconnect = connectedRoleIds.filter(
            (id) => !selectedIds.includes(id)
          );
          const rolesToConnect = selectedIds.filter(
            (id) => !connectedRoleIds.includes(id)
          );
          let promises = [];
          if (rolesToDisconnect.length > 0) {
            promises = [
              ...promises,
              disconnectElementsFromRolesService(rolesToDisconnect, [id]),
            ];
          }
          if (rolesToConnect.length > 0) {
            promises = [
              ...promises,
              connectElementsToRolesService(rolesToConnect, [id]),
            ];
          }
          Promise.all(promises).then(() => {
            queryElements([id]);
            this.getRoles([...rolesToConnect, ...rolesToDisconnect]);
          });
        },
      });
    };

    showChangeElementDescriptionModal = (elementId) => {
      const { elements, showModal, updateModalProps, queryElements } =
        this.props;
      const element = elements[elementId];
      showModal('richTextEditor', {
        title: element.name,
        files: element.files || [],
        closeOnEsc: false,
        closeOnOverlayClick: false,
        defaultValue: element.description,
        elementId: elementId,
        getElement: () => queryElements([elementId]),
        onGetFilesProgress: () => {
          getElementFileConversionProgress(elementId).then(
            ({ data: files }) => {
              updateModalProps({ files });
            }
          );
        },
        onSubmitFile: ({
          file,
          onProgressChange,
          errorCallback,
          successCallback,
          cancelToken,
        }) => {
          getElementUploadAccess(elementId, file.type)
            .then(({ data }) => {
              const {
                accessToken,
                accountName,
                blobNamePrefix,
                containerName,
                uploaderUrl,
              } = data;
              const formData = createFormData({
                fileName: file.name,
                contentType: file.type,
                accessToken,
                accountName,
                containerName,
                blobNamePrefix,
                file,
              });
              uploadFileToElementService(uploaderUrl, formData, {
                headers: {
                  Pragma: 'no-cache',
                  'Content-Type': 'multipart/form-data',
                },
                cancelToken,
                onUploadProgress: (progressEvent) => {
                  const completionPercentage = parseInt(
                    Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    )
                  );
                  onProgressChange(completionPercentage);
                },
              })
                .then(({ data }) =>
                  finalizeElementFileUpload(elementId, data.fileName)
                )
                .then(() => {
                  successCallback();
                })
                .catch((err) => {
                  errorCallback(err);
                });
            })
            .catch((err) => {
              errorCallback(err);
            });
        },
        onDeleteFile: ({ fileName }) => {
          deleteElementFileService(elementId, fileName).then(() => {
            queryElements([elementId]).then((res) => {
              updateModalProps({ files: res[elementId].files });
            });
          });
        },
        maxWidth: '700px',
        fullWidth: true,
        onConfirm: (newDescription) => {
          if (newDescription !== element.description) {
            updateElementDescriptionService(elementId, newDescription).then(
              () => {
                queryElements([elementId]);
              }
            );
          }
        },
      });
    };

    showChangeElementLocationModal = ({ id, areaId }) => {
      const {
        areas,
        categories,
        elements,
        showModal,
        accessibleAreas,
        hasReadOnlyAccessToArea,
      } = this.props;
      showModal('radioButton', {
        title: localeLookup('translations.Relocate'),
        subtitle: `${areas[areaId].name} · ${elements[id].name}`,
        fullWidth: true,
        selectedValue: areaId,
        onConfirm: (destinationAreaId) => {
          this.showConfirmMoveElementModal({
            elementId: id,
            areaId,
            destinationAreaId,
          });
        },
        options: sortBy(
          accessibleAreas
            .filter((id) => !hasReadOnlyAccessToArea(id))
            .map((id) => {
              const area = areas[id];
              return {
                label: area.name,
                subtitle: categories?.[areas[areaId].category],
                value: id,
              };
            }),
          [(a, b) => compareLocal(a.label, b.label)]
        ),
      });
    };

    showChangeElementResponsibleModal = (elementId, areaId) => {
      const { areas, elements, showModal, queryElements, hasAccess } =
        this.props;
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const element = elements[elementId];
      const area = areas[areaId];
      showModal('mediator', {
        subtitle: `${area.name} · ${element.name}`,
        elementId,
        expertIds: area.experts,
        selectedMediator: element.responsible,
        selectedAlternativeMediatorId: element.alternativeResponsible,
        fullWidth: true,
        closeOnOverlayClick: false,
        locked: element.lockedApprover,
        multiApproval: element.completionType === 'Multipart',
        requireSignature: element.completionRequirement === 'Signature',
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onUpdated: () => {
          queryElements([elementId]);
        },
        onCreatedUser: () => {},
      });
    };

    showChangeOrganisationUnitModal = (roleId) => {
      const { roles, showModal, organisationUnits } = this.props;

      showModal('organisationUnit', {
        confirmBtnText: localeLookup('translations.Save'),
        fullWidth: true,
        onConfirm: (selectedUnits) => {
          const unitIdsToDisconnect = roles[roleId].organisationUnits.filter(
            (id) => !selectedUnits.includes(id)
          );
          this.setState({ isSubmitting: true });

          if (unitIdsToDisconnect.length > 0 && selectedUnits.length > 0) {
            disconnectRolesFromOrganisationUnitsService(
              [roleId],
              unitIdsToDisconnect
            ).then(() => {
              connectRolesToOrganisationUnitsService(
                [roleId],
                selectedUnits
              ).then(() => {
                this.getRole(roleId);
              });
            });
          } else if (unitIdsToDisconnect.length > 0) {
            disconnectRolesFromOrganisationUnitsService(
              [roleId],
              unitIdsToDisconnect
            ).then(() => {
              this.getRole(roleId);
            });
          } else if (selectedUnits.length > 0) {
            connectRolesToOrganisationUnitsService(
              [roleId],
              selectedUnits
            ).then(() => {
              this.getRole(roleId);
            });
          }
          return;
        },
        organisationUnits,
        roleId,
        selectedOrganisationUnitIds: roles[roleId].organisationUnits,
        subtitle: roles[roleId].name,
        title: `${localeLookup('translations.Organisation units')}`,
      });
    };

    showChangePersonAreaEditorshipsModal = ({ personId }) => {
      const { areas, categories, hideModal, showModal, persons } = this.props;
      const person = persons[personId];
      const selectedOptionIds = Object.values(areas).reduce((acc, area) => {
        if (area.editors.includes(personId)) {
          return [...acc, area.id];
        }
        return acc;
      }, []);
      const options = Object.values(areas).reduce((acc, area) => {
        return [
          ...acc,
          {
            title: area.name,
            searchString: area.name,
            subtitle: categories[area.category],
            id: area.id,
          },
        ];
      }, []);

      showModal('checkList', {
        title: localeLookup('translations.Editor'),
        subtitle: person.name,
        fullWidth: true,
        maxWidth: '500px',
        options,
        selectedOptionIds,

        onConfirm: (areaIds) => {
          hideModal();
          updateAreaEditorsService(areaIds, [personId], 'person').then(() => {
            this.getAreas([]);
          });
        },
      });
    };

    showChangePersonAreaOwnershipsModal = ({ personId, selectedOptions }) => {
      const { areas, categories, hideModal, showModal, persons, lookupPerson } =
        this.props;
      const person = persons[personId];
      const selectedOptionIds =
        selectedOptions ||
        Object.values(areas).reduce((acc, area) => {
          if (area.owner === personId) {
            return [...acc, area.id];
          }
          return acc;
        }, []);
      const options = Object.values(areas).reduce((acc, area) => {
        return [
          ...acc,
          {
            title: area.name,
            searchString: area.name,
            subtitle: categories[area.category],
            id: area.id,
          },
        ];
      }, []);

      showModal('checkList', {
        title: localeLookup('translations.Owner'),
        subtitle: person.name,
        fullWidth: true,
        maxWidth: '500px',
        options,
        selectedOptionIds,
        onConfirm: (areaIds) => {
          const areaIdsWithNewOwner = areaIds.filter((id) => {
            const area = areas[id];
            return area.owner !== EMPTY_ID && area.owner !== person.id;
          });
          if (areaIdsWithNewOwner.length > 0) {
            const rows = areaIdsWithNewOwner.map((id) => {
              const area = areas[id];
              const currentOwner = lookupPerson(area.owner);
              return [area.name, currentOwner.name, person.name];
            });
            showModal('confirmation', {
              title: localeLookup('translations.Confirm change owner'),
              confirmButtonText: localeLookup('translations.Confirm'),
              fullWidth: true,
              infoText: localeLookup(
                'translations.Modules can only have one owner. Confirm that you want to change the owner for the following modules'
              ),
              maxWidth: '600px',
              onCancel: () =>
                this.showChangePersonAreaOwnershipsModal({
                  personId,
                  selectedOptions: areaIds,
                }),
              onConfirm: () => {
                updateAreaOwnerService(areaIds, personId, 'person').then(() => {
                  this.getAreas([]);
                });
              },
              body: (
                <>
                  <SimpleTable
                    rows={rows}
                    headerRow={[
                      localeLookup('translations.Knowledge area'),
                      localeLookup('translations.Current owner'),
                      localeLookup('translations.New owner'),
                    ]}
                    columnWidths={['33%', '33%', '33%']}
                  />
                </>
              ),
            });
          } else {
            hideModal();
            updateAreaOwnerService(areaIds, personId, 'person').then(() => {
              this.getAreas([]);
            });
          }
        },
      });
    };

    showChangePersonOrganisationAdministratorModal = ({
      personId,
      onChanged,
    }) => {
      const {
        organisationUnits,
        persons,
        showModal,
        hideModal,
        getAllOrganisationUnits,
      } = this.props;
      const person = persons[personId];
      const unitsWherePersonIsAdministrator = Object.keys(
        organisationUnits
      ).filter((unitId) =>
        organisationUnits[unitId].teamAdministrators.includes(personId)
      );
      showModal('organisationAdministrator', {
        fullWidth: true,
        title: localeLookup('translations.Organisation administrator'),
        subtitle: person.name,
        rootNodes: [Object.keys(organisationUnits)[0]],
        onConfirm: (selectedUnits) => {
          updateOrganisationUnitPersonAdministratorsService({
            unitIds: selectedUnits,
            personIds: [person.id],
            context: 'person',
          }).then(async () => {
            await getAllOrganisationUnits();
            hideModal();
            onChanged?.();
          });
        },
        selectedUnits: unitsWherePersonIsAdministrator,
        filterPlaceholder: `${localeLookup(
          'translations.Search for organisation units'
        )}...`,
      });
    };

    showChangePersonRoleEditorshipsModal = ({ personId }) => {
      const { roles, organisationUnits, hideModal, showModal, persons } =
        this.props;
      const person = persons[personId];
      const selectedOptionIds = Object.values(roles).reduce((acc, role) => {
        if (role.editors.includes(personId)) {
          return [...acc, role.id];
        }
        return acc;
      }, []);
      const options = Object.values(roles).reduce((acc, role) => {
        return [
          ...acc,
          {
            title: role.name,
            searchString: `${role.name}${role.organisationUnits
              .map((id) => organisationUnits[id].name)
              .join('')}`,
            subtitle: (
              <OrganisationUnitsText
                organisationUnitIds={role.organisationUnits}
              />
            ),
            id: role.id,
          },
        ];
      }, []);

      showModal('checkList', {
        title: localeLookup('translations.Editor'),
        subtitle: person.name,
        fullWidth: true,
        maxWidth: '500px',
        options,
        selectedOptionIds,

        onConfirm: (roleIds) => {
          hideModal();
          updateRoleEditorsService(roleIds, [personId], 'person').then(() => {
            this.getRoles([]);
          });
        },
      });
    };

    showChangePersonRoleOwnershipsModal = ({ personId, selectedOptions }) => {
      const {
        roles,
        organisationUnits,
        hideModal,
        showModal,
        persons,
        lookupPerson,
      } = this.props;
      const person = persons[personId];
      const selectedOptionIds =
        selectedOptions ||
        Object.values(roles).reduce((acc, role) => {
          if (role.owner === personId) {
            return [...acc, role.id];
          }
          return acc;
        }, []);
      const options = Object.values(roles).reduce((acc, role) => {
        return [
          ...acc,
          {
            title: role.name,
            searchString: `${role.name}${role.organisationUnits
              .map((id) => organisationUnits[id].name)
              .join('')}`,
            subtitle: (
              <OrganisationUnitsText
                organisationUnitIds={role.organisationUnits}
              />
            ),
            id: role.id,
          },
        ];
      }, []);

      showModal('checkList', {
        title: localeLookup('translations.Owner'),
        subtitle: person.name,
        fullWidth: true,
        maxWidth: '500px',
        options,
        selectedOptionIds,

        onConfirm: (roleIds) => {
          const roleIdsWithNewOwner = roleIds.filter((id) => {
            const role = roles[id];
            return role.owner !== EMPTY_ID && role.owner !== person.id;
          });
          if (roleIdsWithNewOwner.length > 0) {
            const rows = roleIdsWithNewOwner.map((id) => {
              const role = roles[id];
              const currentOwner = lookupPerson(role.owner);
              return [role.name, currentOwner.name, person.name];
            });
            showModal('confirmation', {
              title: localeLookup('translations.Confirm change owner'),
              confirmButtonText: localeLookup('translations.Confirm'),
              fullWidth: true,
              infoText: localeLookup(
                'translations.Roles can only have one owner. Confirm that you want to change the owner for the following roles'
              ),
              maxWidth: '600px',
              onCancel: () =>
                this.showChangePersonRoleOwnershipsModal({
                  personId,
                  selectedOptions: roleIds,
                }),
              onConfirm: () => {
                updateRoleOwnerService(roleIds, personId, 'person').then(() => {
                  this.getRoles([]);
                });
              },
              body: (
                <>
                  <SimpleTable
                    rows={rows}
                    headerRow={[
                      localeLookup('translations.Role'),
                      localeLookup('translations.Current owner'),
                      localeLookup('translations.New owner'),
                    ]}
                    columnWidths={['33%', '33%', '33%']}
                  />
                </>
              ),
            });
          } else {
            hideModal();
            updateRoleOwnerService(roleIds, personId, 'person').then(() => {
              this.getRoles([]);
            });
          }
        },
      });
    };

    showChangePersonSpaceAdministratorModal = async ({
      personId,
      onChanged,
    }) => {
      const { spaces, hideModal, showModal, persons } = this.props;
      const person = persons[personId];
      const personsWithAccessLevelsResponse =
        await getPersonsWithAccessLevelsService();
      const personsWithAccessLevels = personsWithAccessLevelsResponse.data;
      const personWithAccessLevels = personsWithAccessLevels[person.id];
      const isPersonAdmin = [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ].some((accessLevel) =>
        personWithAccessLevels?.accessLevels.includes(accessLevel)
      );
      const selectedOptionIds = Object.values(spaces).reduce((acc, space) => {
        if (space.administrators.includes(personId)) {
          return [...acc, space.id];
        }
        return acc;
      }, []);
      const options = Object.values(spaces).reduce((acc, space) => {
        return [
          ...acc,
          {
            title: space.name,
            id: space.id,
          },
        ];
      }, []);

      showModal('contentAdministrator', {
        title: localeLookup('translations.Content administrator'),
        subtitle: person.name,
        fullWidth: true,
        maxWidth: '500px',
        personAccessLevels: personWithAccessLevels?.accessLevels,
        options,
        selectedOptionIds,
        onConfirm: async ({
          selectedOptionIds: spaceIds,
          overAllTeamAdministrator,
        }) => {
          hideModal();
          const isPersonContentAdministrator =
            personWithAccessLevels?.accessLevels.includes(
              ACCESS_LEVELS.contentAdministrator
            );
          if (isPersonContentAdministrator !== overAllTeamAdministrator) {
            if (overAllTeamAdministrator) {
              addPersonPermissionsService(personId, [
                ACCESS_LEVELS.contentAdministrator,
              ]);
            } else {
              removePersonPermissionsService(personId, [
                ACCESS_LEVELS.contentAdministrator,
              ]);
            }
          }
          await updateSpaceAdministratorsService(
            spaceIds,
            [personId],
            'person'
          );
          this.getSpaces();
          onChanged?.();
        },
      });
    };

    showChangePersonSpaceCreateAreaModal = async ({ personId, onChanged }) => {
      const { spaces, hideModal, showModal, persons } = this.props;
      const person = persons[personId];
      const personsWithAccessLevelsResponse =
        await getPersonsWithAccessLevelsService();
      const personsWithAccessLevels = personsWithAccessLevelsResponse.data;
      const personWithAccessLevels = personsWithAccessLevels[person.id];
      const isPersonAdmin = [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ].some((accessLevel) =>
        personWithAccessLevels?.accessLevels.includes(accessLevel)
      );
      const selectedOptionIds = Object.values(spaces).reduce((acc, space) => {
        if (
          space.administrators.includes(personId) ||
          space.canCreateModules.includes(personId)
        ) {
          return [...acc, space.id];
        }
        return acc;
      }, []);
      const options = Object.values(spaces).reduce((acc, space) => {
        const isPersonSpaceAdmin = space.administrators?.includes(personId);
        return [
          ...acc,
          {
            title: space.name,
            id: space.id,
            disabled: isPersonAdmin || isPersonSpaceAdmin,
          },
        ];
      }, []);

      showModal('checkList', {
        title: localeLookup('translations.Create modules in content spaces'),
        subtitle: person.name,
        fullWidth: true,
        maxWidth: '500px',
        options,
        selectedOptionIds,
        onConfirm: async (spaceIds) => {
          hideModal();
          await updateSpaceCreateModulePermissionsService(
            spaceIds,
            [personId],
            'person'
          );
          await this.getSpaces();
          onChanged?.();
        },
      });
    };

    showChangePersonSpaceCreateRoleModal = async ({ personId, onChanged }) => {
      const { spaces, hideModal, showModal, persons } = this.props;
      const person = persons[personId];
      const personsWithAccessLevelsResponse =
        await getPersonsWithAccessLevelsService();
      const personsWithAccessLevels = personsWithAccessLevelsResponse.data;
      const personWithAccessLevels = personsWithAccessLevels[person.id];
      const isPersonAdmin = [
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ].some((accessLevel) =>
        personWithAccessLevels?.accessLevels.includes(accessLevel)
      );
      const selectedOptionIds = Object.values(spaces).reduce((acc, space) => {
        if (
          space.administrators.includes(personId) ||
          space.canCreateRoles.includes(personId)
        ) {
          return [...acc, space.id];
        }
        return acc;
      }, []);
      const options = Object.values(spaces).reduce((acc, space) => {
        const isPersonSpaceAdmin = space.administrators?.includes(personId);
        return [
          ...acc,
          {
            title: space.name,
            id: space.id,
            disabled: isPersonAdmin || isPersonSpaceAdmin,
          },
        ];
      }, []);

      showModal('checkList', {
        title: localeLookup('translations.Create roles in content spaces'),
        subtitle: person.name,
        fullWidth: true,
        maxWidth: '500px',
        options,
        selectedOptionIds,
        onConfirm: async (spaceIds) => {
          hideModal();
          await updateSpaceCreateRolePermissionsService(
            spaceIds,
            [personId],
            'person'
          );
          await this.getSpaces();
          onChanged?.();
        },
      });
    };

    showChangeRoleAreasModal = (roleId, isAdditional = false) => {
      const {
        areas,
        categories,
        roles,
        showModal,
        hideModal,
        hasAccess,
        getAreasAccess,
        activeSpaceId,
        hasCreateAreaPermission,
      } = this.props;
      const role = roles[roleId];
      const title = `${
        isAdditional
          ? localeLookup('translations.Add additional knowledge')
          : localeLookup('translations.Add mandatory knowledge')
      }`;
      const connectedAreas = [...role.areas, ...role.additionalAreas];
      const showCreateAreaButton = hasCreateAreaPermission({
        spaceId: activeSpaceId,
      });
      const options = sortBy(
        Object.values(areas).reduce((acc, area) => {
          const areaSpaceIds = [
            ...Object.keys(area.editableInSpaces),
            ...Object.keys(area.readOnlyInSpaces),
          ];
          if (
            !connectedAreas.includes(area.id) &&
            areaSpaceIds.includes(activeSpaceId)
          ) {
            return [
              ...acc,
              {
                title: area.name,
                subtitle: categories[area.category],
                id: area.id,
              },
            ];
          }
          return acc;
        }, []),
        [(a, b) => compareLocal(a.title, b.title)]
      );
      showModal('checkList', {
        title,
        subtitle: roles[roleId].name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds: [],
        options,
        onConfirm: (selectedIds) => {
          hideModal();
          connectAreasToRolesService([roleId], selectedIds, isAdditional).then(
            () => {
              this.getAreas(selectedIds).then(() => {
                this.getRole(roleId);
              });
            }
          );
        },
        showFilterButton: showCreateAreaButton,
        filterButtonText: localeLookup('translations.Create knowledge area'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createArea', {
            title,
            subtitle: roles[roleId].name,
            onCancel: () => this.showAddAreaModal(roleId, isAdditional),
            isInRoleContext: true,
            roleOwnerId: role.owner,
            roleEditorIds: role.editors,
            initialName: filterString,
            onCreated: (id) => {
              hideModal();
              connectAreasToRolesService([roleId], [id], isAdditional).then(
                () => {
                  this.getAreas([id]).then(() => {
                    this.getRole(roleId);
                    getAreasAccess();
                  });
                }
              );
            },
          });
        },
      });
    };

    showChangeRoleDescriptionModal = (roleId) => {
      const { roles, showModal } = this.props;
      showModal('richTextEditor', {
        title: localeLookup('translations.Role description'),
        subtitle: roles[roleId].name,
        maxWidth: '700px',
        fullWidth: true,
        defaultValue: roles[roleId].description,
        onConfirm: (newDescription) => {
          updateRoleDescriptionService(roleId, newDescription).then(() => {
            this.getRole(roleId);
          });
        },
      });
    };

    showChangeRoleEditorsModal = ({
      roleId,
      selectedValues,
      newlyCreatedUserIds = [],
      onChanged,
    }) => {
      const {
        roles,
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
      } = this.props;
      const role = roles[roleId];
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const renderSectioned =
        newlyCreatedUserIds?.length > 0 || role.editors?.length > 0;
      const selectedOptionIds = selectedValues || role.editors || [];
      const getFlatOptions = () =>
        activePersonsSortOrder.reduce((acc, id) => {
          const person = persons[id];
          return [
            ...acc,
            {
              title: person.name,
              id,
              tooltip: `${person.initials} ${
                person.employeeNumber ? `· ${person.employeeNumber}` : ''
              }`,
              searchString: `${person.name}${person.employeeNumber}${person.initials}`,
            },
          ];
        }, []);

      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            if (newlyCreatedUserIds.includes(id)) {
              acc[0].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            } else if (role.editors?.includes(id)) {
              acc[1].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            } else {
              acc[2].options.push({
                title: person.name,
                id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
            }
            return acc;
          },
          [
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Current selection'),
              options: [],
            },
            { title: localeLookup('translations.Other persons'), options: [] },
          ]
        );
      const options = renderSectioned
        ? getSectionedOptions()
        : getFlatOptions();
      showModal('checkList', {
        title: localeLookup('translations.Editors'),
        subtitle: role.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds,
        renderSectioned,
        options,
        onConfirm: (ids) => {
          hideModal();
          this.updateRoleEditors({ roleId, ids, onChanged });
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            initialName: filterString,
            onCreated: ({ id }) => {
              hideModal();
              this.showChangeRoleEditorsModal({
                roleId,
                selectedValues: selectedOptionIds,
                newlyCreatedUserIds: [...newlyCreatedUserIds, id],
              });
            },
          });
        },
      });
    };

    showChangeRoleNameModal = ({ roleId }) => {
      const { roles, hideModal, showModal } = this.props;
      const role = roles[roleId];
      showModal('nameModal', {
        title: localeLookup('translations.Rename'),
        defaultValue: role.name,
        confirmButtonText: localeLookup('translations.Rename'),
        onConfirm: ({ name }) => {
          this.changeRoleName(roleId, name);
          hideModal();
        },
      });
    };

    showChangeRoleOwnerModal = ({
      roleId,
      selectedValue,
      newlyCreatedUserIds = [],
      onChanged,
    }) => {
      const {
        persons,
        roles,
        showModal,
        activePersonsSortOrder,
        hideModal,
        hasAccess,
      } = this.props;
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const role = roles[roleId];
      const ownerId = role.owner;
      const defaultValue = selectedValue
        ? selectedValue
        : persons[ownerId]
        ? roles[roleId].owner
        : EMPTY_ID;
      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            if (defaultValue === id) {
              acc[0].options.push({
                label: person.name,
                value: id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
              return acc;
            }
            if (newlyCreatedUserIds.includes(id)) {
              acc[1].options.push({
                label: person.name,
                value: id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
              return acc;
            } else {
              acc[2].options.push({
                label: person.name,
                value: id,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
              });
              return acc;
            }
          },
          [
            {
              title: localeLookup('translations.Current selection'),
              options:
                defaultValue === EMPTY_ID
                  ? [
                      {
                        label: localeLookup('translations.No owner'),
                        value: EMPTY_ID,
                      },
                    ]
                  : [],
            },
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Other persons'),
              options:
                defaultValue !== EMPTY_ID
                  ? [
                      {
                        label: localeLookup('translations.No owner'),
                        value: EMPTY_ID,
                      },
                    ]
                  : [],
            },
          ]
        );
      showModal('radioButton', {
        title: localeLookup('translations.Role owner'),
        subtitle: role.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedValue: defaultValue,
        onConfirm: (ownerId) => {
          this.updateRoleOwner({ roleId, ownerId, onChanged });
        },
        grouped: true,
        options: getSectionedOptions(),
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: (filterString) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            fullWidth: false,
            initialName: filterString,
            onCreated: ({ id }) => {
              hideModal();
              this.showChangeRoleOwnerModal({
                roleId,
                selectedValue: null,
                newlyCreatedUserIds: [...newlyCreatedUserIds, id],
                onChanged,
              });
            },
          });
        },
      });
    };

    showChangeSpaceNameModal = ({ spaceId }) => {
      const { spaces, hideModal, showModal } = this.props;
      const space = spaces[spaceId];
      showModal('nameModal', {
        title: localeLookup('translations.Rename'),
        defaultValue: space.name,
        confirmButtonText: localeLookup('translations.Rename'),
        onConfirm: ({ name }) => {
          this.changeSpaceName(spaceId, name);
          hideModal();
        },
      });
    };

    changeRoleSpace = async ({ roleId, spaceId }) => {
      const { showModal } = this.props;
      const roleResponse = await this.getRole(roleId);
      const role = roleResponse[roleId];
      if (role.areas?.length === 0 && role.additionalAreas?.length === 0) {
        await updateRoleSpaceService({
          originSpaceId: role.space,
          destinationSpaceId: spaceId,
          roleId,
        });
        await Promise.all([this.getRole(roleId), this.getSpaces()]);
      } else {
        showModal('changeRoleSpace', {
          fullWidth: true,
          maxWidth: '500px',
          title: localeLookup('translations.Content space'),
          subtitle: role.name,
          roleId,
          spaceId,
        });
      }
    };

    showChangeRoleSpaceModal = async ({ roleId }) => {
      const { roles, showModal, spaces, currentUserId, hasAccess } = this.props;
      const role = roles[roleId];
      showModal('radioButton', {
        title: localeLookup('translations.Content space'),
        subtitle: role.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedValue: role.space,
        onConfirm: (spaceId) => {
          this.changeRoleSpace({ roleId, spaceId });
        },
        grouped: false,
        options: sortBy(Object.values(spaces), [
          (a, b) => {
            return compareLocal(a.name, b.name);
          },
        ]).map((space) => {
          const isAdmin = hasAccess([
            ACCESS_LEVELS.champadministrator,
            ACCESS_LEVELS.administrator,
            ACCESS_LEVELS.contentAdministrator,
          ]);
          const isAdminInSpace = space.administrators.includes(currentUserId);
          return {
            label: space.name,
            value: space.id,
            disabled: !isAdmin && !isAdminInSpace,
          };
        }),
      });
    };

    showChangeSpaceAdministratorsModal = async ({
      selectedValues,
      newlyCreatedUserIds = [],
      spaceId,
    }) => {
      const {
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
        spaces,
      } = this.props;
      const space = spaces[spaceId];
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const personsWithAccessLevelsResponse =
        await getPersonsWithAccessLevelsService();
      const personsWithAccessLevels = personsWithAccessLevelsResponse.data;
      const selectedOptionIds =
        selectedValues ||
        activePersonsSortOrder.filter((id) => {
          const person = persons[id];
          const personWithAccessLevels = personsWithAccessLevels[person.id];
          const isPersonAdmin = [
            ACCESS_LEVELS.champadministrator,
            ACCESS_LEVELS.administrator,
            ACCESS_LEVELS.contentAdministrator,
          ].some((accessLevel) =>
            personWithAccessLevels?.accessLevels.includes(accessLevel)
          );
          return space.administrators?.includes(id) || isPersonAdmin;
        });
      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            const personWithAccessLevels = personsWithAccessLevels[person.id];
            const isPersonAdmin = [
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.contentAdministrator,
            ].some((accessLevel) =>
              personWithAccessLevels?.accessLevels.includes(accessLevel)
            );
            if (newlyCreatedUserIds.includes(id)) {
              acc[0].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
              });
            } else if (space.administrators?.includes(id) || isPersonAdmin) {
              acc[1].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
                disabled: isPersonAdmin,
              });
            } else {
              acc[2].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
              });
            }
            return acc;
          },
          [
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Current selection'),
              options: [],
            },
            { title: localeLookup('translations.Other persons'), options: [] },
          ]
        );
      const options = getSectionedOptions();

      showModal('checkList', {
        title: localeLookup('translations.Content administrators'),
        subtitle: space.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds,
        options,
        renderSectioned: true,
        onConfirm: async (ids) => {
          hideModal();
          await updateSpaceAdministratorsService([spaceId], ids, 'space');
          this.getSpaces();
          //this.updateAreaEditors({ areaId, ids, onChanged });
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            initialName: filterString,
            onCreated: ({ id }) => {
              hideModal();
              this.showChangeSpaceAdministratorsModal({
                spaceId,
                selectedValues: selectedOptionIds,
                newlyCreatedUserIds: [...newlyCreatedUserIds, id],
              });
            },
          });
        },
      });
    };

    showChangeSpaceAreasModal = async ({ spaceId, initialOptions }) => {
      const { areas, showModal, spaces, hideModal, currentUserId, hasAccess } =
        this.props;
      const space = spaces[spaceId];
      const isAdminInSpace = space.administrators.includes(currentUserId);
      const isAdmin = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ]);
      const options = Object.values(areas).map((area) => {
        const isEditableInSpace = Object.keys(area.editableInSpaces).includes(
          space.id
        );
        const isReadOnlyInSpace = Object.keys(area.readOnlyInSpaces).includes(
          space.id
        );
        const activeStateId = isEditableInSpace
          ? 'editable'
          : isReadOnlyInSpace
          ? 'readonly'
          : 'notConnected';

        const areaSpaceIds = [
          ...Object.keys(area.editableInSpaces),
          ...Object.keys(area.readOnlyInSpaces),
        ];

        const isRequiredInSpace =
          area.editableInSpaces[space.id]?.connectedToRoles.length > 0 ||
          area.readOnlyInSpaces[space.id]?.connectedToRoles.length > 0;

        const canRemoveFromSpace =
          (areaSpaceIds.length === 1 && areaSpaceIds.includes(space.id)) ||
          isRequiredInSpace;

        return {
          label: area.name,
          value: area.id,
          id: area.id,
          title: area.name,
          disabled: !isAdmin && !isAdminInSpace,
          isRequired: isRequiredInSpace,
          activeStateId,
          states: canRemoveFromSpace
            ? [
                {
                  icon: 'eye',
                  stateId: 'readonly',
                  color: 'light-green',
                },
                { icon: 'check', stateId: 'editable', color: 'green' },
              ]
            : [
                { stateId: 'notConnected', color: 'stroke' },
                {
                  icon: 'eye',
                  stateId: 'readonly',
                  color: 'light-green',
                },
                { icon: 'check', stateId: 'editable', color: 'green' },
              ],
        };
      });

      showModal('multiStateButton', {
        title: localeLookup('translations.Modules'),
        subtitle: space.name,
        fullWidth: true,
        maxWidth: '500px',
        onConfirm: async (newOptions) => {
          const changedOptions = newOptions.filter((option) => {
            const currentOptionState = options.find(
              (currentOpion) => currentOpion.id === option.id
            ).activeStateId;
            return currentOptionState !== option.activeStateId;
          });
          const newNotConnectedAreaIds = changedOptions
            .filter((option) => option.activeStateId === 'notConnected')
            .map((o) => o.id);
          const newEditableAreaIds = changedOptions
            .filter((option) => option.activeStateId === 'editable')
            .map((o) => o.id);
          const newReadonlyAreaIds = changedOptions
            .filter((option) => option.activeStateId === 'readonly')
            .map((o) => o.id);

          const onConfirmChange = async () => {
            hideModal();
            await this.updateSpaceAreaConnections({
              spaceId: spaceId,
              editableAreaIds: newEditableAreaIds,
              notConnectedAreaIds: newNotConnectedAreaIds,
              readonlyAreaIds: newReadonlyAreaIds,
            });
          };
          onConfirmChange();
        },
        grouped: false,
        options: initialOptions || options,
      });
    };

    showChangeSpaceCreateAreaPermissionModal = async ({
      selectedValues,
      newlyCreatedUserIds = [],
      spaceId,
    }) => {
      const {
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
        spaces,
      } = this.props;
      const space = spaces[spaceId];
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const personsWithAccessLevelsResponse =
        await getPersonsWithAccessLevelsService();
      const personsWithAccessLevels = personsWithAccessLevelsResponse.data;
      const selectedOptionIds =
        selectedValues ||
        activePersonsSortOrder.filter((id) => {
          const person = persons[id];
          const personWithAccessLevels = personsWithAccessLevels[person.id];
          const isPersonAdmin = [
            ACCESS_LEVELS.champadministrator,
            ACCESS_LEVELS.administrator,
            ACCESS_LEVELS.contentAdministrator,
          ].some((accessLevel) =>
            personWithAccessLevels?.accessLevels.includes(accessLevel)
          );
          return (
            space.canCreateModules?.includes(id) ||
            space.administrators?.includes(id) ||
            isPersonAdmin
          );
        });
      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            const personWithAccessLevels = personsWithAccessLevels[person.id];
            const isPersonAdmin = [
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.contentAdministrator,
            ].some((accessLevel) =>
              personWithAccessLevels?.accessLevels.includes(accessLevel)
            );
            const isPersonSpaceAdmin = space.administrators?.includes(id);
            if (newlyCreatedUserIds.includes(id)) {
              acc[0].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
              });
            } else if (
              space.canCreateModules?.includes(id) ||
              isPersonSpaceAdmin ||
              isPersonAdmin
            ) {
              acc[1].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
                disabled: isPersonAdmin || isPersonSpaceAdmin,
              });
            } else {
              acc[2].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
              });
            }
            return acc;
          },
          [
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Current selection'),
              options: [],
            },
            { title: localeLookup('translations.Other persons'), options: [] },
          ]
        );
      const options = getSectionedOptions();

      showModal('checkList', {
        title: localeLookup('translations.Create modules'),
        subtitle: space.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds,
        options,
        renderSectioned: true,
        onConfirm: async (ids) => {
          hideModal();
          await updateSpaceCreateModulePermissionsService(
            [spaceId],
            ids,
            'space'
          );
          this.getSpaces();
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            initialName: filterString,
            onCreated: ({ id }) => {
              hideModal();
              this.updateSpaceCreateModulePermissionsService({
                spaceId,
                selectedValues: selectedOptionIds,
                newlyCreatedUserIds: [...newlyCreatedUserIds, id],
              });
            },
          });
        },
      });
    };

    showChangeSpaceCreateRolePermissionModal = async ({
      selectedValues,
      newlyCreatedUserIds = [],
      spaceId,
    }) => {
      const {
        persons,
        showModal,
        hideModal,
        activePersonsSortOrder,
        hasAccess,
        spaces,
      } = this.props;
      const space = spaces[spaceId];
      const showFilterButton = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.createPersons,
        ACCESS_LEVELS.userAdministrator,
        ACCESS_LEVELS.personAdministrator,
      ]);
      const personsWithAccessLevelsResponse =
        await getPersonsWithAccessLevelsService();
      const personsWithAccessLevels = personsWithAccessLevelsResponse.data;
      const selectedOptionIds =
        selectedValues ||
        activePersonsSortOrder.filter((id) => {
          const person = persons[id];
          const personWithAccessLevels = personsWithAccessLevels[person.id];
          const isPersonAdmin = [
            ACCESS_LEVELS.champadministrator,
            ACCESS_LEVELS.administrator,
            ACCESS_LEVELS.contentAdministrator,
          ].some((accessLevel) =>
            personWithAccessLevels?.accessLevels.includes(accessLevel)
          );
          return (
            space.canCreateRoles?.includes(id) ||
            space.administrators?.includes(id) ||
            isPersonAdmin
          );
        });
      const getSectionedOptions = () =>
        activePersonsSortOrder.reduce(
          (acc, id) => {
            const person = persons[id];
            const personWithAccessLevels = personsWithAccessLevels[person.id];
            const isPersonAdmin = [
              ACCESS_LEVELS.champadministrator,
              ACCESS_LEVELS.administrator,
              ACCESS_LEVELS.contentAdministrator,
            ].some((accessLevel) =>
              personWithAccessLevels?.accessLevels.includes(accessLevel)
            );
            const isPersonSpaceAdmin = space.administrators?.includes(id);
            if (newlyCreatedUserIds.includes(id)) {
              acc[0].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
              });
            } else if (
              space.canCreateRoles?.includes(id) ||
              isPersonSpaceAdmin ||
              isPersonAdmin
            ) {
              acc[1].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
                disabled: isPersonAdmin || isPersonSpaceAdmin,
              });
            } else {
              acc[2].options.push({
                title: person.name,
                searchString: `${person.name}${person.employeeNumber}${person.initials}`,
                tooltip: `${person.initials} ${
                  person.employeeNumber ? `· ${person.employeeNumber}` : ''
                }`,
                id,
              });
            }
            return acc;
          },
          [
            { title: localeLookup('translations.Just created'), options: [] },
            {
              title: localeLookup('translations.Current selection'),
              options: [],
            },
            { title: localeLookup('translations.Other persons'), options: [] },
          ]
        );
      const options = getSectionedOptions();

      showModal('checkList', {
        title: localeLookup('translations.Create roles'),
        subtitle: space.name,
        fullWidth: true,
        maxWidth: '500px',
        selectedOptionIds,
        options,
        renderSectioned: true,
        onConfirm: async (ids) => {
          hideModal();
          await updateSpaceCreateRolePermissionsService(
            [spaceId],
            ids,
            'space'
          );
          this.getSpaces();
        },
        showFilterButton,
        filterButtonText: localeLookup('translations.Create person'),
        onFilterButtonClick: ({ filterString }) => {
          showModal('createUser', {
            title: localeLookup('translations.Create person'),
            initialName: filterString,
            onCreated: ({ id }) => {
              hideModal();
              this.showChangeSpaceCreateRolePermissionModal({
                spaceId,
                selectedValues: selectedOptionIds,
                newlyCreatedUserIds: [...newlyCreatedUserIds, id],
              });
            },
          });
        },
      });
    };

    showChangeAreaSpaceModal = async ({ areaId, initialOptions }) => {
      const { areas, showModal, spaces, hideModal, currentUserId, hasAccess } =
        this.props;
      const area = areas[areaId];
      const isAdmin = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ]);
      const options = Object.values(spaces).map((space) => {
        const isAdminInSpace = space.administrators.includes(currentUserId);

        const isEditableInSpace = Object.keys(area.editableInSpaces).includes(
          space.id
        );
        const isReadOnlyInSpace = Object.keys(area.readOnlyInSpaces).includes(
          space.id
        );
        const activeStateId = isEditableInSpace
          ? 'editable'
          : isReadOnlyInSpace
          ? 'readonly'
          : 'notConnected';

        const areaSpaceIds = [
          ...Object.keys(area.editableInSpaces),
          ...Object.keys(area.readOnlyInSpaces),
        ];

        const isRequiredInSpace =
          area.editableInSpaces[space.id]?.connectedToRoles.length > 0 ||
          area.readOnlyInSpaces[space.id]?.connectedToRoles.length > 0;

        const canRemoveFromSpace =
          (areaSpaceIds.length === 1 && areaSpaceIds.includes(space.id)) ||
          isRequiredInSpace;

        return {
          label: space.name,
          value: space.id,
          id: space.id,
          title: space.name,
          disabled: !isAdmin && !isAdminInSpace,
          isRequired: isRequiredInSpace,
          activeStateId,
          states: canRemoveFromSpace
            ? [
                {
                  icon: 'eye',
                  stateId: 'readonly',
                  color: 'light-green',
                },
                { icon: 'check', stateId: 'editable', color: 'green' },
              ]
            : [
                { stateId: 'notConnected', color: 'stroke' },
                {
                  icon: 'eye',
                  stateId: 'readonly',
                  color: 'light-green',
                },
                { icon: 'check', stateId: 'editable', color: 'green' },
              ],
        };
      });
      showModal('multiStateButton', {
        title: localeLookup('translations.Content space'),
        subtitle: area.name,
        fullWidth: true,
        maxWidth: '500px',
        onConfirm: async (newOptions) => {
          const changedOptions = newOptions.filter((option) => {
            const currentOptionState = options.find(
              (currentOpion) => currentOpion.id === option.id
            ).activeStateId;
            return currentOptionState !== option.activeStateId;
          });
          const newNotConnectedSpaceIds = changedOptions
            .filter((option) => option.activeStateId === 'notConnected')
            .map((o) => o.id);
          const newEditableSpaceIds = changedOptions
            .filter((option) => option.activeStateId === 'editable')
            .map((o) => o.id);
          const newReadonlySpaceIds = changedOptions
            .filter((option) => option.activeStateId === 'readonly')
            .map((o) => o.id);
          // If the area has changed to 'notConnected' or 'readonly' in any spaces
          // we need to check if the area will be accessible afterwards
          const hasChangedFromEditableInAnySpaces = options
            .filter((option) => option.activeStateId === 'editable')
            .some((option) => {
              const newState = newOptions.find(
                (newOption) => newOption.id === option.id
              ).activeStateId;
              return newState !== option.activeStateId;
            });

          const onConfirmChange = async () => {
            await this.updateAreaSpaceConnections({
              areaId: areaId,
              notConnectedSpaceIds: newNotConnectedSpaceIds,
              editableSpaceIds: newEditableSpaceIds,
              readonlySpaceIds: newReadonlySpaceIds,
            });
            await this.getAreas([areaId]);
            hideModal();
          };

          if (hasChangedFromEditableInAnySpaces) {
            const hasAccessToAreaAfterChange = this.hasAccessToArea({
              editorIds: area.editors,
              ownerId: area.owner,
              // Use newOptions because 'editableInSpaceIds' only includes new editable spaceIds
              editableInSpaceIds: newOptions
                .filter((option) => option.activeStateId === 'editable')
                .map((o) => o.id),
            });
            if (!hasAccessToAreaAfterChange) {
              this.showAreaScopeWarningModal({
                onConfirm: onConfirmChange,
                onCancel: () => {
                  this.showChangeAreaSpaceModal({
                    areaId,
                    initialOptions: newOptions,
                  });
                },
              });
            } else {
              // If user still has acces, update connections
              onConfirmChange();
            }
          } else {
            // If not, we can just change the connections
            onConfirmChange();
          }
        },
        grouped: false,
        options: initialOptions || options,
      });
    };

    showConfirmAreaDeleteModal = (areaId, onDeleted) => {
      const { areas, showModal, removeArea } = this.props;

      showModal('confirmation', {
        title: `${localeLookup(
          'translations.Are you sure you want to delete'
        )} ${areas[areaId].name}?`,
        body: (
          <>
            <p>
              {localeLookup(
                'translations.This action will delete the knowledgearea, all underlying elements and all status related to the area.'
              )}
            </p>
            <p>
              {localeLookup(
                'translations.The area will be deleted from all roles and this action cannot be undone.'
              )}
            </p>
          </>
        ),
        safeWord: localeLookup('translations.Delete'),
        maxWidth: '500px',
        btnRejectTitle: localeLookup('translations.Cancel'),
        confirmButtonText: localeLookup('translations.Delete'),
        confirmButtonType: 'alert',
        onConfirm: () => {
          deleteAreaService(areaId).then(() => {
            removeArea(areaId);
            onDeleted?.();
          });
        },
      });
    };

    showConfirmElementDeleteModal = ({ elementId, areaId, roleId }) => {
      const { elements, removeElement, showModal } = this.props;

      showModal('confirmation', {
        title: `${localeLookup(
          'translations.Are you sure you want to delete'
        )} ${elements[elementId].name}?`,
        safeWord: localeLookup('translations.Delete'),
        body: localeLookup(
          'translations.Deletion of this element can possibly affect other roles where the element is connected'
        ),
        maxWidth: '500px',
        btnRejectTitle: localeLookup('translations.Cancel'),
        confirmButtonText: localeLookup('translations.Delete'),
        confirmButtonType: 'alert',
        onConfirm: () => {
          deleteElementService(elementId).then(() => {
            if (roleId) {
              Promise.all([this.getRole(roleId), this.getAreas([areaId])]).then(
                () => {
                  removeElement(elementId);
                }
              );
            } else {
              this.getAreas([areaId]).then(() => {
                removeElement(elementId);
              });
            }
          });
        },
      });
    };

    showConfirmRoleDeleteModal = (roleId, onDeleted) => {
      const { roles, removeRole, showModal } = this.props;
      showModal('confirmation', {
        title: `${localeLookup(
          'translations.Are you sure you want to delete'
        )} ${roles[roleId].name}?`,
        maxWidth: '500px',
        safeWord: localeLookup('translations.Delete'),
        body: <RoleUsageInfo roleId={roleId} />,
        btnRejectTitle: localeLookup('translations.Cancel'),
        confirmButtonText: localeLookup('translations.Delete'),
        confirmButtonType: 'alert',
        onConfirm: () => {
          deleteRoleService(roleId).then(() => {
            removeRole(roleId);
            onDeleted();
            /* this.setState({ openRoleIds: openRoleIds.filter((id) => id !== roleId) }, () => {
            }); */
          });
        },
      });
    };

    showConfirmSpaceDeleteModal = ({ spaceId, onDeleted }) => {
      const { spaces, removeRole, showModal, removeSpace } = this.props;
      const space = spaces[spaceId];
      const { modulesEditable, modulesReadOnly, roles } = space;
      const isSpaceEmpty =
        roles.length === 0 &&
        modulesEditable.length === 0 &&
        modulesReadOnly.length === 0;
      if (!isSpaceEmpty) {
        showModal('infoModal', {
          title: `${localeLookup('translations.Delete')}`,
          subtitle: space.name,
          maxWidth: '500px',
          body: localeLookup(
            'translations.The content space contains roles and/or modules. A content space can only be deleted if it does not contain roles and modules'
          ),
        });
      } else {
        showModal('confirmation', {
          title: `${localeLookup('translations.Delete')}`,
          subtitle: space.name,
          maxWidth: '500px',
          safeWord: localeLookup('translations.Delete'),
          body: localeLookup(
            'translations.This action will delete the content space, and can not be undone'
          ),
          btnRejectTitle: localeLookup('translations.Cancel'),
          confirmButtonText: localeLookup('translations.Delete'),
          confirmButtonType: 'alert',
          onConfirm: () => {
            deleteSpaceService(space.id).then(() => {
              removeSpace(space.id);
              onDeleted?.();
            });
          },
        });
      }
    };

    showConfirmMoveElementModal = ({
      elementId,
      areaId,
      destinationAreaId,
    }) => {
      const {
        areas,
        elements,
        organisationUnits,
        showModal,
        hideModal,
        queryElements,
        queryAreas,
        queryRoles,
      } = this.props;
      getElementRelocationRelationsService(
        elementId,
        areaId,
        destinationAreaId
      ).then(({ data }) => {
        const element = elements[elementId];
        const headerRow = [
          localeLookup('translations.Role name'),
          localeLookup('translations.Organisation units'),
          localeLookup('translations.Area connection type'),
          localeLookup('translations.Element connected'),
        ];
        const updatedRoleIds = [
          ...data.origin.roles,
          ...data.destination.roles,
        ].map((role) => role.id);
        const originRows = sortBy(data.origin.roles, [
          (a, b) => compareLocal(a.name, b.name),
        ]).map((role) => [
          role.name,
          <OrganisationUnitsText
            organisationUnits={role.organisationUnits.map(
              (id) => organisationUnits[id].name
            )}
          />,
          role.isRelated
            ? localeLookup('translations.Additional')
            : localeLookup('translations.Mandatory'),
          role.isConnected
            ? localeLookup('translations.Yes')
            : localeLookup('translations.No'),
        ]);
        const destinationRows = sortBy(data.destination.roles, [
          (a, b) => compareLocal(a.name, b.name),
        ]).map((role) => [
          role.name,
          <OrganisationUnitsText
            organisationUnits={role.organisationUnits.map(
              (id) => organisationUnits[id].name
            )}
          />,
          role.isRelated
            ? localeLookup('translations.Additional')
            : localeLookup('translations.Mandatory'),
          localeLookup('translations.No'),
        ]);
        const showDestinationInfo = destinationRows.length > 0;
        const columnWidths = ['25%', '25%', '25%', '25%'];
        showModal('confirmation', {
          title: localeLookup('translations.Confirm relocation'),
          confirmButtonText: localeLookup('translations.Move'),
          fullWidth: true,
          maxWidth: '900px',
          safeWord: localeLookup('translations.Move'),
          onConfirm: () => {
            hideModal();
            updateElementAreaLocationService(
              elementId,
              areaId,
              destinationAreaId
            ).then(() => {
              queryElements([elementId])
                .then(() => queryAreas([areaId, destinationAreaId]))
                .then(() => queryRoles([updatedRoleIds]));
            });
          },
          body: (
            <>
              <p>
                {localeLookup('translations.Relocation of')}{' '}
                <b>{element.name}</b> {localeLookup('translations.from')}{' '}
                <b>{areas[areaId].name}</b> {localeLookup('translations.to')}{' '}
                <b>{areas[destinationAreaId].name}</b>
              </p>
              <br />
              <h3>{localeLookup('translations.Current connections')}</h3>
              <SimpleTable
                rows={originRows}
                headerRow={headerRow}
                columnWidths={columnWidths}
              />
              <h3>{localeLookup('translations.Future connections')}</h3>

              {showDestinationInfo ? (
                <SimpleTable
                  rows={destinationRows}
                  headerRow={headerRow}
                  columnWidths={columnWidths}
                />
              ) : (
                <p>
                  {localeLookup('translations.None')}
                  <br /> <br />
                </p>
              )}
              <p>
                {localeLookup(
                  'translations.All persons will keep their training history in this element'
                )}
              </p>
            </>
          ),
        });
      });
    };

    showCreateAreaModal = ({ allowSpaceSelection, onCreated }) => {
      const { showModal, hideModal, queryAreas, getAreasAccess } = this.props;
      showModal('createArea', {
        title: localeLookup('translations.Create knowledge area'),
        fullWidth: true,
        allowSpaceSelection,
        maxWidth: '500px',
        onCreated: (id) => {
          hideModal();
          queryAreas([id]).then(async () => {
            await getAreasAccess();
            onCreated?.();
          });
        },
      });
    };

    showCreateRoleModal = ({ onCreated, allowSpaceSelection }) => {
      const {
        organisationUnits,
        showModal,
        hideModal,
        getRolesAccess,
        queryRoles,
      } = this.props;
      showModal('createRole', {
        maxWidth: '500px',
        fullWidth: true,
        organisationUnits,
        allowSpaceSelection,
        onCreated: (id) => {
          hideModal();
          queryRoles([id]).then(async () => {
            await getRolesAccess();
            onCreated?.();
          });
        },
      });
    };

    showCreateSpaceModal = ({ onCreated }) => {
      const {
        organisationUnits,
        showModal,
        hideModal,
        getRolesAccess,
        queryRoles,
        getAllSpaces,
      } = this.props;
      showModal('createSpace', {
        maxWidth: '500px',
        fullWidth: true,
        onCreated: (id) => {
          hideModal();
          getAllSpaces().then(() => {
            onCreated?.();
          });
        },
      });
    };

    showElementVersionsModal = ({ id }) => {
      const { showModal, elements } = this.props;
      const element = elements[id];

      showModal('elementVersions', {
        title: localeLookup('translations.Versions'),
        subtitle: element.name,
        element,
        fullWidth: true,
        maxWidth: '500px',
      });
    };

    showRoleScopeWarningModal = ({ onConfirm, onCancel }) => {
      const { showModal } = this.props;
      showModal('confirmation', {
        title: `${localeLookup('translations.Remove access')}`,
        maxWidth: '500px',
        body: `${localeLookup(
          'translations.You are about to remove your access to this role. Do you want to continue?'
        )}`,
        btnRejectTitle: localeLookup('translations.Cancel'),
        confirmButtonText: localeLookup('translations.Confirm'),
        confirmButtonType: 'alert',
        onCancel: onCancel,
        onConfirm: onConfirm,
      });
    };

    showToggleAreaLinkVisiblityModal = (area, onChanged) => {
      const { showModal } = this.props;
      if (area.champLink.isPublic) {
        showModal('confirmation', {
          title: localeLookup('translations.Activate login requirement'),
          subtitle: area.name,
          body: localeLookup(
            'translations.Only users with a login can open this link'
          ),
          btnRejectTitle: localeLookup('translations.Cancel'),
          confirmButtonText: localeLookup('translations.Activate'),
          confirmButtonType: 'alert',
          onConfirm: () => {
            updateAreaChampLinkVisiblityService(area.id, false).then(() =>
              this.getAreas([area.id]).then(() => {
                onChanged?.();
              })
            );
          },
        });
      } else {
        showModal('confirmation', {
          title: localeLookup('translations.Deactivate login requirement'),
          subtitle: area.name,
          body: localeLookup(
            'translations.This link can be opened without a login'
          ),
          btnRejectTitle: localeLookup('translations.Cancel'),
          confirmButtonText: localeLookup('translations.Deactivate'),
          confirmButtonType: 'alert',
          onConfirm: () => {
            updateAreaChampLinkVisiblityService(area.id, true).then(() =>
              this.getAreas([area.id]).then(() => {
                onChanged?.();
              })
            );
          },
        });
      }
    };

    showToggleElementLinkVisiblityModal = ({
      elementId,
      elementName,
      isPublic,
    }) => {
      const { queryElements, showModal } = this.props;
      if (isPublic) {
        showModal('confirmation', {
          title: localeLookup('translations.Activate login requirement'),
          subtitle: elementName,
          body: localeLookup(
            'translations.Only users with a login can open this link'
          ),
          btnRejectTitle: localeLookup('translations.Cancel'),
          confirmButtonText: localeLookup('translations.Activate'),
          confirmButtonType: 'alert',
          onConfirm: () => {
            updateElementLinkVisibilityService(elementId, false).then(() => {
              queryElements([elementId]);
            });
          },
        });
      } else {
        showModal('confirmation', {
          title: localeLookup('translations.Deactivate login requirement'),
          subtitle: elementName,
          body: localeLookup(
            'translations.This link can be opened without a login'
          ),
          btnRejectTitle: localeLookup('translations.Cancel'),
          confirmButtonText: localeLookup('translations.Deactivate'),
          confirmButtonType: 'alert',
          onConfirm: () => {
            updateElementLinkVisibilityService(elementId, true).then(() => {
              queryElements([elementId]);
            });
          },
        });
      }
    };

    hasAccessToArea = ({ editorIds, ownerId, editableInSpaceIds }) => {
      const { currentUserId, hasAccess, spaces } = this.props;
      const hasAccessRoles = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ]);
      const isSpaceAdmin = editableInSpaceIds.some((spaceId) => {
        return spaces[spaceId]?.administrators.includes(currentUserId);
      });
      const isOwner = ownerId === currentUserId;
      const isEditor = editorIds.includes(currentUserId);
      if (hasAccessRoles || isOwner || isEditor || isSpaceAdmin) return true;
      return false;
    };

    hasAccessToRole = ({ editorIds, ownerId, spaceId }) => {
      const { currentUserId, hasAccess, spaces } = this.props;
      const space = spaces[spaceId];
      const hasAccessRoles = hasAccess([
        ACCESS_LEVELS.champadministrator,
        ACCESS_LEVELS.administrator,
        ACCESS_LEVELS.contentAdministrator,
      ]);
      const isOwner = ownerId === currentUserId;
      const isEditor = editorIds.includes(currentUserId);
      const isSpaceAdmin = space.administrators.includes(currentUserId);
      if (hasAccessRoles || isOwner || isEditor || isSpaceAdmin) return true;
      return false;
    };

    updateAreaCategory = ({ areaId, categoryId }) => {
      updateAreaCategoryService(areaId, categoryId).then(() => {
        this.getAreas([areaId]);
      });
    };

    updateAreaEditors = ({
      areaId,
      ids,
      onChanged,
      showModalOnScopeCancel = true,
    }) => {
      const { areas, getAreasAccess, hideModal } = this.props;
      const area = areas[areaId];
      const hasAccessToAreaAfterChange = this.hasAccessToArea({
        areaId,
        ownerId: area.owner,
        editorIds: ids,
        editableInSpaceIds: Object.keys(area.editableInSpaces),
      });

      if (!hasAccessToAreaAfterChange) {
        this.showAreaScopeWarningModal({
          onCancel: () => {
            if (showModalOnScopeCancel) {
              this.showChangeAreaEditorsModal({
                areaId,
                selectedValues: ids,
                onChanged,
              });
            } else {
              hideModal();
            }
          },
          onConfirm: () => {
            updateAreaEditorsService([areaId], ids, 'module').then(() => {
              this.getAreas([areaId]);
              getAreasAccess();
              onChanged?.(true);
            });
          },
        });
      } else {
        updateAreaEditorsService([areaId], ids, 'module').then(() => {
          this.getAreas([areaId]);
          onChanged?.();
        });
      }
    };

    updateAreaExperts = ({ areaId, expertIds, onChanged }) => {
      updateAreaExpertsService(areaId, expertIds).then(() => {
        this.getAreas([areaId]);
        onChanged?.();
      });
    };

    updateAreaOwner = ({
      areaId,
      ownerId,
      onChanged,
      showModalOnScopeCancel = true,
    }) => {
      const { areas, getAreasAccess, hideModal } = this.props;
      const area = areas[areaId];
      const hasAccessToAreaAfterChange = this.hasAccessToArea({
        areaId,
        ownerId: ownerId,
        editorIds: area.editors,
        editableInSpaceIds: Object.keys(area.editableInSpaces),
      });

      if (hasAccessToAreaAfterChange) {
        updateAreaOwnerService([areaId], ownerId, 'module').then(() => {
          this.getAreas([areaId]);
          onChanged?.();
        });
      } else {
        this.showAreaScopeWarningModal({
          onConfirm: () => {
            updateAreaOwnerService([areaId], ownerId, 'module').then(() => {
              this.getAreas([areaId]);
              getAreasAccess();
              onChanged?.(true);
            });
          },
          onCancel: () => {
            if (showModalOnScopeCancel) {
              this.showChangeAreaOwnerModal({
                areaId,
                selectedValue: ownerId,
                onChanged,
              });
            } else {
              hideModal();
            }
          },
        });
      }
    };

    updateAreaSpaceConnection = async ({ areaId, spaceId, newStateId }) => {
      const { areas, hideModal } = this.props;
      const area = areas[areaId];

      const isEditableInSpace = Object.keys(area.editableInSpaces).includes(
        spaceId
      );
      const isReadOnlyInSpace = Object.keys(area.readOnlyInSpaces).includes(
        spaceId
      );
      const oldStateId = isEditableInSpace
        ? 'editable'
        : isReadOnlyInSpace
        ? 'readonly'
        : 'notConnected';

      const onConfirmChange = () => {
        if (newStateId === 'editable') {
          this.connectAreasToSpaces({
            areaIds: [areaId],
            spaceIds: [spaceId],
            editable: true,
          });
        } else if (newStateId === 'readonly') {
          this.connectAreasToSpaces({
            areaIds: [areaId],
            spaceIds: [spaceId],
            editable: false,
          });
        } else if (newStateId === 'notConnected') {
          this.disconnectAreasFromSpaces({
            areaIds: [areaId],
            spaceIds: [spaceId],
          });
        }
      };

      if (oldStateId === 'editable' && newStateId !== 'editable') {
        const hasAccessToAreaAfterChange = this.hasAccessToArea({
          editorIds: area.editors,
          ownerId: area.owner,
          editableInSpaceIds: Object.keys(area.editableInSpaces).filter(
            (id) => id !== spaceId
          ),
        });
        if (!hasAccessToAreaAfterChange) {
          this.showAreaScopeWarningModal({
            onConfirm: onConfirmChange,
            onCancel: hideModal,
          });
        } else {
          onConfirmChange();
        }
      } else {
        onConfirmChange();
      }
    };

    updateAreaSpaceConnections = async ({
      areaId,
      notConnectedSpaceIds,
      editableSpaceIds,
      readonlySpaceIds,
    }) => {
      const { getAreasAccess } = this.props;
      if (notConnectedSpaceIds.length > 0) {
        await disconnectSpacesFromModulesService(notConnectedSpaceIds, [
          areaId,
        ]);
      }
      if (editableSpaceIds.length > 0) {
        await connectSpacesToModulesService(editableSpaceIds, [areaId], true);
      }
      if (readonlySpaceIds.length > 0) {
        await connectSpacesToModulesService(readonlySpaceIds, [areaId], false);
      }
      await getAreasAccess();
      await this.getAreas([areaId]);
      await getAllSpaces();
    };

    updateRoleEditors = ({
      roleId,
      ids,
      onChanged,
      showModalOnScopeCancel = true,
    }) => {
      const { roles, getRolesAccess, hideModal } = this.props;
      const role = roles[roleId];
      const hasAccessToRoleAfterChange = this.hasAccessToRole({
        roleId,
        editorIds: ids,
        ownerId: role.owner,
        spaceId: role.space,
      });
      if (!hasAccessToRoleAfterChange) {
        this.showRoleScopeWarningModal({
          onCancel: () => {
            if (showModalOnScopeCancel) {
              this.showChangeRoleEditorsModal({
                roleId,
                selectedValues: ids,
                onChanged,
              });
            } else {
              hideModal();
            }
          },
          onConfirm: () => {
            updateRoleEditorsService([roleId], ids, 'role').then(() => {
              onChanged?.(true);
              getRolesAccess();
            });
          },
        });
      } else {
        updateRoleEditorsService([roleId], ids, 'role').then(() => {
          this.getRole(roleId);
          onChanged?.();
        });
      }
    };

    updateRoleOwner = ({
      roleId,
      ownerId,
      onChanged,
      showModalOnScopeCancel = true,
    }) => {
      const { roles, getRolesAccess, hideModal } = this.props;
      const role = roles[roleId];
      const hasAccessToRoleAfterChange = this.hasAccessToRole({
        roleId,
        editorIds: role.editors || [],
        ownerId,
        spaceId: role.space,
      });
      if (!hasAccessToRoleAfterChange) {
        this.showRoleScopeWarningModal({
          onCancel: () => {
            if (showModalOnScopeCancel) {
              this.showChangeRoleOwnerModal({
                roleId,
                selectedValue: ownerId,
                onChanged,
              });
            } else {
              hideModal();
            }
          },
          onConfirm: () => {
            updateRoleOwnerService([roleId], ownerId, 'role').then(() => {
              getRolesAccess();
              onChanged?.();
            });
          },
        });
      } else {
        updateRoleOwnerService([roleId], ownerId, 'role').then(() => {
          this.getRole(roleId);
          onChanged?.();
        });
      }
    };

    updateSpaceAreaConnections = async ({
      spaceId,
      notConnectedAreaIds,
      editableAreaIds,
      readonlyAreaIds,
    }) => {
      const { getAreasAccess, getAllSpaces } = this.props;
      if (notConnectedAreaIds.length > 0) {
        await disconnectSpacesFromModulesService(
          [spaceId],
          notConnectedAreaIds
        );
      }
      if (editableAreaIds.length > 0) {
        await connectSpacesToModulesService([spaceId], editableAreaIds, true);
      }
      if (readonlyAreaIds.length > 0) {
        await connectSpacesToModulesService([spaceId], readonlyAreaIds, false);
      }
      await getAreasAccess();
      await this.getAreas([
        ...notConnectedAreaIds,
        ...editableAreaIds,
        ...readonlyAreaIds,
      ]);
      await getAllSpaces();
    };

    render() {
      return (
        <WrappedComponent
          editorActions={{
            changeAreaElementsOrder: this.changeAreaElementsOrder,
            changeAreaName: this.changeAreaName,
            changeElementExpiration: this.changeElementExpiration,
            changeElementName: this.changeElementName,
            changeElementType: this.changeElementType,
            changeRoleAreasOrder: this.changeRoleAreasOrder,
            changeRoleName: this.changeRoleName,
            changeRoleSpace: this.changeRoleSpace,
            connectAreasToSpaces: this.connectAreasToSpaces,
            connectElementsToRoles: this.connectElementsToRoles,
            createElements: this.createElements,
            disconnectAreasFromRoles: this.disconnectAreasFromRoles,
            disconnectAreasFromSpaces: this.disconnectAreasFromSpaces,
            disconnectElementsFromRoles: this.disconnectElementsFromRoles,
            getRole: this.getRole,
            showChangeAreaCategoryModal: this.showChangeAreaCategoryModal,
            showChangeAreaEditorsModal: this.showChangeAreaEditorsModal,
            showChangeAreaExpertsModal: this.showChangeAreaExpertsModal,
            showChangeAreaOwnerModal: this.showChangeAreaOwnerModal,
            showChangeAreaRolesModal: this.showChangeAreaRolesModal,
            showChangeElementConnectionsModal:
              this.showChangeElementConnectionsModal,
            showChangeElementDescriptionModal:
              this.showChangeElementDescriptionModal,
            showChangeElementLocationModal: this.showChangeElementLocationModal,
            showChangeElementResponsibleModal:
              this.showChangeElementResponsibleModal,
            showChangeOrganisationUnitModal:
              this.showChangeOrganisationUnitModal,
            showChangeRoleAreasModal: this.showChangeRoleAreasModal,
            showChangeRoleDescriptionModal: this.showChangeRoleDescriptionModal,
            showChangeRoleEditorsModal: this.showChangeRoleEditorsModal,
            showChangeRoleOwnerModal: this.showChangeRoleOwnerModal,
            showConfirmAreaDeleteModal: this.showConfirmAreaDeleteModal,
            showConfirmElementDeleteModal: this.showConfirmElementDeleteModal,
            showConfirmRoleDeleteModal: this.showConfirmRoleDeleteModal,
            showCreateAreaModal: this.showCreateAreaModal,
            showToggleAreaLinkVisiblityModal:
              this.showToggleAreaLinkVisiblityModal,
            showToggleElementLinkVisiblityModal:
              this.showToggleElementLinkVisiblityModal,
            updateAreaCategory: this.updateAreaCategory,
            updateAreaEditors: this.updateAreaEditors,
            updateAreaExperts: this.updateAreaExperts,
            updateAreaOwner: this.updateAreaOwner,
            updateRoleEditors: this.updateRoleEditors,
            updateRoleOwner: this.updateRoleOwner,
            showCreateRoleModal: this.showCreateRoleModal,
            showCreateSpaceModal: this.showCreateSpaceModal,
            showChangeAreaNameModal: this.showChangeAreaNameModal,
            showChangeRoleNameModal: this.showChangeRoleNameModal,
            showChangeSpaceNameModal: this.showChangeSpaceNameModal,
            showConfirmSpaceDeleteModal: this.showConfirmSpaceDeleteModal,
            showChangeRoleSpaceModal: this.showChangeRoleSpaceModal,
            showChangeAreaSpaceModal: this.showChangeAreaSpaceModal,
            hasAccessToAreaWithFutureProperties: this.hasAccessToArea,
            updateAreaSpaceConnection: this.updateAreaSpaceConnection,
            showChangeSpaceAdministratorsModal:
              this.showChangeSpaceAdministratorsModal,
            showChangeSpaceCreateRolePermissionModal:
              this.showChangeSpaceCreateRolePermissionModal,
            showChangeSpaceCreateAreaPermissionModal:
              this.showChangeSpaceCreateAreaPermissionModal,
            showChangeSpaceAreasModal: this.showChangeSpaceAreasModal,
            showChangePersonRoleEditorshipsModal:
              this.showChangePersonRoleEditorshipsModal,
            showChangePersonAreaEditorshipsModal:
              this.showChangePersonAreaEditorshipsModal,
            showChangePersonRoleOwnershipsModal:
              this.showChangePersonRoleOwnershipsModal,
            showChangePersonAreaOwnershipsModal:
              this.showChangePersonAreaOwnershipsModal,
            showChangePersonSpaceAdministratorModal:
              this.showChangePersonSpaceAdministratorModal,
            showChangePersonSpaceCreateAreaModal:
              this.showChangePersonSpaceCreateAreaModal,
            showChangePersonSpaceCreateRoleModal:
              this.showChangePersonSpaceCreateRoleModal,
            showChangePersonOrganisationAdministratorModal:
              this.showChangePersonOrganisationAdministratorModal,
            showElementVersionsModal: this.showElementVersionsModal,
          }}
          {...this.props}
        />
      );
    }
  }
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithModals(withAccessControl(WithEditorActionsComponent)));
};

export default WithEditorActions;
