import { parse } from 'date-fns';
import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Yup from 'yup';
import Button from '../../components/Button';
import DragAndDropHandle from '../../components/DragAndDropHandle';
import { Grid } from '../../components/Grid';
import withAccessControl from '../../components/HOC/withAccessControl';
import WithModals from '../../components/HOC/withModals';
import WithTrainingActions from '../../components/HOC/withTrainingActions';
import { Header } from '../../components/Header';
import Icon from '../../components/Icon';
import KnowledgeElementContentButton from '../../components/KnowledgeElementContentButton';
import ListHeader from '../../components/ListHeader';
import LoadScreen from '../../components/LoadScreen';
import MultiSelect from '../../components/MultiSelect';
import Page from '../../components/Page';
import PersonWrapper from '../../components/PersonWrapper';
import ProgressBar from '../../components/ProgressBar';
import { RadioButtonGroup } from '../../components/RadioButtonGroup';
import SessionStatusButton from '../../components/SessionStatusButton';
import Tabs from '../../components/Tabs';
import Text from '../../components/Text';
import Tooltip from '../../components/Tooltip';
import TruncatedArrayText from '../../components/TruncatedArrayText';
import { FakeFieldButton } from '../../components/formElements/FakeFieldButton';
import Field from '../../components/formElements/Field';
import FormWrapper from '../../components/formElements/FormWrapper';
import TextField from '../../components/formElements/TextField';
import localeLookup from '../../config/locale';
import { ACCESS_LEVELS, PERSON_STATES } from '../../constants';
import {
  updateTrainingSessionCompletionType,
  updateTrainingSessionElementsService,
  updateTrainingSessionLocation,
  updateTrainingSessionName,
  updateTrainingSessionOrganisers,
} from '../../services/trainingSessionService';
import { getAllAreas } from '../../slices/areasSlice';
import { getAllElements, queryElements } from '../../slices/elementsSlice';
import { getAllOrganisationUnits } from '../../slices/organisationUnitsSlice';
import { getPersons } from '../../slices/personsSlice';
import {
  queryTrainingSession,
  selectTrainingSession,
  getTrainingSessionStatus,
} from '../../slices/trainingSessionsSlice';
import {
  compareLocal,
  formatDate,
  formatTime,
  getQueryStringParams,
  moveArrayItemToIndex,
  sortBy,
} from '../../utils/helpers';
import StatusLabel from '../../components/StatusLabel';

const mapStateToProps = (state, ownProps) => ({
  persons: state.persons,
  timeFormat: state.rootmenu?.timeFormat,
  session: selectTrainingSession(state, ownProps.match.params.sessionId),
  elements: state.elements,
  currentUserId: state.user.employeeId,
  dateFormat: state.rootmenu?.dateFormat,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllElements,
      queryElements,
      getPersons,
      getAllAreas,
      queryTrainingSession,
      getAllOrganisationUnits,
      getTrainingSessionStatus,
    },
    dispatch
  );
class TrainingSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeView: 'training',
      session: {},
      isLoading: true,
      defaultElementOrder: [],
    };
  }
  async componentDidMount() {
    const { getAllElements, getAllAreas, getAllOrganisationUnits } = this.props;
    this.getQueryParams();
    await Promise.all([
      getAllAreas(),
      getAllElements(),
      getAllOrganisationUnits(),
    ]);
    this.getSession();
  }

  getCanEdit = ({ newOrganisers } = {}) => {
    const { session, currentUserId, hasAccess } = this.props;
    if (!session) return false;
    const isAdmin = hasAccess([
      ACCESS_LEVELS.champadministrator,
      ACCESS_LEVELS.administrator,
      ACCESS_LEVELS.trainingSessionAdministrator,
    ]);

    const isOrganiser = newOrganisers
      ? newOrganisers.includes(currentUserId)
      : session.organisers.includes(currentUserId);

    const canEdit = isAdmin || isOrganiser;

    return canEdit;
  };

  getSession = () => {
    const {
      match,
      queryTrainingSession,
      queryElements,
      getTrainingSessionStatus,
    } = this.props;
    if (!match?.params?.sessionId) return;
    return queryTrainingSession(match.params.sessionId).then((data) => {
      const session = data[match.params.sessionId];
      const sessionElements = session.elements;
      if (sessionElements.length > 0) {
        return getTrainingSessionStatus(session.id).then(() => {
          queryElements(sessionElements).then(() => {
            this.setState({
              isLoading: false,
              defaultElementOrder: sessionElements,
            });
          });
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  };

  getQueryParams = () => {
    const { location } = this.props;
    const queryParams = getQueryStringParams(location.search);
    if (queryParams.view) {
      this.setState({
        activeView: queryParams.view || 'training',
      });
    }
  };

  setQueryParams = () => {
    const { activeView } = this.state;
    const { history } = this.props;
    const serialize = (obj) =>
      Object.keys(obj)
        .filter((key) => obj[key])
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');
    history.replace({
      search: serialize({ view: activeView }),
      state: history.location.state,
    });
  };

  onBlurSettingField = (e, fieldName) => {
    const { session, history, location } = this.props;
    const { name, value } = e.target;
    switch (name || fieldName) {
      case 'name':
        if (session.name !== value && value !== '') {
          updateTrainingSessionName(session.id, value).then(() => {
            this.getSession();
            history.replace(location.pathname, {
              ...location.state,
              title: value,
            });
          });
        }
        break;
      case 'startDate':
        console.log(name, value);
        break;
      case 'endDate':
        console.log(name, value);
        break;
      case 'startTime':
        console.log(name, value);
        break;
      case 'endTime':
        console.log(name, value);
        break;
      case 'location':
        if (session.location !== value) {
          updateTrainingSessionLocation(session.id, value).then(
            this.getSession
          );
        }
        break;
    }
  };

  onChangeCompletionType = (value) => {
    const { session } = this.props;
    updateTrainingSessionCompletionType(session.id, value).then(() => {
      this.getSession();
    });
  };

  onChangeOrganisers = (ids) => {
    const { session, history, showModal, currentUserId } = this.props;

    if (session.organisers.length !== ids.length) {
      const canEditAfterChange = this.getCanEdit({
        newOrganisers: ids,
      });
      const isTrainer = session.trainers.includes(currentUserId);
      if (!canEditAfterChange && isTrainer) {
        showModal('confirmation', {
          title: `${localeLookup('translations.Remove access')}`,
          maxWidth: '500px',
          body: `${localeLookup(
            'translations.You are about to remove your editing access to this session. Do you want to continue?'
          )}`,
          btnRejectTitle: localeLookup('translations.Cancel'),
          confirmButtonText: localeLookup('translations.Confirm'),
          confirmButtonType: 'alert',
          onCancel: () => {},
          onConfirm: () => {
            this.setState({ activeView: 'training' });
            updateTrainingSessionOrganisers(session.id, ids).then(
              this.getSession
            );
          },
        });
      } else if (!canEditAfterChange && !isTrainer) {
        showModal('confirmation', {
          title: `${localeLookup('translations.Remove access')}`,
          maxWidth: '500px',
          body: `${localeLookup(
            'translations.You are about to remove your access to this session. Do you want to continue?'
          )}`,
          btnRejectTitle: localeLookup('translations.Cancel'),
          confirmButtonText: localeLookup('translations.Confirm'),
          confirmButtonType: 'alert',
          onCancel: () => {},
          onConfirm: () => {
            this.setState({ activeView: 'training' });
            updateTrainingSessionOrganisers(session.id, ids).then(() => {
              history.replace('/training/sessions');
            });
          },
        });
      } else {
        updateTrainingSessionOrganisers(session.id, ids).then(this.getSession);
      }
    }
  };

  onChangeView = (view) => {
    this.setState(
      {
        activeView: view.id,
      },
      this.setQueryParams
    );
  };

  onClickArchive = () => {
    const { session, trainingActions } = this.props;
    trainingActions.showArchiveTrainingSessionModal({ id: session.id });
  };

  onClickCancel = () => {
    const { session, trainingActions } = this.props;
    trainingActions.showCancelTrainingSessionModal({ id: session.id });
  };

  onClickChangeDates = () => {
    const { session, trainingActions } = this.props;
    trainingActions.showChangeTrainingSessionDatesModal({ id: session.id });
  };

  onClickEditElements = () => {
    const { trainingActions, session, queryElements } = this.props;
    trainingActions.showChangeTrainingSessionElementsModal({
      id: session.id,
      onChanged: () => {
        this.getSession().then(() => {
          if (this.props.session.elements.length > 0) {
            queryElements(this.props.session.elements).then(() => {
              this.setState({
                defaultElementOrder: this.props.session.elements,
              });
            });
          } else {
            this.setState({
              defaultElementOrder: this.props.session.elements,
            });
          }
        });
      },
    });
  };

  onClickEditParticipants = () => {
    const { trainingActions, session } = this.props;
    trainingActions.showChangeTrainingSessionParticipantsModal({
      id: session.id,
      onChanged: this.getSession,
    });
  };

  onClickEditTrainers = () => {
    const { trainingActions, session } = this.props;
    trainingActions.showChangeTrainingSessionTrainersModal({
      id: session.id,
      onChanged: this.getSession,
    });
  };

  onClickResetCompletions = () => {
    const { session, trainingActions } = this.props;
    trainingActions.showTrainingSessionResetCompletionsModal({
      sessionId: session.id,
      onReset: this.getSession,
    });
  };

  onClickResume = () => {
    const { session, trainingActions } = this.props;
    trainingActions.onClickResumeTrainingSession({ id: session.id });
  };
  onClickRestore = () => {
    const { session, trainingActions } = this.props;
    trainingActions.onClickRestoreTrainingSession({ id: session.id });
  };

  onClickShowElementDescription = (id) => {
    const { elements, showModal } = this.props;
    const element = elements[id];
    if (!element) return;
    const { description, files, name } = element;
    const descriptionLength =
      description && description.replace(/<(?:.|\n)*?>/gm, '').trim().length;
    if (descriptionLength > 0 || (files && files.length > 0)) {
      showModal('richText', {
        title: name,
        description,
        files,
        fullWidth: true,
        maxWidth: '700px',
        elementId: id,
      });
    }
  };

  onDragEnd = (result) => {
    const { session } = this.props;
    const { destination, source } = result;
    if (!destination || !source) return;
    const isDroppedAtSamePlace =
      destination.droppableId === source.droppableId &&
      destination.index === source.index;

    if (!destination || isDroppedAtSamePlace) return;
    const newElementOrder = moveArrayItemToIndex({
      arr: session.elements,
      currentIndex: source.index,
      newIndex: destination.index,
    });
    // Update state
    this.setState({
      defaultElementOrder: newElementOrder,
    });

    // Persist order
    updateTrainingSessionElementsService(session.id, newElementOrder).then(
      () => {
        this.getSession();
      }
    );
  };

  renderHeader() {
    const { session } = this.props;
    const { activeView } = this.state;
    const {
      startDate,
      endDate,
      startTime,
      endTime,
      location,
      numberOfCompletedRegistrations,
      totalNumberOfRegistrations,
      state,
    } = session;
    const renderDateAndTime = () => {
      if (startDate && endDate) {
        const parsedStartDate = parse(startDate, 'yyyy-MM-dd', new Date());
        const parsedEndDate = parse(endDate, 'yyyy-MM-dd', new Date());

        return `${formatDate(parsedStartDate)} ${
          startTime ? startTime : ''
        } - ${formatDate(parsedEndDate)} ${endTime ? endTime : ''}`;
      }
      if (startDate && !endDate) {
        const parsedStartDate = parse(startDate, 'yyyy-MM-dd', new Date());
        return `${formatDate(parsedStartDate)} ${startTime ? startTime : ''} ${
          endTime ? `- ${endTime}` : ''
        }`;
      }
      if (!startDate && endDate) {
        const parsedEndDate = parse(endDate, 'yyyy-MM-dd', new Date());
        return `${formatDate(parsedEndDate)} ${startTime ? startTime : ''} ${
          endTime ? `- ${endTime}` : ''
        }`;
      }
    };

    const canEdit = this.getCanEdit();

    return (
      <Header
        className="training-session__header"
        leftComponentContainerClass="training-session__header-left"
        leftComponent={
          <>
            {startDate || endDate ? (
              <div className="training-session__header-detail">
                <Icon
                  className="training-session__header-detail-icon"
                  color="grey"
                  kind={!startDate && endDate ? 'flag' : 'calendar-full'}
                ></Icon>
                <Text className="training-session__header-detail-text">
                  {renderDateAndTime()}
                </Text>
              </div>
            ) : null}
            {location ? (
              <div className="training-session__header-detail">
                <Icon
                  className="training-session__header-detail-icon"
                  color="grey"
                  kind="map-marker"
                ></Icon>
                <Text className="training-session__header-detail-text">
                  {location}
                </Text>
              </div>
            ) : null}
            <div className="training-session__header-detail">
              <Icon
                className="training-session__header-detail-icon"
                color="grey"
                kind="sign"
              ></Icon>
              <Text className="training-session__header-progress-text">
                {numberOfCompletedRegistrations}/{totalNumberOfRegistrations}
              </Text>
              <ProgressBar
                rounded
                size="lg"
                className="training-session__header-progress-bar"
                percentage={Math.ceil(
                  (numberOfCompletedRegistrations /
                    totalNumberOfRegistrations) *
                    100
                )}
              />
            </div>
            {state === 'Cancelled' && (
              <StatusLabel noMargin round color="red">
                {localeLookup('translations.Cancelled')}
              </StatusLabel>
            )}
            {state === 'Archived' && (
              <StatusLabel noMargin round size="small" color="grey">
                {localeLookup('translations.Archived')}
              </StatusLabel>
            )}
          </>
        }
        rightComponent={
          canEdit ? (
            <Tabs
              activeTab={activeView}
              tabWidth="100px"
              activeTabColor="green"
              tabColor="grey"
              rounded
              onChangeTab={this.onChangeView}
              tabs={[
                { name: localeLookup('translations.Training'), id: 'training' },
                {
                  name: localeLookup('translations.Settings'),
                  id: 'settings',
                },
              ]}
            />
          ) : null
        }
      />
    );
  }
  renderElements() {
    const { defaultElementOrder } = this.state;
    const { elements } = this.props;
    const canEdit = this.getCanEdit();

    if (defaultElementOrder.length === 0)
      return (
        <Text italic color="dark-grey" style={{ padding: '1rem' }}>
          {localeLookup('translations.No elements added to session')}
        </Text>
      );

    if (canEdit) {
      return (
        <DragDropContext nonce={cspNonce} onDragEnd={this.onDragEnd}>
          <Droppable droppableId="elements">
            {(provided) => (
              <div
                className="editor__elements"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {defaultElementOrder.map((id, i) => {
                  if (elements[id]) {
                    const element = elements[id];
                    if (!element) return null;
                    return (
                      <Draggable
                        key={id}
                        draggableId={id}
                        index={i}
                        //isDragDisabled={!canDrag}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <li key={id} className="training-session__element">
                              <div {...provided.dragHandleProps}>
                                <DragAndDropHandle className="training-session__element-handle" />
                              </div>
                              <div className="training-session__element-body">
                                <p className="training-session__element-title">
                                  {element.name}
                                </p>
                              </div>
                              <KnowledgeElementContentButton
                                files={element.files}
                                description={element.description}
                                onClick={() =>
                                  this.onClickShowElementDescription(id)
                                }
                              />
                            </li>
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    } else {
      return (
        <div className="editor__elements">
          {defaultElementOrder.map((id) => {
            if (elements[id]) {
              const element = elements[id];
              if (!element) return null;
              return (
                <li key={id} className="training-session__element">
                  <div className="training-session__element-body">
                    <p className="training-session__element-title">
                      {element.name}
                    </p>
                  </div>
                  <KnowledgeElementContentButton
                    files={element.files}
                    description={element.description}
                    onClick={() => this.onClickShowElementDescription(id)}
                  />
                </li>
              );
            }
          })}
        </div>
      );
    }
  }
  renderParticipants() {
    const { session, persons, trainingActions } = this.props;
    const { participants } = session;
    const showStatusButton =
      (session.completionType === 'SessionEmployeeSignature' ||
        session.completionType === 'SessionMultipartSignature') &&
      session.elements.length > 0;

    const sortedParticipants = sortBy(
      participants,
      [
        (a, b) => {
          const personAName = persons[a].name;
          const personBName = persons[b].name;
          return compareLocal(personAName, personBName);
        },
      ],
      ['asc']
    );

    if (sortedParticipants.length === 0)
      return (
        <Text italic color="dark-grey" style={{ padding: '1rem' }}>
          {localeLookup('translations.No participants added to session')}
        </Text>
      );

    return (
      <ul className="training-session__participants">
        {sortedParticipants.map((id) => {
          const participantElementsStatus = session.status[id]?.elements;
          const numberOfCompletedElements = participantElementsStatus
            ? Object.values(participantElementsStatus).reduce(
                (acc, element) => {
                  if (element.participantApproval) return acc + 1;
                  return acc;
                },
                0
              )
            : 0;
          return (
            <li key={id} className="training-session__participant">
              <div className="training-session__participant-left">
                <PersonWrapper
                  id={id}
                  render={({ name, color, tooltip }) => (
                    <Text
                      className="training-session__participant-name"
                      as="span"
                      color={color}
                    >
                      <Tooltip tooltip={tooltip}>
                        <span>{name}</span>
                      </Tooltip>
                    </Text>
                  )}
                />
              </div>
              {showStatusButton && (
                <SessionStatusButton
                  context="participant"
                  totalNumber={session.elements.length}
                  completedNumber={numberOfCompletedElements}
                  icon="user-sign"
                  onClick={() => {
                    trainingActions.showTrainingSessionParticipantCompletionModal(
                      {
                        sessionId: session.id,
                        participantId: id,
                        onCompleted: this.getSession,
                      }
                    );
                  }}
                />
              )}
            </li>
          );
        })}
      </ul>
    );
  }
  renderTrainers() {
    const { session, persons, trainingActions } = this.props;
    const { trainers } = session;
    const showStatusButton =
      (session.completionType === 'SessionTrainerSignature' ||
        session.completionType === 'SessionMultipartSignature') &&
      session.elements.length > 0;

    const numberOfTotalTrainerApprovals =
      session.elements.length * session.participants.length;
    const numberOfCompletedTrainerApprovals = session.elements.reduce(
      (acc, elementId) => {
        const numberOfTrainerApprovalsForElement = Object.keys(
          session.status
        ).reduce((acc, participantId) => {
          if (
            session.status[participantId].elements[elementId]?.trainerApproval
          )
            return acc + 1;
          return acc;
        }, 0);
        return acc + numberOfTrainerApprovalsForElement;
      },
      0
    );

    if (trainers.length === 0)
      return (
        <Text italic color="dark-grey" style={{ padding: '1rem' }}>
          {localeLookup('translations.No trainers added to session')}
        </Text>
      );

    const sortedTrainers = sortBy(
      trainers,
      [
        (a, b) => {
          const personAName = persons[a].name;
          const personBName = persons[b].name;
          return compareLocal(personAName, personBName);
        },
      ],
      ['asc']
    );
    const trainerNames = sortedTrainers.map((id) => (
      <PersonWrapper
        key={id}
        id={id}
        render={({ name, color, tooltip, suffix }) => (
          <Text as="span" color={color}>
            <span>{name}</span>
          </Text>
        )}
      />
    ));
    const trainersTooltip = sortedTrainers.map((id) => {
      return (
        <PersonWrapper
          key={id}
          id={id}
          render={({ name, color, tooltip, suffix }) => (
            <Text color={color}>
              <span>
                {name} {suffix}
              </span>
            </Text>
          )}
        />
      );
    });

    return (
      <ul className="training-session__participants">
        <li className="training-session__participant">
          <div className="training-session__participant-left">
            <Text className="training-session__participant-name">
              <TruncatedArrayText
                tooltip={trainersTooltip}
                countSuffixText={localeLookup('translations.Trainers')}
                items={trainerNames}
                limit={4}
                render
              ></TruncatedArrayText>
            </Text>
          </div>
          {showStatusButton && (
            <SessionStatusButton
              onClick={() => {
                trainingActions.showTrainingSessionTrainerCompletionModal({
                  sessionId: session.id,
                  onCompleted: this.getSession,
                });
              }}
              totalNumber={numberOfTotalTrainerApprovals}
              completedNumber={numberOfCompletedTrainerApprovals}
              icon="sign"
            />
          )}
        </li>
      </ul>
    );
  }
  renderMain() {
    const {
      persons,
      timeFormat,
      session,
      hasAccess,
      currentUserId,
      dateFormat,
    } = this.props;
    const { activeView } = this.state;

    const canEdit = this.getCanEdit();

    return (
      <div className="training-session__main">
        <div className="training-session__main-left">
          <ListHeader
            className="training-session__main-list-title"
            title={localeLookup('translations.Elements')}
            icon={canEdit ? 'pencil' : null}
            onIconClick={this.onClickEditElements}
          />
          {this.renderElements()}
        </div>
        <div className="training-session__main-right">
          <ListHeader
            className="training-session__main-list-title"
            title={localeLookup('translations.Trainers')}
            icon={canEdit ? 'pencil' : null}
            onIconClick={this.onClickEditTrainers}
          />
          {this.renderTrainers()}
          <ListHeader
            className="training-session__main-list-title"
            title={localeLookup('translations.Participants')}
            icon={canEdit ? 'pencil' : null}
            onIconClick={this.onClickEditParticipants}
            showTopBorder={session.trainers.length === 0}
          />
          {this.renderParticipants()}
        </div>
      </div>
    );
  }
  renderFooter() {
    const { session } = this.props;
    const { activeView } = this.state;
    if (activeView === 'settings') return null;
    return (
      <footer className="training-session__footer">
        <Button
          disabled={session.numberOfCompletedRegistrations <= 0}
          kind="alert"
          size="small"
          onClick={this.onClickResetCompletions}
        >
          {localeLookup('translations.Reset completions')}
        </Button>
      </footer>
    );
  }
  renderSettings() {
    const { persons, timeFormat, session, dateFormat } = this.props;
    const { activeView } = this.state;

    const getDateFormat = () => {
      const DATE_FORMAT_STRINGS = {
        DayMonthYear: 'dd.MM.yyyy',
        MonthDayYear: 'MM.dd.yyyy',
        YearMonthDay: 'yyyy.MM.dd',
      };
      const dateFormatString = DATE_FORMAT_STRINGS[dateFormat] || 'dd.MM.yyyy';
      return dateFormatString;
    };

    const organiserOptions = [
      ...sortBy(
        Object.keys(persons)
          .filter((id) => persons[id].state === PERSON_STATES.ACTIVE)
          .map((id) => {
            const person = persons[id];
            return {
              label: persons[id].name,
              value: id,
              searchString: `${person.name}${person.employeeNumber}${person.initials}`,
            };
          }),
        [(a, b) => compareLocal(a.label, b.label)]
      ),
    ];
    const filterOption = (candidate, input) => {
      if (input) {
        if (candidate.data.searchString) {
          return candidate.data.searchString
            .toLowerCase()
            .includes(input.toLowerCase());
        }
        return false;
      }
      return true;
    };
    const validationSchema = Yup.object().shape({
      name: Yup.string().required(
        localeLookup('translations.Name is required')
      ),
    });

    const startTimeParsed = session.startTime
      ? parse(session.startTime, 'HH:mm', new Date())
      : null;
    const endTimeParsed = session.endTime
      ? parse(session.endTime, 'HH:mm', new Date())
      : null;

    return (
      <div className="training-session__settings">
        <FormWrapper
          validateOnMount
          validationSchema={validationSchema}
          initialValues={{
            name: session.name,
            location: session.location,
            organisers: session.organisers,
          }}
        >
          {({
            values,
            handleChange,
            touched,
            errors,
            handleBlur,
            isValid,
            setFieldValue,
          }) => {
            const onBlurField = (e, name, value) => {
              handleBlur(e);
              if (isValid) {
                this.onBlurSettingField(e, name, value);
              }
            };
            return (
              <div className="training-session__main-settings">
                <div className="training-session__main-form">
                  <TextField
                    required
                    autoFocus
                    placeholder={localeLookup('translations.Name')}
                    name="name"
                    id="name"
                    onBlur={onBlurField}
                    onChange={handleChange}
                    error={touched.name && errors.name}
                    value={values.name}
                    label={localeLookup('translations.Name')}
                  />
                  <Grid gridTemplateColumns="1fr 1fr" columnGap="1rem">
                    <FakeFieldButton
                      onClick={this.onClickChangeDates}
                      label={localeLookup('translations.Start date')}
                      placeholder={getDateFormat().toUpperCase()}
                      value={
                        session.startDate
                          ? formatDate(session.startDate, null)
                          : ''
                      }
                    />
                    <FakeFieldButton
                      onClick={this.onClickChangeDates}
                      placeholder="HH:MM"
                      label={localeLookup('translations.Start time')}
                      value={
                        startTimeParsed ? formatTime(startTimeParsed, null) : ''
                      }
                    />
                    <FakeFieldButton
                      onClick={this.onClickChangeDates}
                      label={localeLookup('translations.End date')}
                      placeholder={getDateFormat().toUpperCase()}
                      value={
                        session.endDate ? formatDate(session.endDate, null) : ''
                      }
                    />
                    <FakeFieldButton
                      onClick={this.onClickChangeDates}
                      placeholder="HH:MM"
                      label={localeLookup('translations.End time')}
                      value={
                        endTimeParsed ? formatTime(endTimeParsed, null) : ''
                      }
                    />
                  </Grid>
                  <TextField
                    placeholder={localeLookup('translations.Location')}
                    name="location"
                    id="location"
                    onBlur={onBlurField}
                    onChange={handleChange}
                    error={touched.location && errors.location}
                    value={values.location}
                    label={localeLookup('translations.Location')}
                  />
                  <Field label={localeLookup('translations.Organisers')}>
                    <MultiSelect
                      onChange={(selectedOptions) =>
                        this.onChangeOrganisers(
                          selectedOptions.map((option) => option.value)
                        )
                      }
                      onBlur={(e) => {
                        onBlurField(e, 'organisers', values.organisers);
                      }}
                      defaultValue={values.organisers.reduce((acc, id) => {
                        const option = organiserOptions.find(
                          (option) => option.value === id
                        );
                        if (option) {
                          acc.push(option);
                        }
                        return acc;
                      }, [])}
                      name="organisers"
                      filterOption={filterOption}
                      options={organiserOptions}
                      placeholder={localeLookup(
                        'translations.Select organisers'
                      )}
                    />
                  </Field>

                  <RadioButtonGroup
                    groupName="completionType"
                    label={`${localeLookup(
                      'translations.Documentation type'
                    )}:`}
                    onChange={(e) => {
                      this.onChangeCompletionType(e.target.id);
                    }}
                    options={[
                      {
                        value: 'SessionEmployeeSignature',
                        label: localeLookup(
                          'translations.Participant signature'
                        ),
                        isChecked:
                          session.completionType === 'SessionEmployeeSignature',
                      },
                      {
                        value: 'SessionTrainerSignature',
                        label: localeLookup('translations.Trainer signature'),
                        isChecked:
                          session.completionType === 'SessionTrainerSignature',
                      },
                      {
                        value: 'SessionMultipartSignature',
                        label: localeLookup('translations.Multipart signature'),
                        isChecked:
                          session.completionType ===
                          'SessionMultipartSignature',
                      },
                    ]}
                  />
                  <div className="training-session__main-settings-buttons">
                    {session.state !== 'Archived' && (
                      <>
                        {session.state === 'Cancelled' ? (
                          <Button
                            kind="darkui"
                            icon="calendar-check"
                            onClick={this.onClickResume}
                          >
                            {localeLookup('translations.Resume')}
                          </Button>
                        ) : (
                          <Button
                            kind="cancel"
                            icon="calendar-cross"
                            onClick={this.onClickCancel}
                          >
                            {localeLookup('translations.Cancel session')}
                          </Button>
                        )}
                      </>
                    )}
                    {session.state === 'Archived' ? (
                      <Button
                        kind="darkui"
                        icon="undelete"
                        onClick={this.onClickRestore}
                      >
                        {localeLookup('translations.Restore')}
                      </Button>
                    ) : (
                      <Button
                        kind="alert"
                        icon="trash2"
                        onClick={this.onClickArchive}
                      >
                        {localeLookup('translations.Archive')}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            );
          }}
        </FormWrapper>
      </div>
    );
  }
  render() {
    const { session } = this.props;
    const { isLoading, activeView } = this.state;
    if (isLoading || !session) return <LoadScreen></LoadScreen>;
    return (
      <Page>
        <div className="training-session">
          {this.renderHeader()}
          {activeView === 'settings'
            ? this.renderSettings()
            : this.renderMain()}
          {this.renderFooter()}
        </div>
      </Page>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithTrainingActions(WithModals(withAccessControl(TrainingSession))));
