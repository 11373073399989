/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import cx from 'classnames';
import React, { PureComponent } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import localeLookup from '../../config/locale';
import { ACCESS_LEVELS } from '../../constants';
import { getAreaDashbordService } from '../../services/dashboardService';
import { queryAreas } from '../../slices/areasSlice';
import { queryElements } from '../../slices/elementsSlice';
import { queryRoles } from '../../slices/rolesSlice';
import {
  compareLocal,
  insertStringInString,
  sortBy,
} from '../../utils/helpers';
import Accordion from '../Accordion';
import AreaElementConnectionCount from '../AreaElementConnectionCount';
import ContextMenu from '../contextMenu/ContextMenu';
import ContextMenuItem from '../contextMenu/ContextMenuItem';
import KnowledgeDashboard from '../dashboard/KnowledgeDashboard';
import withAccessControl from '../HOC/withAccessControl';
import WithEditorActions from '../HOC/withEditorActions';
import Icon from '../Icon';
import IconWithCount from '../IconWithCount';
import InlineFieldEditor from '../InlineFieldEditor';
import Loader from '../Loader';
import Overlay from '../Overlay';
import Portal from '../Portal';
import SwitchCheckbox from '../SwitchCheckbox';
import CreateElementBox from '../CreateElementBox';
import DesignboardKnowledgeElement from './DesignboardKnowledgeElement';
import TruncatedArrayText from '../TruncatedArrayText';

const mapStateToProps = (state) => {
  const { user, roles, wildcardPersons, categories, spaces } = state;
  return {
    currentUserId: user.employeeId,
    roles,
    wildcardPersons,
    categories,
    spaces: spaces.spaces,
    spacesEnabled: spaces.enabled,
    activeSpaceId: spaces.activeSpaceId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ queryElements, queryAreas, queryRoles }, dispatch);

class DesignboardKnowledgeArea extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAddingElement: false,
      isEditingName: false,
      isExpanded: false,
      isLoadingElements: false,
      showDashboardOverlay: false,
    };
  }

  updateArea = () => {
    const { area, queryAreas } = this.props;
    queryAreas([area.id]);
  };

  getElementConnectionsCount = () => {
    const { area, roleElementIds } = this.props;
    const areaElementIdsConnectedToRole = area?.knowledgeElements?.reduce(
      (acc, areaId) => {
        if (roleElementIds.includes(areaId)) return acc + 1;
        return acc;
      },
      0
    );
    if (!area.knowledgeElements) return { connected: 0, total: 0 };
    return {
      connected: areaElementIdsConnectedToRole,
      total: area.knowledgeElements.length,
    };
  };

  onClickAddElement = () => {
    this.setState({
      isAddingElement: true,
    });
  };

  onClickDisconnectAreaFromRole = () => {
    const { area, roleId, editorActions } = this.props;
    editorActions.disconnectAreasFromRoles({
      roleIds: [roleId],
      areaIds: [area.id],
    });
  };

  onClickEditSpace = () => {
    const { area, editorActions } = this.props;
    editorActions.showChangeAreaSpaceModal({ areaId: area.id });
  };

  onBlurEditName = () => {
    this.setState({
      isEditingName: false,
    });
  };

  onClickDelete = () => {
    const { onClickDeleteArea, area } = this.props;
    onClickDeleteArea(area.id);
  };

  onClickEditCategory = () => {
    const { area, onClickChangeCategory } = this.props;
    onClickChangeCategory(area.id);
  };

  onClickEditEditors = () => {
    const { area, onClickChangeEditors } = this.props;
    onClickChangeEditors({ areaId: area.id });
  };

  onClickEditExperts = () => {
    const { area, onClickAddExpert } = this.props;
    onClickAddExpert({ areaId: area.id });
  };

  onClickEditName = (e) => {
    e.stopPropagation();
    this.setState({
      isEditingName: true,
    });
  };

  onClickEditOwner = () => {
    const { area, onClickEditAreaOwner } = this.props;
    onClickEditAreaOwner({ areaId: area.id });
  };

  onClickOpenInKnowledgeEditor = (e) => {
    const { history, area } = this.props;
    e.stopPropagation();
    history.push({
      pathname: `/editor/knowledge/${area.id}`,
      state: {
        title: area.name,
        backTitle: localeLookup('translations.Design board'),
      },
    });
  };

  onClickToggleShowDashboardOverlay = () => {
    const { showDashboardOverlay } = this.state;
    this.setState({ showDashboardOverlay: !showDashboardOverlay });
  };

  onClickToggleLinkVisiblity = () => {
    const {
      onClickToggleAreaLinkVisiblity,
      area,
      allowChangeOfChampLinkVisibility,
    } = this.props;
    if (allowChangeOfChampLinkVisibility) {
      onClickToggleAreaLinkVisiblity(area);
    }
  };

  onSubmitAddElements = (names) => {
    const { area, roleId, editorActions } = this.props;
    editorActions.createElements({
      names,
      areaId: area.id,
      connectToRole: true,
      roleId,
    });
  };

  onBlurAddElementInput = () => {
    this.setState({
      isAddingElement: false,
    });
  };

  onSubmitRename = (value) => {
    const { area, editorActions } = this.props;
    editorActions.changeAreaName(area.id, value);
  };

  onToggleAreaExpansion = () => {
    const { area, queryElements } = this.props;
    const { isEditingName, isExpanded } = this.state;
    if (isEditingName) return;
    if (!isExpanded) {
      this.setState({ isLoadingElements: true });
      if (area.knowledgeElements?.length > 0) {
        queryElements(area.knowledgeElements).then(() => {
          this.setState({ isExpanded: true, isLoadingElements: false });
        });
      } else {
        this.setState({ isExpanded: true, isLoadingElements: false });
      }
    } else {
      this.setState({ isExpanded: false });
    }
  };

  onToggleAllConnections = () => {
    const { area, onChangeElementConnections } = this.props;
    const connectionCount = this.getElementConnectionsCount();
    if (connectionCount.total === 0) return;
    if (connectionCount.total === connectionCount.connected) {
      onChangeElementConnections({
        elementIds: area.knowledgeElements,
        connect: false,
      });
    } else {
      onChangeElementConnections({
        elementIds: area.knowledgeElements,
        connect: true,
      });
    }
  };

  renderElements = () => {
    const {
      allowChangeOfChampLinkVisibility,
      area,
      onClickDeleteElement,
      knowledgeElements,
      onClickEditElementResponsible,
      onClickEditElementDescription,
      onClickMoveElement,
      onClickToggleElementLinkVisiblity,
      persons,
      roleId,
      wildcardPersons,
      onChangeElementConnections,
      isAreaReadOnly,
    } = this.props;
    const { isExpanded, isAddingElement } = this.state;
    const connectionCount = this.getElementConnectionsCount();

    const connectedRoleIds = [
      ...area.attachedAsMandatoryToRoles,
      ...area.attachedAsAdditionalToRoles,
    ];
    return (
      <Accordion
        className="designboard-knowledge-area__knowledge-elements-wrapper"
        isOpen={isExpanded}
      >
        {isExpanded && (
          <div className="designboard-knowledge-area__knowledge-elements">
            {area.knowledgeElements?.length > 0 && (
              <div className="designboard-knowledge-area__knowledge-elements-buttons">
                <SwitchCheckbox
                  labelText={localeLookup('translations.Connect all')}
                  htmlFor={`${area.id}connectAll`}
                  size="small"
                  onChange={this.onToggleAllConnections}
                  isChecked={
                    connectionCount.total === connectionCount.connected
                  }
                />
              </div>
            )}
            <Droppable
              droppableId={`${roleId}${area.id}`}
              type={`${roleId}${area.id}`}
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {area.knowledgeElements?.length > 0 &&
                    area.knowledgeElements.map((elementId, i) => {
                      if (knowledgeElements[elementId]) {
                        return (
                          <Draggable
                            isDragDisabled={isAreaReadOnly}
                            draggableId={`ELEMENT_${roleId}_${area.id}_${elementId}`}
                            index={i}
                            key={`${roleId}${area.id}${elementId}`}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <DesignboardKnowledgeElement
                                  allowChangeOfChampLinkVisibility={
                                    allowChangeOfChampLinkVisibility
                                  }
                                  areaId={area.id}
                                  hasAccess={!isAreaReadOnly}
                                  onClickDeleteElement={onClickDeleteElement}
                                  id={elementId}
                                  showConnectionCounter={
                                    connectedRoleIds.length > 1
                                  }
                                  index={i}
                                  onChangeElementConnections={
                                    onChangeElementConnections
                                  }
                                  key={elementId}
                                  knowledgeElement={
                                    knowledgeElements[elementId]
                                  }
                                  onClickEditElementResponsible={
                                    onClickEditElementResponsible
                                  }
                                  onClickEditElementDescription={
                                    onClickEditElementDescription
                                  }
                                  onClickMoveElement={onClickMoveElement}
                                  onClickToggleElementLinkVisiblity={
                                    onClickToggleElementLinkVisiblity
                                  }
                                  persons={persons}
                                  roleId={roleId}
                                  wildcardPersons={wildcardPersons}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                      return null;
                    })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {isAddingElement && (
              <CreateElementBox
                placeholder={localeLookup(
                  'translations.Name of knowledge element'
                )}
                onBlur={this.onBlurAddElementInput}
                onSubmit={this.onSubmitAddElements}
              />
            )}
            {!isAreaReadOnly && (
              <div className="designboard-knowledge-area__section">
                <p
                  className="designboard-knowledge-area__section-add-text"
                  onClick={this.onClickAddElement}
                  ref={(text) => {
                    this.elementAddTextRef = text;
                  }}
                >
                  {localeLookup('translations.Add knowledge element')}...
                </p>
              </div>
            )}
          </div>
        )}
      </Accordion>
    );
  };

  renderHeader = () => {
    const {
      allowChangeOfChampLinkVisibility,
      area,
      categories,
      isDragging,
      persons,
      currentUserId,
      roles,
      hasAccess,
      spaces,
      spacesEnabled,
      isAreaReadOnly,
      hasChangeAreaOwnerPermission,
    } = this.props;
    const { isEditingName, isLoadingElements } = this.state;

    const getEditorsSubtitle = () => {
      if (area.editors?.length === 0)
        return localeLookup('translations.No editors');
      if (area.editors?.length === 1) return persons[area.editors[0]]?.name;
      return localeLookup('translations.{0} editors', [area.editors?.length]);
    };

    const getExpertsSubtitle = () => {
      if (area.experts?.length === 0)
        return localeLookup('translations.No experts');
      if (area.experts?.length === 1) return persons[area.experts[0]]?.name;
      return localeLookup('translations.{0} experts', [area.experts?.length]);
    };

    const editableInSpaceNames = Object.keys(area.editableInSpaces).map(
      (id) => spaces[id]?.name
    );

    const readOnlyInSpaceNames = Object.keys(area.readOnlyInSpaces).map(
      (id) => spaces[id]?.name
    );

    const dragModifier = isDragging ? 'designboard-knowledge-area--hidden' : '';

    const connectedRoleIds = [
      ...area.attachedAsMandatoryToRoles,
      ...area.attachedAsAdditionalToRoles,
    ];

    const connectionsTooltip = (
      <span>
        {localeLookup('translations.Connected to {0} roles', [
          connectedRoleIds.length,
        ])}
        : <br />
        {sortBy(
          connectedRoleIds.map((id) => roles[id]?.name),
          [compareLocal]
        ).map((name) => (
          <>
            {name}
            <br />
          </>
        ))}
      </span>
    );

    const champLinkIcon = () => {
      if (allowChangeOfChampLinkVisibility) {
        return area.champLink.isPublic ? 'unlock' : 'lock';
      }
      return null;
    };

    const isAdminInAnySpacesWhereAreaIsEditable = Object.keys(
      area.editableInSpaces
    ).some((spaceId) => spaces[spaceId].administrators.includes(currentUserId));

    const showContentSpaces = spacesEnabled
      ? hasAccess([
          ACCESS_LEVELS.champadministrator,
          ACCESS_LEVELS.administrator,
          ACCESS_LEVELS.contentAdministrator,
        ]) || isAdminInAnySpacesWhereAreaIsEditable
      : false;

    const connectionCount = this.getElementConnectionsCount();
    const canEditOwner = hasChangeAreaOwnerPermission({
      currentOwnerId: area.owner,
      editableInSpaceIds: Object.keys(area.editableInSpaces),
    });
    return (
      <div className={`designboard-knowledge-area__title ${dragModifier}`}>
        <Icon kind="book2" className="designboard-knowledge-area__title-icon" />
        <div
          className="designboard-knowledge-area__title-expansion-wrapper"
          onClick={this.onToggleAreaExpansion}
        >
          {isEditingName ? (
            <InlineFieldEditor
              autoFocus
              classNameInput="designboard-knowledge-area__title-input"
              defaultValue={area.name}
              onBlur={this.onBlurEditName}
              onSubmit={this.onSubmitRename}
              placeholder={localeLookup('translations.Name of area')}
            />
          ) : (
            <div
              className="designboard-knowledge-area__title-name"
              onClick={!isAreaReadOnly ? this.onClickEditName : undefined}
            >
              <p
                title={area.name}
                className="designboard-knowledge-area__title-name-text"
              >
                {area.name}
              </p>
              {!isAreaReadOnly && (
                <Icon
                  kind="pencil"
                  className="designboard-knowledge-area__title-name-icon"
                  size="small"
                />
              )}
            </div>
          )}
          {connectedRoleIds.length > 1 && (
            <IconWithCount
              className="designboard-knowledge-area__title-role-count"
              count={connectedRoleIds.length}
              kind="share2"
              color="grey"
              size="xs"
              tooltip={connectionsTooltip}
            ></IconWithCount>
          )}
          <AreaElementConnectionCount
            total={connectionCount.total}
            connected={connectionCount.connected}
          />
          <div className="designboard-knowledge-area__title-expand">
            {isLoadingElements ? (
              <Loader
                className="designboard-knowledge-area__title-loader"
                size="small"
                numberOfDots={1}
              />
            ) : (
              <Icon
                kind="chevron-down"
                className="designboard-knowledge-area__title-expand-icon"
              />
            )}
          </div>
        </div>
        <ContextMenu triggerClass="designboard-knowledge-area__title-context-menu-icon">
          <ContextMenuItem
            disabled={!canEditOwner}
            onClick={this.onClickEditOwner}
            leftIconKind="key"
            titleText={localeLookup('translations.Owner')}
            subtitleText={
              persons[area.owner]
                ? persons[area.owner].name
                : localeLookup('translations.No owner')
            }
          />
          <ContextMenuItem
            disabled={isAreaReadOnly}
            onClick={this.onClickEditEditors}
            tooltip={
              area.editors?.length > 1 &&
              area.editors.map((id) => <p key={id}>{persons[id]?.name}</p>)
            }
            leftIconKind="clipboard-pencil"
            titleText={localeLookup('translations.Editors')}
            subtitleText={getEditorsSubtitle()}
          />
          <ContextMenuItem
            disabled={isAreaReadOnly}
            onClick={this.onClickEditExperts}
            leftIconKind="star"
            titleText={localeLookup('translations.Experts')}
            subtitleText={getExpertsSubtitle()}
            tooltip={
              area.experts?.length > 1 &&
              area.experts.map((id) => <p key={id}>{persons[id]?.name}</p>)
            }
          />
          {showContentSpaces && (
            <ContextMenuItem
              disabled={isAreaReadOnly}
              onClick={this.onClickEditSpace}
              leftIconKind="folder"
              titleText={localeLookup('translations.Content space')}
              subtitleText={
                <TruncatedArrayText
                  countSuffixText={localeLookup('translations.Content spaces')}
                  items={[...editableInSpaceNames, ...readOnlyInSpaceNames]}
                />
              }
            />
          )}
          <ContextMenuItem
            disabled={isAreaReadOnly}
            onClick={this.onClickEditCategory}
            leftIconKind="tag"
            titleText={localeLookup('translations.Category')}
            subtitleText={
              categories?.[area.category] ||
              localeLookup('translations.No category')
            }
          />

          <ContextMenuItem
            disabled={isAreaReadOnly}
            leftIconKind="champ-link-outline-2"
            rightIconKind={champLinkIcon()}
            onClick={this.onClickToggleLinkVisiblity}
            titleText={insertStringInString(area.champLink.link, '-', 3)}
          />
          <ContextMenuItem
            disabled={isAreaReadOnly}
            onClick={this.onClickOpenInKnowledgeEditor}
            leftIconKind="enter-right"
            titleText={localeLookup('translations.Open in knowledge editor')}
          />
          <ContextMenuItem
            onClick={this.onClickDisconnectAreaFromRole}
            leftIconClassName="designboard-knowledge-area__title-disconnect-icon"
            leftIconKind="circle-minus"
            titleText={localeLookup('translations.Disconnect from role')}
          />
          <ContextMenuItem
            disabled={isAreaReadOnly}
            onClick={this.onClickDelete}
            leftIconClassName="designboard-knowledge-area__section-delete-area"
            leftIconKind="trash2"
            titleText={localeLookup('translations.Delete')}
          />
        </ContextMenu>
      </div>
    );
  };

  renderDashboardOverlay = () => {
    const { area } = this.props;
    const { showDashboardOverlay } = this.state;
    if (!showDashboardOverlay) return null;
    return (
      <Overlay
        isOpen={showDashboardOverlay}
        onClose={this.onClickToggleShowDashboardOverlay}
        title={<p>{area.name}</p>}
      >
        <KnowledgeDashboard getData={() => getAreaDashbordService(area.id)} />
      </Overlay>
    );
  };

  render() {
    const { transformStyle, isAreaReadOnly } = this.props;
    const { isExpanded } = this.state;
    const areaClasses = cx({
      'designboard-knowledge-area--expanded': isExpanded,
      'designboard-knowledge-area--no-access': isAreaReadOnly,
    });

    return (
      <div
        className={`designboard-knowledge-area ${areaClasses}`}
        style={transformStyle}
      >
        {this.renderHeader()}
        {this.renderElements()}
        <Portal id="overlay-root">{this.renderDashboardOverlay()}</Portal>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withAccessControl(WithEditorActions(DesignboardKnowledgeArea))));
